import { WarningTwoIcon } from '@chakra-ui/icons'
import {
  Alert,
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Divider,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react'
import { Plural, t, Trans } from '@lingui/macro'
import { useCallback, useState } from 'react'

import { config } from 'config'
import {
  Organization,
  useDeleteUserMutation,
  useGetGraphqlUserQuery,
  User,
} from 'modules/api'
import {
  GAMMA_ARTIFACT_PROPER_NOUN_PLURAL,
  GAMMA_PROPER_NOUN,
} from 'modules/i18n/properNouns'

type AccountDeleteModalProps = {
  isOpen: boolean
  onClose: () => void
  user: User
}

type InfoBoxProps = {
  // In order to use correctly only 1 of user or workspace should be set
  user?: User
  workspace?: Organization
  canDeleteAccount: boolean
}

const PermanentlyDeleteBox = () => {
  return (
    <HStack
      px={2}
      py={2}
      border="2px solid var(--chakra-colors-red-500)"
      borderRadius="md"
    >
      <WarningTwoIcon w={4} h={4} color="red.500" />
      <Text fontSize="sm" color="red.500">
        <Trans>Will be deleted</Trans>
      </Text>
    </HStack>
  )
}

const InfoBox = ({ user, workspace, canDeleteAccount }: InfoBoxProps) => {
  return (
    <Alert
      status={
        user || (workspace?.memberCount && workspace.memberCount <= 1)
          ? 'error'
          : 'warning'
      }
      display="flex"
      flexDirection="column"
      alignItems="normal"
      mt={2}
    >
      <Flex
        pb={3}
        flexDirection={['column', 'column', 'row', 'row']}
        align="flex-start"
        gap={2}
      >
        <Flex flex={1}>
          <Avatar
            src={user?.profileImageUrl}
            size="sm"
            name={user?.displayName || workspace?.name}
          />
          <Stack spacing={0} ml={2} flex="1">
            <Text fontSize="sm" flex="1" wordBreak="break-word">
              {user?.displayName || workspace?.name}
            </Text>
            {user && (
              <Text
                fontSize="xs"
                color="gray.600"
                flex="1"
                wordBreak="break-word"
              >
                {user.email}
              </Text>
            )}
            {workspace && (
              <Text fontSize="xs" color="gray.600">
                <Plural
                  value={workspace.memberCount || 0}
                  one="# member (you)"
                  other="# members"
                />
              </Text>
            )}
          </Stack>
        </Flex>
        <Flex flexShrink={1}>
          {(user || (workspace && workspace?.memberCount === 1)) && (
            <PermanentlyDeleteBox />
          )}
        </Flex>
      </Flex>
      <Divider borderColor="blackAlpha.200" />
      <Text
        pt={2}
        fontSize="sm"
        color={
          user || (workspace?.memberCount && workspace.memberCount <= 1)
            ? 'red.500'
            : 'yellow.700'
        }
      >
        {canDeleteAccount && user && (
          <>
            ⚠️{' '}
            <Trans>
              You will lose access to any {GAMMA_ARTIFACT_PROPER_NOUN_PLURAL}{' '}
              shared with your account
            </Trans>
          </>
        )}
        {canDeleteAccount &&
          workspace?.memberCount &&
          (workspace.memberCount > 1 ? (
            <>
              ⚠️ <Trans> You will lose access to this workspace</Trans>
            </>
          ) : (
            <>
              ⚠️{' '}
              <Trans>
                All the {GAMMA_ARTIFACT_PROPER_NOUN_PLURAL} in this workspace
                will be deleted
              </Trans>
            </>
          ))}
        {!canDeleteAccount && (
          <>
            ⚠️{' '}
            <Trans>
              You are the only admin in this workspace. Please make at least one
              other user an admin to delete your account.
            </Trans>
          </>
        )}
      </Text>
    </Alert>
  )
}

export const AccountDeleteModal = ({
  isOpen,
  onClose,
  user,
}: AccountDeleteModalProps) => {
  const [hasConfirmedDeletion, setHasConfirmedDeletion] = useState(false)
  const [isDeleting, setIsDeleting] = useState<boolean>(false)
  const { data } = useGetGraphqlUserQuery()
  const toast = useToast()
  const [deleteUserMutation] = useDeleteUserMutation()
  const workspaceMemberships =
    data?.user?.workspaceMemberships?.filter(
      (membership) => membership.disabled === false
    ) || []
  // Figure out any workspaces with no admins post user's account deletion
  const workspacesWithNoAdmins = workspaceMemberships.filter(
    (membership) =>
      membership.role === 'admin' &&
      membership.workspace?.adminCount === 1 &&
      membership.workspace?.memberCount &&
      membership.workspace.memberCount > 1
  )
  const userWorkspaces = workspaceMemberships.map(
    (membership) => membership.workspace
  )
  const canDeleteAccount = workspacesWithNoAdmins.length === 0

  const onAccountDelete = useCallback(() => {
    setIsDeleting(true)
    deleteUserMutation()
      .then(({ data: deleteData }) => {
        if (!deleteData) return
        window.location.href = `${config.API_HOST}/logout?redirectTo=/user-deleted`
      })
      .catch(() => {
        setIsDeleting(false)
        toast({
          title:
            'Delete account failed. Try again or reach out to Gamma support.',
          status: 'error',
          duration: 3000,
          position: 'top',
          isClosable: true,
        })
      })
  }, [toast, deleteUserMutation])

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent p={2}>
        <ModalHeader>
          <Trans>Delete your {GAMMA_PROPER_NOUN} account?</Trans>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {canDeleteAccount && (
            <Stack spacing={3}>
              <Text>
                <Trans>
                  Deleting your account is permanent and cannot be undone. Are
                  you sure you'd like to continue?
                </Trans>
              </Text>
              <Box>
                <Text colorScheme="gray" fontSize="sm">
                  <Trans>Your account:</Trans>
                </Text>
                <InfoBox user={user} canDeleteAccount={canDeleteAccount} />
              </Box>
              {userWorkspaces && userWorkspaces.length > 0 && (
                <Box>
                  <Text colorScheme="gray" fontSize="sm">
                    <Trans>Your workspaces:</Trans>
                  </Text>
                  {userWorkspaces?.map((workspace) => {
                    return (
                      <InfoBox
                        key={workspace?.id}
                        workspace={workspace}
                        canDeleteAccount={canDeleteAccount}
                      />
                    )
                  })}
                </Box>
              )}
              <Box>
                <Text colorScheme="gray" fontSize="sm">
                  <Trans>Please acknowledge and confirm:</Trans>
                </Text>
                <HStack
                  bg="gray.100"
                  mt={2}
                  p={3}
                  pl={2}
                  spacing={2}
                  borderRadius="sm"
                >
                  <Checkbox
                    size="lg"
                    spacing="1rem"
                    p={3}
                    defaultChecked={hasConfirmedDeletion}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setHasConfirmedDeletion(e.target.checked)
                    }
                  />
                  <Text fontSize="sm">
                    <Trans>
                      I understand that deleting my account is permanent, and
                      have reviewed what will happen to my workspaces
                    </Trans>
                  </Text>
                </HStack>
              </Box>
            </Stack>
          )}
          {!canDeleteAccount && (
            <Stack>
              <Box>
                <Text colorScheme="gray" fontSize="sm">
                  <Trans>
                    Before you leave please resolve the following issues:
                  </Trans>
                </Text>
                {userWorkspaces?.map((workspace) => {
                  return (
                    <InfoBox
                      key={workspace?.id}
                      workspace={workspace}
                      canDeleteAccount={canDeleteAccount}
                    />
                  )
                })}
              </Box>
            </Stack>
          )}
        </ModalBody>
        {canDeleteAccount && (
          <ModalFooter>
            <ButtonGroup>
              <Button variant="ghost" colorScheme="gray" onClick={onClose}>
                <Trans>Cancel</Trans>
              </Button>
              <Button
                isDisabled={!hasConfirmedDeletion}
                variant="ghost"
                colorScheme="red"
                onClick={onAccountDelete}
                isLoading={isDeleting}
                loadingText={t`Deleting your account...`}
              >
                <Trans>Yes, delete my account</Trans>
              </Button>
            </ButtonGroup>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  )
}
