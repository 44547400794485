import {
  Badge,
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Image,
  LinkBox,
  LinkOverlay,
  Stack,
  Text,
} from '@chakra-ui/react'
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'
import NextLink from 'next/link'

import { isMobileDevice } from 'utils/deviceDetection'

const ButtonInner = ({
  children,
  href,
  onClick,
}: {
  children: JSX.Element
  href?: string
  onClick?: () => void
}) => {
  if (href) {
    return (
      <LinkBox as={Box} role="group" onClick={onClick}>
        <LinkOverlay
          href={href}
          as={NextLink}
          _focusVisible={{
            boxShadow: 'none',
            outline: '0 none !important',
          }}
        >
          {children}
        </LinkOverlay>
      </LinkBox>
    )
  }
  return (
    <Box
      as={Button}
      w="100%"
      variant="unstyled"
      display="inline-flex"
      h="fit-content"
      role="group"
      onClick={onClick}
      maxW="18em"
      tabIndex={0}
      _focusVisible={{
        boxShadow: 'none',
        outline: '0 none !important',
      }}
    >
      {children}
    </Box>
  )
}

type BigButtonProps = {
  label: JSX.Element
  description?: JSX.Element
  isPopular?: boolean
  colorScheme?: string
  imageUrl: string
  href?: string
  transform?: string
  onClick?: () => void
  ctaIcon: JSX.Element
  ctaLabel: JSX.Element
}

export const BigButton = ({
  label,
  description,
  isPopular,
  colorScheme,
  imageUrl,
  href,
  transform,
  onClick,
  ctaIcon,
  ctaLabel,
}: BigButtonProps) => {
  return (
    <ButtonInner
      href={href}
      onClick={() => {
        onClick?.()
      }}
    >
      <Stack
        cursor="pointer"
        direction={{ base: 'row', md: 'column' }}
        px="0"
        flex="1"
        whiteSpace="normal"
        textAlign="left"
        spacing="0"
        border="none"
        bg="white"
        borderRadius="lg"
        boxShadow={{ base: 'sm', md: 'md' }}
        transition="all 300ms ease"
        bottom="0"
        outline="none"
        _groupHover={
          !isMobileDevice()
            ? {
                bottom: 3,
                boxShadow: 'xl',
                transform: 'scale(1.03)',
              }
            : {}
        }
        position="relative"
        transform={{
          base: 'auto',
          md: transform && !isMobileDevice() ? transform : 'auto',
        }}
        _groupFocusWithin={{
          shadow: 'outline',
        }}
        overflow="hidden"
      >
        <Flex
          flex="1"
          justifyContent="center"
          textAlign="center"
          color={`${colorScheme}.500`}
          bg={`${colorScheme}.100`}
          position="relative"
          minH={{ base: 'none', md: '190px' }}
        >
          <Image
            src={imageUrl}
            objectFit="cover"
            transitionProperty="filter"
            transitionDuration="normal"
            _groupHover={{ filter: 'brightness(1.02)' }}
          />

          {isPopular && (
            <HStack position="absolute" bottom="2" left="2">
              <Badge
                colorScheme="teal"
                fontSize="md"
                p="0.5"
                px="2"
                textTransform="none"
                fontWeight="600"
                borderRadius={['sm', 'md']}
              >
                <FontAwesomeIcon icon={duotone('bolt')} />{' '}
                <Trans>Popular</Trans>
              </Badge>
            </HStack>
          )}
        </Flex>

        <Stack
          spacing="1.5"
          p={4}
          maxW="100%"
          minH={{ base: '100px', md: 'none' }}
          justifyContent="space-between"
          alignItems="stretch"
          flex="1.5"
          direction={{ base: 'row', md: 'column' }}
          position="relative"
          h="100%"
        >
          <Stack>
            <Heading fontSize={{ base: 'xl', md: '2xl' }} fontWeight="600">
              {label}
            </Heading>

            <HStack>
              <Box
                fontSize={{ base: 'sm', md: 'md' }}
                fontWeight="500"
                lineHeight="1.4"
                color="gray.600"
              >
                {description}
              </Box>
            </HStack>
          </Stack>
          <HStack
            maxW="100%"
            justifyContent="flex-end"
            alignItems="flex-start"
            fontSize={{ base: 'sm', sm: 'md' }}
            p={{ base: 0.5, md: 0 }}
          >
            <Text
              display={{ base: 'none', md: 'block' }}
              transition="all 300ms ease"
              opacity={0}
              color={`${colorScheme}.700`}
              _groupFocusWithin={{
                opacity: 1,
              }}
              _groupHover={{
                opacity: 1,
              }}
            >
              {ctaLabel}
            </Text>
            <Box
              fontSize={{ base: 'md', sm: 'lg' }}
              _groupFocusWithin={{
                color: `${colorScheme}.700`,
              }}
              _groupHover={{
                color: `${colorScheme}.700`,
              }}
            >
              {ctaIcon}
            </Box>
          </HStack>
        </Stack>
      </Stack>
    </ButtonInner>
  )
}
