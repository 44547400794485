import {
  Box,
  Button,
  GridItem,
  Heading,
  HStack,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  SimpleGrid,
  Skeleton,
  Stack,
  Text,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { t, Trans } from '@lingui/macro'
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useGetThemesLazyQuery, useGetThemesQuery } from 'modules/api'
import { useModalDisclosure } from 'modules/modal_state/hooks/useModalDisclosure'
import {
  FILTER_THEME_OPTIONS,
  getDefaultNewTheme,
  makeCleanCustomTheme,
  ORDERED_FILTER_THEME_OPTIONS,
  Theme,
  themeSortFunction,
  ThemeType,
} from 'modules/theming'
import { ThemeEditorDrawer } from 'modules/theming/components/ThemeEditorDrawer'
import { ThemePreview } from 'modules/theming/components/ThemePreview'
import { useThemeReducer } from 'modules/theming/themeReducer/reducer'
import { GlobalCardStyles } from 'modules/tiptap_editor/extensions/Card/GlobalCardStyles'
import { useUserContext } from 'modules/user'
import { useGammaBreakpointValue } from 'utils/breakpoints/useGammaBreakpointValue'

import { SidebarTabs, TabMap } from '../Sidebar'
import { TopbarWrapper } from '../Topbar'
import { GridItemButton } from './GridItemButton'

type ThemesViewProps = {
  isSidebarVisible: boolean
  setIsSidebarVisible: Dispatch<SetStateAction<boolean>>
}

export const ThemesView = ({
  isSidebarVisible,
  setIsSidebarVisible,
}: ThemesViewProps) => {
  const [filterBy, setFilterBy] = useState<ThemeType>('custom')
  const [state, dispatch] = useThemeReducer()
  const { currentWorkspace } = useUserContext()
  const {
    isOpen: isThemeEditorOpen,
    onOpen: onThemeEditorOpen,
    onClose: onThemeEditorClose,
  } = useModalDisclosure({ id: 'themeEditor' })

  const openThemeEditor = useCallback(
    (themeToEdit?: Theme, isNewTheme = false) => {
      if (!themeToEdit) {
        dispatch({ type: 'NEW_BLANK_THEME' })
      } else if (isNewTheme) {
        dispatch({
          type: 'SET_NEW_THEME_FOR_SELECTING',
          data: {
            theme: themeToEdit,
          },
        })
      } else {
        dispatch({
          type: 'SET_THEME_EDITING',
          data: {
            theme: themeToEdit,
          },
        })
      }
      onThemeEditorOpen()
    },
    [dispatch, onThemeEditorOpen]
  )

  const closeThemeEditor = useCallback(() => {
    dispatch({ type: 'THEME_RESET' })
    onThemeEditorClose()
  }, [dispatch, onThemeEditorClose])

  const {
    data: customThemesData,
    loading,
    called,
  } = useGetThemesQuery({
    variables: {
      workspaceId: currentWorkspace?.id,
      archived: false,
    },
    skip: !currentWorkspace,
  })

  const { data: standardThemesData } = useGetThemesQuery({
    variables: {
      // @ts-ignore
      workspaceId: null,
      archived: false,
    },
  })

  const [
    getArchivedThemes,
    { data: archivedThemesData, loading: archivedLoading },
  ] = useGetThemesLazyQuery({
    variables: {
      workspaceId: currentWorkspace?.id,
      archived: true,
    },
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only',
  })

  useEffect(() => {
    if (filterBy === 'archived' && currentWorkspace) {
      getArchivedThemes()
    }
  }, [currentWorkspace, filterBy, getArchivedThemes])
  const archivedThemes = archivedThemesData?.themes || []
  const customThemes = customThemesData?.themes || []
  const standardThemes = standardThemesData?.themes || []

  const showLoading = !called || loading || archivedLoading

  const preferredNewTheme = useMemo(
    () => getDefaultNewTheme(standardThemesData?.themes),
    [standardThemesData]
  )

  const { displayName, description, ariaLabel, icon } =
    TabMap[SidebarTabs.THEMES]
  const showFilterMenu = useGammaBreakpointValue({ base: true, xl: false })

  const filterInfo = FILTER_THEME_OPTIONS?.[filterBy]
  const options = ORDERED_FILTER_THEME_OPTIONS.map((k) => {
    return { key: k, ...FILTER_THEME_OPTIONS[k] }
  })

  const themesToShow =
    filterBy === 'custom'
      ? customThemes
      : filterBy === 'archived'
      ? archivedThemes
      : standardThemes
  return (
    <Box w="100%" data-testid="themes-view-container">
      <GlobalCardStyles />
      <TopbarWrapper
        isSidebarVisible={isSidebarVisible}
        setIsSidebarVisible={setIsSidebarVisible}
      >
        <Heading
          fontSize="lg"
          fontWeight="600"
          data-testid="themes-view-header"
        >
          {icon && <FontAwesomeIcon icon={icon} aria-label={ariaLabel} />}{' '}
          <Text as="span" ml={2}>
            {displayName}
          </Text>
        </Heading>
        <Text fontSize="md" mt="2" mb="6" color="gray.600">
          {description}
        </Text>
        <Wrap
          id="filter-by-dropdown"
          zIndex="3"
          width={['100%', 'auto']}
          mt={[4, 0]}
        >
          {showFilterMenu ? (
            <Menu closeOnSelect={true}>
              <MenuButton
                as={Button}
                variant="ghost"
                size="sm"
                rightIcon={<FontAwesomeIcon icon={regular('chevron-down')} />}
                fontWeight="600"
                color="gray.700"
                leftIcon={<FontAwesomeIcon icon={regular('filter')} />}
              >
                <HStack>
                  <Box boxSize={4}>{filterInfo.icon}</Box>
                  <Box>{filterInfo.label}</Box>
                </HStack>
              </MenuButton>
              <MenuList minWidth="240px">
                <MenuOptionGroup
                  defaultValue={filterBy}
                  title={t`Filter by...`}
                  type="radio"
                  onChange={(val) => {
                    setFilterBy(val as ThemeType)
                  }}
                  value={filterBy}
                >
                  {options.map((option) => (
                    <MenuItemOption value={option.key} key={option.key}>
                      <HStack>
                        <Box w={5}>{option.icon}</Box>
                        <Box>{option.label}</Box>
                      </HStack>
                    </MenuItemOption>
                  ))}
                </MenuOptionGroup>
              </MenuList>
            </Menu>
          ) : (
            options.map((option) => {
              const isSelected = option.key === filterBy
              return (
                <WrapItem key={option.key}>
                  <Button
                    fontWeight="600"
                    color={isSelected ? 'trueblue.500' : 'gray.600'}
                    bg={isSelected ? 'trueblue.50' : 'transparent'}
                    size="sm"
                    leftIcon={option.icon}
                    variant="ghost"
                    onClick={() => setFilterBy(option.key)}
                  >
                    {option.label}
                  </Button>
                </WrapItem>
              )
            })
          )}
        </Wrap>
      </TopbarWrapper>
      {showLoading ? (
        <Box width="100%" data-testid="themes-skeleton">
          <SimpleGrid columns={[1, 2, 2, 3, 5]} spacing={6}>
            {[1, 2, 3, 4, 5, 6, 7, 8].map((i) => (
              <GridItem
                key={i}
                bg="linen.50"
                borderRadius="5px"
                height="144px"
                as={Skeleton}
              />
            ))}
          </SimpleGrid>
        </Box>
      ) : themesToShow.length === 0 && filterBy !== 'custom' ? (
        <>
          <Trans>
            There are no themes matching your filter. Please try changing your
            filter.
          </Trans>
        </>
      ) : (
        <>
          <ThemesGrid
            themes={themesToShow}
            openThemeEditor={openThemeEditor}
            type={filterBy}
            defaultNewTheme={preferredNewTheme}
          />
        </>
      )}
      <ThemeEditorDrawer
        isOpen={isThemeEditorOpen}
        onClose={closeThemeEditor}
        state={state}
        dispatch={dispatch}
        disableDrawerTransition
      />
    </Box>
  )
}

type ThemesGridProps = {
  themes: Theme[]
  type: ThemeType
  defaultNewTheme?: Theme
  openThemeEditor?: (
    themeToEdit?: Theme | undefined,
    isNewTheme?: boolean
  ) => void
}

const ThemesGrid = ({
  themes,
  type,
  defaultNewTheme,
  openThemeEditor,
}: ThemesGridProps) => {
  const isCustom = type === 'custom'

  const openThemeEditorWithDefaultNewTheme = useCallback(() => {
    if (!openThemeEditor) {
      return
    }
    if (defaultNewTheme) {
      openThemeEditor(defaultNewTheme, true)
    }
  }, [openThemeEditor, defaultNewTheme])

  const openThemeEditorWithThisTheme = useCallback(
    (isCustomizing: boolean = false) =>
      (theme: Theme) => {
        if (isCustomizing) {
          openThemeEditor?.(makeCleanCustomTheme(theme, true))
        } else {
          openThemeEditor?.(theme)
        }
      },
    [openThemeEditor]
  )

  return (
    <Stack>
      <SimpleGrid columns={[1, 2, 2, 3, 5]} spacing={6}>
        {isCustom && (
          <GridItemButton
            label={<Trans>New theme</Trans>}
            icon={<FontAwesomeIcon icon={regular('circle-plus')} />}
            onClick={openThemeEditorWithDefaultNewTheme}
          />
        )}
        {[...themes].sort(themeSortFunction).map((theme) => {
          return (
            <ThemePreview
              key={theme.id}
              type={type}
              theme={theme}
              isChecked={false}
              onEditClick={openThemeEditorWithThisTheme(!isCustom)}
              onThemeClicked={
                isCustom ? openThemeEditorWithThisTheme(!isCustom) : undefined
              }
            />
          )
        })}
      </SimpleGrid>
    </Stack>
  )
}
