import {
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  Text,
} from '@chakra-ui/react'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { Trans } from '@lingui/macro'
import { format, parseISO } from 'date-fns'
import { memo } from 'react'

import { ARCHIVED_THEME_NAME_REPLACE_REGEX } from 'modules/theming/constants'
import { useUnarchiveTheme } from 'modules/theming/hooks'

import { Theme } from '../types'

type ArchivedThemePreviewMenuProps = {
  theme: Theme
}
const ArchivedThemePreviewMenuComponent = ({
  theme,
}: ArchivedThemePreviewMenuProps) => {
  const unarchiveTheme = useUnarchiveTheme({ theme })

  const nameToDisplay = theme.name.replace(
    ARCHIVED_THEME_NAME_REPLACE_REGEX,
    ''
  )
  return (
    <Menu isLazy>
      <MenuButton
        as={IconButton}
        isRound
        size="xs"
        variant="ghost"
        colorScheme="blackAlpha"
        icon={<FontAwesomeIcon icon={regular('ellipsis')} />}
        onClick={(ev) => ev.stopPropagation()} // Prevents clicking the ... from changing the theme
      >
        <Trans>Actions</Trans>
      </MenuButton>

      <Portal>
        <MenuList
          zIndex="modal" // Necessary when this component is rendered inside a drawer
          onClick={(ev) => ev.stopPropagation()} // Prevents clicking a menu item from changing the theme
        >
          <Box px={4} py={0} maxWidth="300px">
            <Text noOfLines={3}>{nameToDisplay}</Text>
            {theme.createdTime && (
              <Text fontSize="sm" color="gray.500" noOfLines={1}>
                <Trans>
                  Created {format(parseISO(theme.createdTime), 'PPP')}
                </Trans>
              </Text>
            )}
            {theme.createdBy && (
              <GammaTooltip label={theme.createdBy.email}>
                <Text
                  display="inline-block"
                  fontSize="sm"
                  color="gray.500"
                  noOfLines={1}
                >
                  <Trans>by {theme.createdBy.displayName}</Trans>
                </Text>
              </GammaTooltip>
            )}
          </Box>
          <MenuDivider />
          <MenuItem
            icon={<FontAwesomeIcon icon={solid('rotate-left')} fixedWidth />}
            onClick={unarchiveTheme}
          >
            <Trans>Restore</Trans>
          </MenuItem>
        </MenuList>
      </Portal>
    </Menu>
  )
}

export const ArchivedThemePreviewMenu = memo(ArchivedThemePreviewMenuComponent)
