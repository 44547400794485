import { Box, Center, Flex, Heading, Link, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import NextImage from 'next/legacy/image'
import NextLink from 'next/link'

import { GAMMA_ARTIFACT_PROPER_NOUN_PLURAL } from 'modules/i18n/properNouns'

import { INITIAL_TAB } from '../constants'

export const DocsEmptyState = () => {
  return (
    <Center minH="sm">
      <Flex direction="column" justify={'center'} align="center">
        <Box w="80%" maxW="250px" mb={6} alignContent="center">
          <NextImage
            src="/images/Sal-Fishing-2x.png"
            width="640"
            height="361"
            alt="Sal, the Gamma mascot, fishing extraterrestrially"
          />
        </Box>
        <Heading size="md" mb={2} textAlign="center">
          <Trans>No {GAMMA_ARTIFACT_PROPER_NOUN_PLURAL} found.</Trans>
        </Heading>
        <Text fontSize="lg" color="gray.400" textAlign="center">
          <Trans>
            Try changing your filters, or{' '}
            <Link as={NextLink} color="trueblue.500" href={`/#${INITIAL_TAB}`}>
              view all {GAMMA_ARTIFACT_PROPER_NOUN_PLURAL}
            </Link>
            .
          </Trans>
        </Text>
      </Flex>
    </Center>
  )
}
