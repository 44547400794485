import { CloseIcon } from '@chakra-ui/icons'
import {
  Badge,
  Box,
  Divider,
  Flex,
  IconButton,
  Spacer,
  Spinner,
  Stack,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { GammaTooltip } from '@gamma-app/ui'
import { Plural, t, Trans } from '@lingui/macro'
import { Dispatch, SetStateAction } from 'react'

import { Logo } from 'gamma_components'
import { Channel, User } from 'modules/api'
import { AICreditsModal } from 'modules/credits/components/AICreditsModal'
import { selectAvailableCredits } from 'modules/credits/reducer'
import { useFeatureFlag } from 'modules/featureFlags'
import { GAMMA_SUPPORT_EMAIL } from 'modules/help/constants'
import { GAMMA_ARTIFACT_PROPER_NOUN_PLURAL } from 'modules/i18n/properNouns'
import { useLaunchIntercom } from 'modules/intercom/hooks'
import { SidebarUpsell } from 'modules/monetization/components/SidebarUpsell'
import { useMonetizationContext } from 'modules/monetization/context/MonetizationContext'
import {
  useCanUseSites,
  useHasUnlimitedAI,
} from 'modules/monetization/hooks/productFeatures'
import { useAppSelector } from 'modules/redux'
import { FauxSearchInput } from 'modules/search'
import { CHANNELS_ICON } from 'modules/sharing/constants'
import { useCanManageWorkspace } from 'modules/user'
import { WorkspaceSwitcher } from 'modules/workspaces'
import {
  useOpenWorkspaceSettingsOnLoad,
  WorkspaceSettingsModal,
} from 'modules/workspaces/components/WorkspaceSettingsModal'
import { isMobileDevice } from 'utils/deviceDetection'

import { SidebarButton } from './SidebarButton'
import { SidebarChannels } from './SidebarChannels'
import { SidebarItem } from './SidebarItem'
import { SidebarItemLabel } from './SidebarItemLabel'
import { SidebarSkeleton } from './SidebarSkeleton'

// These are Union Types (instead of enums)
// Read more here:
//   https://stackoverflow.com/a/60041791
//   https://blog.bam.tech/developer-news/should-you-use-enums-or-union-types-in-typescript
export const DocsTabs = {
  SHARED_WITH_ME: 'shared-with-me',
  ALL: 'all',
  CHANNEL: 'channel',
  TRASH: 'trash',
  TEMPLATES: 'templates',
  INSPIRATION: 'inspiration',
  THEMES: 'themes',
  FONTS: 'fonts',
  SITES: 'sites',
} as const
type DocsTabsType = typeof DocsTabs[keyof typeof DocsTabs]

export type SidebarTabsType = DocsTabsType
export const SidebarTabs = { ...DocsTabs }

export interface SidebarTabGroups {
  DOCS: DocsTabsType
}

export type TabInfo = {
  displayName: React.ReactNode
  ariaLabel: string
  description?: React.ReactNode
  icon: IconProp
  showCreateButton?: boolean
}

export const TabMap: Record<valueof<SidebarTabGroups>, TabInfo> = {
  // Only used for users with no Org in DocsView (not sidebar) for the time being
  [DocsTabs.SHARED_WITH_ME]: {
    displayName: <Trans>Shared With Me</Trans>,
    ariaLabel: t`Shared with me`,
    icon: regular('paper-plane'),
    showCreateButton: false,
  },
  [DocsTabs.ALL]: {
    displayName: <Trans>All {GAMMA_ARTIFACT_PROPER_NOUN_PLURAL}</Trans>,
    ariaLabel: t`All ${GAMMA_ARTIFACT_PROPER_NOUN_PLURAL}`,
    icon: regular('rectangle-history'),
    showCreateButton: true,
  },
  [DocsTabs.SITES]: {
    displayName: <Trans comment="As in, websites">Sites</Trans>,
    ariaLabel: t`Sites`,
    icon: regular('globe'),
    showCreateButton: true,
  },
  [DocsTabs.CHANNEL]: {
    displayName: <Trans>Folders</Trans>,
    ariaLabel: t`Folders`,
    icon: CHANNELS_ICON,
    showCreateButton: true,
  },
  [DocsTabs.TRASH]: {
    displayName: <Trans>Trash</Trans>,
    ariaLabel: t`Trash`,
    icon: regular('trash'),
    showCreateButton: false,
  },
  [DocsTabs.TEMPLATES]: {
    displayName: <Trans>Templates</Trans>,
    ariaLabel: t`Templates`,
    icon: regular('bolt'),
    showCreateButton: false,
  },
  [DocsTabs.INSPIRATION]: {
    displayName: <Trans>Inspiration</Trans>,
    ariaLabel: t`Inspiration`,
    icon: regular('book-open'),
    showCreateButton: false,
  },
  [DocsTabs.THEMES]: {
    displayName: <Trans>Themes</Trans>,
    ariaLabel: t`Themes`,
    icon: regular('palette'),
    showCreateButton: false,
    description: (
      <Trans>
        Gamma comes with <b>built in themes</b> to style your{' '}
        {GAMMA_ARTIFACT_PROPER_NOUN_PLURAL}, but you can also create your own{' '}
        <b>custom themes</b> to match your brand
      </Trans>
    ),
  },
  [DocsTabs.FONTS]: {
    // To be eventually merged with themes
    displayName: <Trans>Custom fonts</Trans>,
    ariaLabel: t`Custom fonts`,
    icon: regular('book-font'),
    showCreateButton: false,
    description: (
      <Trans>
        Gamma comes with <b>Google Fonts</b>, but you can also upload your own
        to use in custom themes
      </Trans>
    ),
  },
}

type SidebarProps = {
  channelPreview: Channel | null
  isSidebarVisible: boolean
  setIsSidebarVisible: Dispatch<SetStateAction<boolean>>
  user?: User
  isReady: boolean
  channels: Channel[]
  isChannelsLoading: boolean
  activeTab: SidebarTabsType
  currentChannelId: string | null
}

export const Sidebar = ({
  channelPreview,
  isSidebarVisible,
  setIsSidebarVisible,
  isReady,
  activeTab,
  currentChannelId,
  channels,
  isChannelsLoading,
}: SidebarProps) => {
  const isIntercomEnabled = useFeatureFlag('intercom')
  const hasUnlimitedAI = useHasUnlimitedAI()
  const { product } = useMonetizationContext()
  const showCredits = hasUnlimitedAI === false
  const availableCredits = useAppSelector(selectAvailableCredits)
  const launchIntercomUI = useLaunchIntercom()

  const canManageWorkspace = useCanManageWorkspace()

  const {
    isOpen: isWorkspaceSettingsModalOpen,
    onOpen: onWorkspaceSettingsModalOpen,
    onClose: onWorkspaceSettingsModalClose,
  } = useDisclosure({ id: 'workspace-settings-modal' })

  const {
    isOpen: isAICreditsModalOpen,
    onOpen: onAICreditsModalOpen,
    onClose: onAICreditsModalClose,
  } = useDisclosure({ id: 'ai-credits-modal' })

  useOpenWorkspaceSettingsOnLoad({
    openModal: onWorkspaceSettingsModalOpen,
  })

  return (
    <Flex
      direction="column"
      zIndex="overlay"
      h="100vh"
      position={{ base: 'fixed', md: 'sticky' }}
      top={0}
      w="300px"
      minW="300px"
      bg="white"
      shadow={{
        base: isSidebarVisible ? 'xl' : 'none',
        md: 'none',
      }}
      transform={
        isSidebarVisible
          ? {
              base: 'translate(0,0px)',
              md: 'translate(0,0)',
            }
          : {
              base: 'translate(-100%,0px)',
              md: 'translate(0,0)',
            }
      }
      transition="transform .2s ease"
    >
      <Flex
        overflowY="auto"
        h="100vh"
        p={6}
        py={2}
        direction="column"
        alignContent={'space-between'}
      >
        {!isReady ? (
          <SidebarSkeleton setIsSidebarVisible={setIsSidebarVisible} />
        ) : (
          <>
            <Stack>
              <Flex alignItems="flex-start" justify="flex-end">
                <Box
                  display={{
                    base: 'block',
                    md: 'none',
                  }}
                >
                  <IconButton
                    size="sm"
                    aria-label={t`Close sidebar`}
                    variant="ghost"
                    icon={<CloseIcon w={3} />}
                    onClick={() => setIsSidebarVisible(false)}
                    isRound
                  />
                </Box>
              </Flex>
              <WorkspaceSwitcher onOpen={onWorkspaceSettingsModalOpen} />
              <FauxSearchInput />
            </Stack>
            <SidebarContent
              channelPreview={channelPreview}
              activeTab={activeTab}
              currentChannelId={currentChannelId}
              channels={channels}
              isChannelsLoading={isChannelsLoading}
            />
            <Box w="100%" pt={1} pb={3}>
              <Divider />
            </Box>
            {canManageWorkspace && (
              <SidebarButton
                dataTestId="invite-to-workspace"
                onClick={() => {
                  onWorkspaceSettingsModalOpen()
                }}
              >
                <SidebarItemLabel
                  icon={regular('user-plus')}
                  title={<Trans>Invite to workspace</Trans>}
                />
              </SidebarButton>
            )}
            {showCredits && (
              <SidebarButton
                dataTestId="ai-user-credits"
                onClick={onAICreditsModalOpen}
              >
                <SidebarItemLabel
                  icon={regular('coins')}
                  title={
                    availableCredits === null ? (
                      <Spinner size="xs" />
                    ) : (
                      <Plural
                        value={availableCredits}
                        one="# credit"
                        other="# credits"
                      />
                    )
                  }
                />
              </SidebarButton>
            )}
            {isIntercomEnabled && (
              <GammaTooltip
                label={
                  <Trans>
                    Share feedback, request features, reports bugs, and more!
                  </Trans>
                }
              >
                <SidebarButton
                  dataTestId="contact-button"
                  onClick={launchIntercomUI}
                >
                  <SidebarItemLabel
                    icon={regular('message-smile')}
                    title={<Trans>Contact us</Trans>}
                  />
                </SidebarButton>
              </GammaTooltip>
            )}
            {!isIntercomEnabled && (
              <SidebarButton
                dataTestId="contact-button"
                href={`mailto:${GAMMA_SUPPORT_EMAIL}`}
              >
                <SidebarItemLabel
                  icon={regular('envelope')}
                  title={<Trans>Email us</Trans>}
                />
              </SidebarButton>
            )}
            <Spacer />
            {product === null ? (
              <SidebarUpsell />
            ) : (
              <Flex justify="center" align="flex-end" width="100%" my={6}>
                <Box fontSize="12px" width="30px">
                  {Logo}
                </Box>
              </Flex>
            )}
          </>
        )}
      </Flex>
      <WorkspaceSettingsModal
        isOpen={isWorkspaceSettingsModalOpen}
        onClose={onWorkspaceSettingsModalClose}
      />
      <AICreditsModal
        isOpen={isAICreditsModalOpen}
        onClose={onAICreditsModalClose}
        credits={availableCredits}
        onOpen={onAICreditsModalOpen}
      />
    </Flex>
  )
}

// TODO - Consider refactoring this to not use Chakra Tabs, as the index based
// selecting makes things more complex than they need to be.
// See https://linear.app/gamma-app/issue/G-1606/fe-refactor-sidebartabs-and-make-them-into-something-other-than-chakra
const SidebarContent = ({
  channelPreview,
  activeTab,
  currentChannelId,
  channels,
  isChannelsLoading,
}: Pick<
  SidebarProps,
  | 'channelPreview'
  | 'activeTab'
  | 'currentChannelId'
  | 'channels'
  | 'isChannelsLoading'
>) => {
  const customFontsEnabled = useFeatureFlag('customFonts')
  const sitesEnabled = useCanUseSites()

  return (
    <VStack data-testid="sidebar-tablist" mt={4} mb={2} w="100%" spacing={1}>
      <Stack spacing={0} w="100%">
        <SidebarItem
          tabKey={DocsTabs.ALL}
          isSelected={activeTab === DocsTabs.ALL}
          href={`/#${DocsTabs.ALL}`}
        >
          <SidebarItemLabel
            icon={TabMap[DocsTabs.ALL].icon}
            title={TabMap[DocsTabs.ALL].displayName}
          />
        </SidebarItem>
        {sitesEnabled && !isMobileDevice() && (
          <SidebarItem
            pr={-1}
            tabKey={DocsTabs.SITES}
            isSelected={activeTab === DocsTabs.SITES}
            href={`/#${DocsTabs.SITES}`}
          >
            <SidebarItemLabel
              icon={TabMap[DocsTabs.SITES].icon}
              title={TabMap[DocsTabs.SITES].displayName}
              badge={
                <Badge
                  mr={6}
                  colorScheme="green"
                  lineHeight="normal"
                  fontSize="xxs"
                >
                  <Trans>Beta</Trans>
                </Badge>
              }
            />
          </SidebarItem>
        )}
      </Stack>
      <SidebarChannels
        channelPreview={channelPreview}
        isChannelsLoading={isChannelsLoading}
        channels={channels}
        currentChannelId={currentChannelId}
        activeTab={activeTab}
      />
      <Box w="100%" pt={1} pb={2}>
        <Divider />
      </Box>
      {!isMobileDevice() && (
        <SidebarItem
          tabKey={DocsTabs.TEMPLATES}
          isSelected={activeTab === DocsTabs.TEMPLATES}
          href={`/#${DocsTabs.TEMPLATES}`}
        >
          <SidebarItemLabel
            icon={TabMap[DocsTabs.TEMPLATES].icon}
            title={TabMap[DocsTabs.TEMPLATES].displayName}
          />
        </SidebarItem>
      )}
      <SidebarItem
        tabKey={DocsTabs.INSPIRATION}
        isSelected={activeTab === DocsTabs.INSPIRATION}
        href={`/#${DocsTabs.INSPIRATION}`}
      >
        <SidebarItemLabel
          icon={TabMap[DocsTabs.INSPIRATION].icon}
          title={TabMap[DocsTabs.INSPIRATION].displayName}
        />
      </SidebarItem>
      {!isMobileDevice() && (
        <SidebarItem
          tabKey={DocsTabs.THEMES}
          isSelected={activeTab === DocsTabs.THEMES}
          href={`/#${DocsTabs.THEMES}`}
        >
          <SidebarItemLabel
            icon={TabMap[DocsTabs.THEMES].icon}
            title={TabMap[DocsTabs.THEMES].displayName}
          />
        </SidebarItem>
      )}
      {customFontsEnabled && !isMobileDevice() && (
        <SidebarItem
          tabKey={DocsTabs.FONTS}
          isSelected={activeTab === DocsTabs.FONTS}
          href={`/#${DocsTabs.FONTS}`}
        >
          <SidebarItemLabel
            icon={TabMap[DocsTabs.FONTS].icon}
            title={TabMap[DocsTabs.FONTS].displayName}
          />
        </SidebarItem>
      )}
      <SidebarItem
        tabKey={DocsTabs.TRASH}
        isSelected={activeTab === DocsTabs.TRASH}
        href={`/#${DocsTabs.TRASH}`}
      >
        <SidebarItemLabel
          icon={TabMap[DocsTabs.TRASH].icon}
          title={TabMap[DocsTabs.TRASH].displayName}
        />
      </SidebarItem>
    </VStack>
  )
}
