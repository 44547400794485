import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { useCallback } from 'react'

import { Channel, useArchiveChannelMutation } from 'modules/api'
import { GAMMA_ARTIFACT_PROPER_NOUN_PLURAL } from 'modules/i18n/properNouns'
export const DeleteChannelModal = ({
  channel,
  workspaceId,
  onClose,
}: {
  channel: Channel
  workspaceId?: string
  onClose: () => void
}) => {
  const [archiveChannel] = useArchiveChannelMutation()
  const toast = useToast()
  const handleDeleteChannel = useCallback(() => {
    if (!workspaceId || !channel?.id) {
      console.error(
        `[onDeleteChannelClick] Cannot delete channel. OrgId: "${workspaceId}" channelId: "${channel?.id}"`
      )
      return
    }
    archiveChannel({
      variables: {
        channelId: channel.id,
        workspaceId: workspaceId as string,
      },
    }).then(() => {
      toast({
        title: <Trans>Folder successfully deleted from workspace.</Trans>,
        status: 'success',
        duration: 3000,
        position: 'top',
      })
    })
  }, [channel, toast, archiveChannel, workspaceId])

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={true}
      onClose={onClose}
      size="lg"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete {channel.name}?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb={2}>
            <Trans>
              This folder will be permanently deleted for all users.
            </Trans>
          </Text>
          <Text mb={2}>
            <Trans>
              Any {GAMMA_ARTIFACT_PROPER_NOUN_PLURAL} in this folder will lose
              their association with it.
            </Trans>
          </Text>
          <Text color="red.500">
            <Trans>This action cannot be undone.</Trans>
          </Text>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button variant="ghost" onClick={onClose}>
              <Trans>Cancel</Trans>
            </Button>
            <Button
              variant="ghost"
              colorScheme="red"
              onClick={() => {
                handleDeleteChannel()
                onClose()
              }}
            >
              <Trans>Delete folder</Trans>
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
