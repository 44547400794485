import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  Skeleton,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Router from 'next/router'
import { Dispatch, SetStateAction } from 'react'

import { DocumentGrid } from 'gamma_components/DocumentGrid'
import {
  DeckCategoryHeader,
  SimplifiedCategory,
  TemplateGridItem,
  TemplatesModal,
  useGetTemplateCategories,
} from 'modules/example_decks'
import { isMobileDevice } from 'utils/deviceDetection'

import { SidebarTabs, TabMap } from '../Sidebar'
import { TopbarWrapper } from '../Topbar'

const TemplateGridSkeleton = () => {
  return (
    <Flex flex={1} w="100%" direction="column">
      <Skeleton w="50%" h="48px" mb={4} />
      <Box w="100%">
        <Grid templateColumns={['repeat(2, 1fr)', 'repeat(3, 1fr)']} gap={6}>
          <GridItem
            bg="linen.50"
            borderRadius="5px"
            height="200px"
            as={Skeleton}
          />
          <GridItem
            bg="linen.50"
            borderRadius="5px"
            height="200px"
            as={Skeleton}
          />
          <GridItem
            bg="linen.50"
            borderRadius="5px"
            height="200px"
            as={Skeleton}
          />
        </Grid>
      </Box>
    </Flex>
  )
}
const CategoryRow = ({ category }: { category: SimplifiedCategory }) => {
  if (!category) return <></>
  const categoryItems = category.decks
  return (
    <Box key={category.id} mb={8}>
      <DeckCategoryHeader category={category} />
      <DocumentGrid>
        {categoryItems?.map((deck) => {
          return <TemplateGridItem deck={deck} key={deck.docId} />
        })}
      </DocumentGrid>
    </Box>
  )
}
export const TemplatesView = ({
  isSidebarVisible,
  setIsSidebarVisible,
}: {
  isSidebarVisible: boolean
  setIsSidebarVisible: Dispatch<SetStateAction<boolean>>
}) => {
  const { categories, loading } = useGetTemplateCategories()
  const { isOpen, onOpen, onClose } = useDisclosure({
    id: 'template-browser-modal',
  })

  if (isMobileDevice()) {
    // Don't let someone on mobile get to the Templates tab directly
    Router.replace('/')
    return null
  }

  const { displayName, ariaLabel, icon } = TabMap[SidebarTabs.TEMPLATES]
  return (
    <Box w="100%" data-testid="templates-view-container">
      <TopbarWrapper
        isSidebarVisible={isSidebarVisible}
        setIsSidebarVisible={setIsSidebarVisible}
      >
        <Heading fontSize="lg" fontWeight="600" data-testid="docs-view-header">
          {icon && <FontAwesomeIcon icon={icon} aria-label={ariaLabel} />}{' '}
          <Text as="span" ml={2}>
            {displayName}
          </Text>
        </Heading>
      </TopbarWrapper>
      {loading && <TemplateGridSkeleton />}
      {!loading &&
        categories &&
        categories?.length > 0 &&
        categories?.map((category: SimplifiedCategory) => {
          return <CategoryRow key={category.id} category={category} />
        })}

      {isOpen && <TemplatesModal isOpen={true} onClose={onClose} />}
    </Box>
  )
}
