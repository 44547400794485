import {
  Box,
  HStack,
  IconButton,
  Link,
  Skeleton,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  AvatarGroupAutoOverflow,
  GammaTooltip,
  SectionTitle,
} from '@gamma-app/ui'
import { t, Trans } from '@lingui/macro'
import uniqBy from 'lodash/uniqBy'
import { useDrop } from 'react-dnd'

import { Channel, ChannelActivity, Doc } from 'modules/api'
import { useFeatureFlag } from 'modules/featureFlags'
import {
  GAMMA_ARTIFACT_PROPER_NOUN,
  GAMMA_ARTIFACT_PROPER_NOUN_PLURAL,
} from 'modules/i18n/properNouns'
import { useAddDocToChannel, WorkspacePermissionsPrompt } from 'modules/sharing'
import { CHANNELS_ICON, CHANNELS_ICON_ACTIVE } from 'modules/sharing/constants'
import { GraphqlUser, useUserContext } from 'modules/user'
import { moveItemWithMatchingIdToEnd } from 'utils/array'
import { generateChannelUrl } from 'utils/url'

import { ChannelDocDragItem, ChannelDocDragItemType } from '../../types'
import { ManageChannelsModal } from '../channels'
import { DocsTabs, SidebarTabsType } from './Sidebar'
import { SidebarItem } from './SidebarItem'
import { SidebarItemLabel } from './SidebarItemLabel'

const getIcon = ({
  isSelected,
  hasActivity,
}: {
  isSelected: boolean
  hasActivity: boolean
}) => {
  if (hasActivity) {
    return isSelected ? regular('signal-stream') : regular('signal-stream')
  }
  return isSelected ? CHANNELS_ICON_ACTIVE : CHANNELS_ICON
}

const MAX_ACTIVE_USERS = 3

const ChannelActivityInfo = ({
  activity,
  user,
}: {
  activity?: ChannelActivity
  user?: GraphqlUser
}) => {
  if (!activity?.activeUsers) return <></>
  const uniqueActiveUsers = uniqBy(activity.activeUsers, 'id')
  const avatars = moveItemWithMatchingIdToEnd(uniqueActiveUsers, user?.id)

  return (
    <AvatarGroupAutoOverflow
      overflowTooltipLabel={t`See everyone`}
      position="absolute"
      right={2}
      size="xs"
      max={MAX_ACTIVE_USERS}
      avatars={avatars.map(({ displayName, profileImageUrl, id }) => {
        return {
          name: displayName || '',
          id,
          profileImageUrl,
          color: 'white',
          badge: { color: 'green.500', size: '1em' },
        }
      })}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}
    />
  )
}
type SidebarChannelItemProps = {
  isPreview?: boolean
  channel: Channel
  activeTab: SidebarTabsType
  currentChannelId: string | null
  addDocToChannel: (doc: Doc, channel: Channel) => void
  user?: GraphqlUser
}
const SidebarChannelItem = ({
  isPreview,
  channel,
  activeTab,
  currentChannelId,
  addDocToChannel,
  user,
}: SidebarChannelItemProps) => {
  const isChannelActivityEnabled = useFeatureFlag('channelActivity')

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: ChannelDocDragItemType,
    drop: (doc: ChannelDocDragItem) => {
      addDocToChannel(doc, channel)
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }))

  const isSelected =
    activeTab === DocsTabs.CHANNEL && channel.id === currentChannelId
  const hasActivity =
    isChannelActivityEnabled &&
    Boolean(
      channel?.activity?.activeUsers &&
        channel?.activity?.activeUsers.length > 0
    )

  const icon = getIcon({ isSelected, hasActivity })
  return (
    <SidebarItem
      ref={drop}
      data-testid="sidebar-item"
      key={channel.id}
      tabKey={`${DocsTabs.CHANNEL}-${channel.id}`}
      isDropActive={isOver && canDrop}
      isSelected={isSelected}
      href={generateChannelUrl({ id: channel.id, slug: channel.slug })}
      opacity={isPreview ? '0.5' : undefined}
      justifyContent="space-between"
      w="100%"
    >
      <SidebarItemLabel
        icon={icon}
        title={channel.name}
        faClassName={isSelected && hasActivity ? 'fa-beat-fade' : undefined}
        w="18ch"
      />
      {hasActivity && (
        <ChannelActivityInfo activity={channel?.activity} user={user} />
      )}
    </SidebarItem>
  )
}

type SidebarChannelsProps = {
  channelPreview: Channel | null
  channels: Channel[]
  activeTab: SidebarTabsType
  currentChannelId: string | null
  isChannelsLoading: boolean
}
export const SidebarChannels = ({
  channelPreview,
  channels,
  activeTab,
  currentChannelId,
  isChannelsLoading,
}: SidebarChannelsProps) => {
  const { user, currentWorkspace } = useUserContext()

  const {
    isOpen: isWorkspacePermissionPromptOpen,
    onClose: onWorkspacePermissionPromptClose,
    doc: droppedDoc,
    handleAddDocToChannel,
    updateWorkspacePermissionThenAddToChannel,
  } = useAddDocToChannel()

  const {
    isOpen: isManageOpen,
    onOpen: onManageOpen,
    onClose: onManageClose,
  } = useDisclosure({ id: 'manageChannelsModalDisclosure' })

  if (isChannelsLoading) {
    return (
      <>
        <Skeleton h={'30px'} w="100%" />
        <Skeleton h={'30px'} w="100%" />
        <Skeleton h={'30px'} w="100%" />
      </>
    )
  }
  return (
    <Box data-testid="sidebar-channels" w="100%">
      <HStack justify="space-between">
        <SectionTitle textAlign="left" mt={4} mb={3} paddingInlineStart={1}>
          <Trans>Folders</Trans>
        </SectionTitle>
        <GammaTooltip label={<Trans>Create or join a folder</Trans>}>
          <IconButton
            aria-label={t`Create or join a folder`}
            size="xs"
            isRound
            variant="plain"
            icon={<FontAwesomeIcon icon={regular('plus')} />}
            onClick={onManageOpen}
          />
        </GammaTooltip>
      </HStack>

      {channelPreview && (
        <SidebarChannelItem
          isPreview={true}
          key={channelPreview.id}
          channel={channelPreview}
          activeTab={activeTab}
          currentChannelId={currentChannelId}
          addDocToChannel={handleAddDocToChannel}
          user={user}
        />
      )}
      {channels.map((channel) => {
        return (
          <SidebarChannelItem
            key={channel.id}
            channel={channel}
            activeTab={activeTab}
            currentChannelId={currentChannelId}
            addDocToChannel={handleAddDocToChannel}
            user={user}
          />
        )
      })}

      {channels.length === 0 && (
        <Box bgColor="gray.50" textAlign="center" p={4} mb={2}>
          <Text fontSize="sm">
            <Trans>
              Organize your {GAMMA_ARTIFACT_PROPER_NOUN_PLURAL} by topic and
              share them with your team
            </Trans>
          </Text>
          <Text fontSize="sm" mt={2} fontWeight="bold">
            <Link color="trueblue.600" href="#" onClick={onManageOpen}>
              <Trans>Create or join a folder</Trans>
            </Link>
          </Text>
        </Box>
      )}

      <ManageChannelsModal
        isOpen={isManageOpen}
        onClose={onManageClose}
        workspaceId={currentWorkspace?.id}
      />
      {isWorkspacePermissionPromptOpen && (
        <WorkspacePermissionsPrompt
          doc={droppedDoc}
          onSubmit={updateWorkspacePermissionThenAddToChannel}
          onClose={onWorkspacePermissionPromptClose}
        />
      )}
    </Box>
  )
}
