import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { useCallback } from 'react'

import { Font } from 'modules/api'

import { useDeleteFont } from '../hooks'

type FontDeleteModalProps = {
  onClose: () => void
  font: Font
  orgId: string
}
export const FontDeleteModal = ({
  onClose,
  font,
  orgId,
}: FontDeleteModalProps) => {
  const { archiveFontFn, isArchiving } = useDeleteFont(orgId)

  const onFontDelete = useCallback(() => {
    archiveFontFn(font.id, font.name)
      .then(onClose)
      .catch((err) => {
        // TODO display an error state
        console.error('[FontDeleteModal] Error deleting font', err)
      })
  }, [archiveFontFn, font.id, font.name, onClose])

  return (
    <Modal onClose={onClose} isOpen={true}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Trans>Delete {font.name}?</Trans>
        </ModalHeader>
        <ModalCloseButton isDisabled={isArchiving} />
        <ModalBody>
          <Stack spacing={4}>
            <Text fontWeight="bold">
              <Trans>Are you sure you want to delete this font family?</Trans>
            </Text>
            <Text>
              <Trans>
                Any custom themes using this font will not break, but they will
                not be able to select it again
              </Trans>
            </Text>
            <Text color="red.600">
              <Trans>This action cannot be undone</Trans>
            </Text>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button variant="ghost" onClick={onClose}>
              <Trans>Cancel</Trans>
            </Button>
            <Button
              variant="ghost"
              color="red.600"
              onClick={onFontDelete}
              isLoading={isArchiving}
            >
              <Trans>Delete font family</Trans>
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
