import { useToast } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { useCallback, useState } from 'react'

import { config } from 'config'
import {
  documentTypes,
  slideTypes,
  uploadFile,
} from 'modules/media/apis/transloadit'
import { ImageUploadResult } from 'modules/media/types/ImageUpload'
import { useUserContext } from 'modules/user'

import { useHiddenFileInput } from '../fonts/components/useHiddenFileInput'
import { ImportedFile } from './types'

type UseUploadDocHandlers = {
  onUploadStarted?: () => void
  onUploadComplete: (file: ImportedFile) => void
  onUploadFailed?: (message?: string) => void
}

export const useUploadDoc = (handlers: UseUploadDocHandlers) => {
  const toast = useToast()
  const { currentWorkspace } = useUserContext()
  const [isUploading, setUploading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const handleDocUpload = useCallback(
    async (files: File[]) => {
      handlers.onUploadStarted?.()
      setUploading(true)
      const file = files[0]
      const isPPT = slideTypes.includes(file.type)
      const loadDocToast = toast({
        id: `load-doc-toast-${file.name}`,
        position: 'top',
        duration: null,
        status: 'loading',
        variant: 'subtle',
        title: <Trans>Importing...</Trans>,
        description: <Trans>This can take up to a minute</Trans>,
        isClosable: false,
      }) as string

      uploadFile(
        file,
        currentWorkspace?.id,
        {
          onUploadComplete: async (result: ImageUploadResult) => {
            const { src, name } = result
            const html = await fetch(src).then((response) => response.text())
            const title = name.replace(/[.]html$/, '')
            handlers.onUploadComplete({
              html,
              filename: title,
              source: isPPT ? 'PptxUpload' : 'DocxUpload',
            })
            toast.update(loadDocToast, {
              status: 'success',
              title: <Trans>Success!</Trans>,
              duration: 5000,
              isClosable: true,
            })
            setIsSuccess(true)
            setUploading(false)
          },
          onUploadFailed(message) {
            toast.update(loadDocToast, {
              status: 'error',
              title: <Trans>Error</Trans>,
              description: (
                <Trans>
                  Sorry, we couldn't import this file. Please try again.
                </Trans>
              ),
              duration: 5000,
              isClosable: true,
            })
            handlers.onUploadFailed?.(message)
            setUploading(false)
            setIsSuccess(false)
          },
        },
        undefined,
        'importDoc',
        config.TRANSLOADIT_DOC_IMPORT_TEMPLATE_ID
      )
    },
    [currentWorkspace?.id, handlers, toast]
  )

  const { onClick: initiateDocUpload, inputElement: hiddenInputElement } =
    useHiddenFileInput({
      handleFilesCallback: handleDocUpload,
      multiple: false,
      accept: [...documentTypes, ...slideTypes].join(','),
    })

  return {
    initiateDocUpload,
    hiddenInputElement,
    isUploading,
    handleDocUpload,
    isSuccess,
  }
}
