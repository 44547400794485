import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'
import capitalize from 'lodash/capitalize'

import { WorkspaceRole } from 'modules/api'
import { workspaceRoleMap } from 'modules/workspaces/constants'

type WorkspaceRoleMenuProps = {
  selected: WorkspaceRole
  options: WorkspaceRole[]
  onSelect: (opt: WorkspaceRole) => void
  isMenuDisabled: boolean
  disabledOptions?: { disabledOption: WorkspaceRole; reason: string }[]
  deleteLabel?: React.ReactNode
  onDeleteClick: () => void
  disabledDeleteReason?: string
}

export const EditMemberMenu = ({
  selected,
  options,
  onSelect,
  isMenuDisabled,
  disabledOptions = [],
  deleteLabel = <Trans>Remove from workspace</Trans>,
  onDeleteClick,
  disabledDeleteReason,
}: WorkspaceRoleMenuProps) => {
  const buttonLabel =
    selected === WorkspaceRole.Admin ? (
      <Trans>Admin</Trans>
    ) : (
      <Trans>Member</Trans>
    )

  return (
    <Menu closeOnSelect={true}>
      <MenuButton
        as={Button}
        isDisabled={isMenuDisabled}
        variant="ghost"
        size="sm"
        rightIcon={<FontAwesomeIcon icon={regular('chevron-down')} />}
      >
        {buttonLabel}
      </MenuButton>
      <MenuList maxW="320px" zIndex="dropdown">
        <MenuOptionGroup
          defaultValue={selected}
          value={selected}
          type="radio"
          onChange={(value) => {
            if (!Array.isArray(value)) {
              onSelect(value as WorkspaceRole)
            }
          }}
        >
          {options.map((option) => {
            const disabledOption = disabledOptions.find(
              (f) => f.disabledOption === option
            )
            return (
              <MenuItemOption
                key={option}
                value={option}
                isDisabled={!!disabledOption}
                pt={3}
              >
                <Tooltip
                  aria-label={disabledOption?.reason}
                  label={disabledOption?.reason}
                  isDisabled={!disabledOption}
                  placement="right"
                  shouldWrapChildren // so tooltip shows on disabled buttons
                >
                  <Box lineHeight="base">
                    <Text fontSize="md">{workspaceRoleMap[option].title}</Text>
                    <Text fontSize="sm" color="gray.500">
                      {workspaceRoleMap[option].description}
                    </Text>
                  </Box>
                </Tooltip>
              </MenuItemOption>
            )
          })}
          <MenuDivider />
          <Tooltip
            aria-label={disabledDeleteReason}
            label={disabledDeleteReason}
            isDisabled={!disabledDeleteReason}
            placement="right"
            shouldWrapChildren
          >
            <MenuItem
              onClick={onDeleteClick}
              color="red.500"
              fontSize="md"
              lineHeight="base"
              isDisabled={Boolean(disabledDeleteReason)}
            >
              {deleteLabel}
            </MenuItem>
          </Tooltip>
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  )
}
