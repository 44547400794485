import {
  Badge,
  Box,
  Button,
  ButtonProps,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'
import { GoogleOAuthProvider } from '@react-oauth/google'

import { config } from 'config'
import { useFeatureFlag } from 'modules/featureFlags'
import GoogleDocsIcon from 'modules/media/providers/icons/google-docs.svg'
import GoogleSlidesIcon from 'modules/media/providers/icons/google-slides.svg'
import WordIcon from 'modules/media/providers/icons/ms-word.svg'
import PowerpointIcon from 'modules/media/providers/icons/powerpoint.svg'

import { ImportedFile } from './types'
import { useImportGoogleDoc } from './useImportGoogleDoc'
import { useUploadDoc } from './useUploadDoc'

type ImportDocPickerProps = {
  handleImport: (file: ImportedFile) => void
  isDisabled?: boolean
  buttonProps?: ButtonProps
  helpTip?: string | JSX.Element
}

export const ImportDocPicker = (props: ImportDocPickerProps) => {
  const docImportEnabled = useFeatureFlag('docImport')
  const pptUploadEnabled = useFeatureFlag('pptImport')
  if (!docImportEnabled && !pptUploadEnabled) {
    return null
  }

  return (
    <GoogleOAuthProvider clientId={config.GOOGLE_DRIVE_IMPORT_CLIENT_ID}>
      <ImportDocMenu {...props} />
    </GoogleOAuthProvider>
  )
}

const ImportDocMenu = ({
  handleImport,
  isDisabled,
  buttonProps,
  helpTip,
}: ImportDocPickerProps) => {
  const docImportEnabled = useFeatureFlag('docImport')
  const pptUploadEnabled = useFeatureFlag('pptImport')

  const { initiateDocUpload, hiddenInputElement, isUploading } = useUploadDoc({
    onUploadComplete: handleImport,
  })
  const { initiateGoogleImport, isImporting } = useImportGoogleDoc({
    onImportComplete: handleImport,
  })

  return (
    <Menu>
      <MenuButton
        as={Button}
        // Prevents tooltip from opening on focus, see https://github.com/chakra-ui/chakra-ui/issues/5304#issuecomment-1102836734
        onFocus={(e) => e.preventDefault()}
        data-testid="import-doc-button"
        isDisabled={isDisabled}
        leftIcon={<FontAwesomeIcon icon={regular('file-import')} />}
        rightIcon={<FontAwesomeIcon icon={regular('angle-down')} />}
        sx={{
          span: {
            // Keeps the icons from getting pushed to the edges on mobile
            flexGrow: 0,
          },
        }}
        isLoading={isUploading || isImporting}
        {...buttonProps}
      >
        <Trans>Import</Trans>
      </MenuButton>
      <Portal>
        <MenuList maxW="350px" zIndex="tooltip">
          {docImportEnabled && (
            <MenuItem
              icon={<Image h="1.5em" w="1.5em" src={GoogleDocsIcon.src} />}
              onClick={initiateGoogleImport}
            >
              <Trans>Google Docs</Trans>
            </MenuItem>
          )}
          {docImportEnabled && pptUploadEnabled && (
            <MenuItem
              icon={<Image h="1.5em" w="1.5em" src={GoogleSlidesIcon.src} />}
              onClick={initiateGoogleImport}
            >
              <Trans>Google Slides (text only)</Trans>
              <Badge colorScheme="green" ml={2}>
                <Trans>Beta</Trans>
              </Badge>
            </MenuItem>
          )}
          {pptUploadEnabled && (
            <MenuItem
              icon={<Image h="1.5em" w="1.5em" src={WordIcon.src} />}
              onClick={initiateDocUpload}
            >
              <Trans>Word Document</Trans>
            </MenuItem>
          )}
          {pptUploadEnabled && (
            <MenuItem
              icon={<Image h="1.5em" w="1.5em" src={PowerpointIcon.src} />}
              onClick={initiateDocUpload}
            >
              <Trans>Powerpoint (text only)</Trans>
              <Badge colorScheme="green" ml={2}>
                <Trans>Beta</Trans>
              </Badge>
            </MenuItem>
          )}
          {helpTip && (
            <>
              <MenuDivider />
              <Box px={4} py={0}>
                {helpTip}
              </Box>
            </>
          )}
          {hiddenInputElement}
        </MenuList>
      </Portal>
    </Menu>
  )
}
