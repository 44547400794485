import { Flex, Stack, Text } from '@chakra-ui/react'

type GridItemButtonProps = {
  label: JSX.Element
  icon: React.ReactNode
  onClick: () => void
}

export const GridItemButton = ({
  label,
  icon,
  onClick,
}: GridItemButtonProps) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      border="1px solid"
      borderColor="gray.200"
      borderRadius="lg"
      background="gray.50"
      minH={36}
      minW="100%"
      _hover={{
        background: 'white',
        color: 'trueblue.600',
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <Stack textAlign="center">
        <Text fontSize="xl">{icon}</Text>
        <Text>{label}</Text>
      </Stack>
    </Flex>
  )
}
