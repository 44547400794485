import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { useCallback } from 'react'

import { Doc } from 'modules/api'
import { useDocMutations } from 'modules/api/hooks/useDocMutations'

type ConfirmPermanentlyDeleteModalProps = {
  onClose: () => void
  doc: Doc
}
export const ConfirmPermanentlyDeleteModal = ({
  onClose,
  doc,
}: ConfirmPermanentlyDeleteModalProps) => {
  const { permanentlyDeleteDoc, isPermanentlyDeletingDoc } = useDocMutations({
    refetchQueries: ['GetDocs', 'GetChannelDocActivity'],
  })
  const onPermanentlyDelete = useCallback(() => {
    if (!permanentlyDeleteDoc) {
      return
    }
    permanentlyDeleteDoc(doc.id).then(onClose)
  }, [doc.id, onClose, permanentlyDeleteDoc])

  return (
    <Modal onClose={onClose} isOpen={true}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Trans>Delete forever?</Trans>
        </ModalHeader>
        <ModalCloseButton isDisabled={isPermanentlyDeletingDoc} />
        <ModalBody>
          <Stack spacing={4}>
            <Text>
              <Trans>
                "{doc.title}" will be permanently deleted and you won't be able
                to restore it.
              </Trans>
            </Text>
            <Text color="red.500">
              <Trans>This action cannot be undone.</Trans>
            </Text>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button variant="ghost" onClick={onClose}>
              <Trans>Cancel</Trans>
            </Button>
            <Button
              variant="ghost"
              colorScheme="red"
              onClick={onPermanentlyDelete}
              isLoading={isPermanentlyDeletingDoc}
            >
              <Trans>Permanently delete</Trans>
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
