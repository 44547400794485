import {
  Badge,
  Button,
  ButtonProps,
  Divider,
  HStack,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Portal,
  Spacer,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { t, Trans } from '@lingui/macro'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { useRouter } from 'next/router'

import { config } from 'config'
import { useFeatureFlag } from 'modules/featureFlags'
import { ImportButtons } from 'modules/import/ImportButtons'

import { ImportedFile } from './types'

type ImportDocPickerV2Props = {
  handleImport: (file: ImportedFile) => void
  isDisabled?: boolean
  buttonProps?: ButtonProps
  helpTip?: string | JSX.Element
}

export const ImportDocPickerV2 = (props: ImportDocPickerV2Props) => {
  const docImportEnabled = useFeatureFlag('docImport')
  const pptUploadEnabled = useFeatureFlag('pptImport')
  if (!docImportEnabled && !pptUploadEnabled) {
    return null
  }

  return (
    <GoogleOAuthProvider clientId={config.GOOGLE_DRIVE_IMPORT_CLIENT_ID}>
      <ImportDocMenuV2 {...props} />
    </GoogleOAuthProvider>
  )
}

const ImportDocMenuV2 = ({
  handleImport,
  isDisabled,
  buttonProps,
  helpTip,
}: ImportDocPickerV2Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure({
    id: 'import-plain-modal',
  })

  const { push } = useRouter()
  return (
    <Menu>
      <MenuButton
        as={Button}
        // Prevents tooltip from opening on focus, see https://github.com/chakra-ui/chakra-ui/issues/5304#issuecomment-1102836734
        onFocus={(e) => e.preventDefault()}
        data-testid="import-doc-button"
        isDisabled={isDisabled}
        leftIcon={<FontAwesomeIcon icon={regular('file-import')} />}
        rightIcon={<FontAwesomeIcon icon={regular('angle-down')} />}
        sx={{
          span: {
            // Keeps the icons from getting pushed to the edges on mobile
            flexGrow: 0,
          },
        }}
        {...buttonProps}
      >
        <Trans>Import</Trans>
      </MenuButton>
      <Portal>
        <MenuList maxW="350px" zIndex="tooltip">
          <MenuGroup title={t`Import file`}>
            <MenuItem
              icon={<FontAwesomeIcon icon={solid('sparkles')} fixedWidth />}
              onClick={() => {
                push('/create/import?ref=dashboard')
              }}
            >
              <HStack align="flex-start">
                <Stack spacing={0}>
                  <Text>
                    <Trans>AI import</Trans>
                  </Text>
                  <Text fontSize="xs" color="gray.700">
                    <Trans>
                      Import and transform files. Add images and layouts, scale
                      content and more
                    </Trans>
                  </Text>
                </Stack>
                <Spacer />
                <Badge h="fit-content" colorScheme="trueblue" ml={2}>
                  <Trans>AI</Trans>
                </Badge>
              </HStack>
            </MenuItem>
            <MenuItem
              icon={
                <FontAwesomeIcon icon={regular('file-import')} fixedWidth />
              }
              onClick={onOpen}
            >
              <Stack spacing={0}>
                <Text>
                  <Trans>Plain import</Trans>
                </Text>
                <Text fontSize="xs" color="gray.700">
                  <Trans>
                    Import the text content from docs, presentations and more
                  </Trans>
                </Text>
              </Stack>
            </MenuItem>
          </MenuGroup>
        </MenuList>
      </Portal>

      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent bg="gray.50">
          <ModalHeader>
            <HStack spacing={3}>
              <FontAwesomeIcon icon={regular('file-import')} />
              <Text>
                <Trans>Import file</Trans>
              </Text>
            </HStack>
          </ModalHeader>
          <Divider />
          <ModalCloseButton />
          <ModalBody py={4} px={{ base: 4, md: 6 }}>
            <Stack spacing={4} justifyContent="flex-start">
              <Text>
                <Trans>Import the text content from files into Gamma</Trans>
              </Text>
              <ImportButtons
                onImportComplete={handleImport}
                hidePasteLink={true}
              />
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>
              <Trans>Cancel</Trans>
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Menu>
  )
}
