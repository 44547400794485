import {
  AspectRatio,
  Badge,
  Box,
  Flex,
  GridItem,
  HStack,
  IconButton,
  Image,
  LinkBox,
  LinkOverlay,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  Spacer,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { t, Trans } from '@lingui/macro'
import NextLink from 'next/link'

import { CreatedByPanel } from 'gamma_components/CreatedByPanel'
import placeholderBackground from 'gamma_components/placeholderBackground.svg'
import { GetSiteQuery } from 'modules/api'
import { formatDistanceToNowStrict } from 'modules/i18n/utils/date'
import {
  getLiveSiteUrl,
  getSiteManagerUrl,
  getSiteManagerUrlSettings,
} from 'modules/sites/utils'
import { useUserContext } from 'modules/user'

export const SiteTile = ({ site }: { site: GetSiteQuery['site'] }) => {
  const { isOpen, onOpen } = useDisclosure()
  const siteUrl = getSiteManagerUrl(site.id, site.name)
  const { user } = useUserContext()
  const liveSiteUrl =
    site &&
    getLiveSiteUrl({
      site,
      path: '/',
    })

  const generalSettingsUrl = site && getSiteManagerUrlSettings(site.id)
  return (
    <LinkBox
      as={GridItem}
      bg="linen.50"
      borderRadius="5px"
      shadow="md"
      _focusWithin={{ shadow: 'outline', _hover: { shadow: 'outline' } }}
      cursor="pointer"
      _hover={{
        shadow: 'lg',
        bgColor: 'gray.100',
      }}
      transition="box-shadow .2s ease"
      role="group"
      className="highlight-mask"
    >
      {site?.routes.length > 2 && (
        <Box
          pos="absolute"
          top={-2}
          left={2}
          right={2}
          bottom="calc(66%)"
          bg="whiteAlpha.600"
          zIndex={0}
          borderRadius="md"
        />
      )}

      {site?.routes.length > 1 && (
        <Box
          pos="absolute"
          top={-1}
          left={1}
          right={1}
          bottom="calc(66%)"
          bg="whiteAlpha.900"
          zIndex={0}
          borderRadius="md"
        />
      )}

      <Flex
        direction="column"
        wordBreak="break-word"
        alignContent="space-between"
        height="100%"
        bg="white"
        _hover={{
          bgColor: 'gray.100',
        }}
        borderBottomRadius="md"
      >
        <LinkOverlay href={siteUrl} as={NextLink} borderRadius="md">
          <AspectRatio ratio={40 / 21} bgColor="gray.100">
            <Flex
              borderBottomWidth="1px"
              borderBottomColor="gray.100"
              pos="relative"
              overflow="hidden"
              borderTopRadius="md"
              shadow={
                site?.routes.length > 1
                  ? '0px -4px 9px -8px rgba(0,0,0,0.4);'
                  : 'none'
              }
            >
              <Image
                src={site?.previewUrl}
                fit="cover"
                fallbackSrc={placeholderBackground.src}
              />
              {site?.routes.length > 1 && (
                <Badge
                  pos="absolute"
                  bottom={2}
                  right={2}
                  colorScheme="blackAlpha"
                  bg="gray.100"
                  color="gray.600"
                >
                  <HStack spacing={1}>
                    <FontAwesomeIcon
                      fixedWidth
                      icon={regular('rectangle-history')}
                    />
                    <Text>{site?.routes.length}</Text>
                  </HStack>
                </Badge>
              )}
            </Flex>
          </AspectRatio>
        </LinkOverlay>
        <Flex
          p={3}
          position="relative"
          direction="column"
          justifyContent={'space-between'}
          h="100%"
          alignItems="flex-start"
        >
          <LinkOverlay href={siteUrl} as={NextLink}>
            <Stack spacing={0} mb={2}>
              <Text noOfLines={2}>{site.name}</Text>
              <HStack>
                {site?.faviconUrl && (
                  <Image maxW="16px" maxH="16px" src={site?.faviconUrl} />
                )}
                <Text
                  noOfLines={1}
                  color="gray.500"
                  fontSize="xs"
                  wordBreak="break-all"
                >
                  {site?.domains?.[0]?.name}
                </Text>
              </HStack>
            </Stack>
          </LinkOverlay>

          <Flex direction="column" w="100%">
            <Flex mt={2} align="flex-start" w="100%" justify="space-between">
              {site?.createdBy && (
                <CreatedByPanel
                  createdByYou={site.createdBy.id === user?.id}
                  createdByName={site.createdBy.displayName || t`unknown`}
                  createdByProfileImageUrl={site.createdBy.profileImageUrl}
                  timestampDescription={
                    <Trans>
                      Created {formatDistanceToNowStrict(site.createdTime)} ago
                    </Trans>
                  }
                />
              )}
              <Spacer />
              <Menu isLazy={true} closeOnSelect={false}>
                <MenuButton
                  data-dashboard-site-menu
                  as={IconButton}
                  size="xs"
                  variant="ghost"
                  borderRadius="full"
                  onClick={onOpen}
                  icon={
                    <FontAwesomeIcon fixedWidth icon={regular('ellipsis-h')} />
                  }
                  color="gray.400"
                  _hover={{ color: 'gray.800', bg: 'gray.200' }}
                >
                  <Trans>Actions</Trans>
                </MenuButton>
                {isOpen && site && (
                  <Portal>
                    <MenuList maxW="sm" zIndex="overlay">
                      {liveSiteUrl && (
                        <>
                          <MenuItem
                            href={liveSiteUrl}
                            as={NextLink}
                            target="_blank"
                            icon={
                              <FontAwesomeIcon
                                fixedWidth
                                icon={regular('square-arrow-up-right')}
                              />
                            }
                          >
                            <Trans>View live site</Trans>
                          </MenuItem>
                          <MenuDivider />
                        </>
                      )}
                      {generalSettingsUrl && (
                        <MenuItem
                          as={NextLink}
                          href={generalSettingsUrl}
                          icon={
                            <FontAwesomeIcon
                              fixedWidth
                              icon={regular('gear')}
                            />
                          }
                        >
                          <Trans>Settings</Trans>
                        </MenuItem>
                      )}
                      {/* <MenuDivider /> */}
                      {/* <MenuItem
                        color="red"
                        icon={
                          <FontAwesomeIcon fixedWidth icon={regular('trash')} />
                        }
                        onClick={() => {
                          // TODO: Let people delete a site
                          // Hack to close the menu so we don't see a flash of other menu items
                          onClose()
                        }}
                      >
                        <Trans>Delete</Trans>
                      </MenuItem> */}
                    </MenuList>
                  </Portal>
                )}
              </Menu>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </LinkBox>
  )
}
