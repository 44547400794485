import { getSchema, JSONContent } from '@tiptap/core'

import { getBaseExtensions } from 'modules/tiptap_editor/EditorCore'
import { SplitOptions } from 'modules/tiptap_editor/extensions/Clipboard/splitCards'
import { parseExternalHtml } from 'modules/tiptap_editor/extensions/Clipboard/parseExternalHtml'

import { initialDocWithCards } from '../tiptap_editor/defaultDocs'

export const parseImportedDoc = (
  html: string,
  splitCardOptions: SplitOptions
) => {
  const baseExtensions = getBaseExtensions()
  const schema = getSchema(baseExtensions)
  const { slice } = parseExternalHtml(html, schema, splitCardOptions, false)

  const splitContent: JSONContent[] = slice.toJSON()?.content || []

  let doc: JSONContent
  if (splitContent.every((node) => node.type === 'card')) {
    doc = initialDocWithCards(undefined, splitContent)
  } else {
    // This is a basic way of checking that the input will match our schema
    // ProseMirror does something much more complex, but that requires an
    // editor instance. We can explore that as a followup. See
    // https://linear.app/gamma-app/issue/G-2261/import-fails-when-there-are-inline-elements-at-the-top-level
    const content =
      splitContent.filter((node) => schema.nodes[node.type!].isBlock) || []
    doc = initialDocWithCards(undefined, [
      {
        type: 'card',
        content,
      },
    ])
  }

  console.debug('Parsing imported doc', {
    doc,
    slice,
    splitContent,
    html,
  })
  return doc
}
