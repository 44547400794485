import {
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  Text,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { Trans } from '@lingui/macro'
import { format, parseISO } from 'date-fns'
import { memo } from 'react'

import { Theme } from '../types'
import { ArchiveThemeModal } from './ArchiveThemeModal'

type ThemePreviewMenuProps = {
  theme: Theme
  openThemeEditorWithThisTheme: () => void
  onDuplicate: () => void
  onArchiveThemeModalOpen: () => void
  isArchiveThemeModalOpen: boolean
  onArchiveThemeModalClose: () => void
}
const ThemePreviewMenuComponent = ({
  theme,
  openThemeEditorWithThisTheme,
  onDuplicate,
  onArchiveThemeModalOpen,
  isArchiveThemeModalOpen,
  onArchiveThemeModalClose,
}: ThemePreviewMenuProps) => {
  return (
    <Menu isLazy>
      <MenuButton
        as={IconButton}
        isRound
        size="xs"
        variant="ghost"
        colorScheme="blackAlpha"
        icon={<FontAwesomeIcon icon={regular('ellipsis')} />}
        onClick={(ev) => ev.stopPropagation()} // Prevents clicking the ... from changing the theme
      >
        <Trans>Actions</Trans>
      </MenuButton>

      <Portal>
        <MenuList
          zIndex="modal" // Necessary when this component is rendered inside a drawer
          onClick={(ev) => ev.stopPropagation()} // Prevents clicking a menu item from changing the theme
        >
          <Box px={4} py={0} maxWidth="300px">
            <Text noOfLines={3}>{theme.name}</Text>
            {theme.createdTime && (
              <Text fontSize="sm" color="gray.500" noOfLines={1}>
                <Trans>
                  Created {format(parseISO(theme.createdTime), 'PPP')}
                </Trans>
              </Text>
            )}
            {theme.createdBy && (
              <GammaTooltip label={theme.createdBy.email}>
                <Text
                  display="inline-block"
                  fontSize="sm"
                  color="gray.500"
                  noOfLines={1}
                >
                  <Trans>by {theme.createdBy.displayName}</Trans>
                </Text>
              </GammaTooltip>
            )}
          </Box>
          <MenuDivider />
          <MenuItem
            icon={<FontAwesomeIcon icon={regular('palette')} fixedWidth />}
            onClick={openThemeEditorWithThisTheme}
          >
            <Trans>Edit</Trans>
          </MenuItem>
          <MenuItem
            icon={<FontAwesomeIcon icon={regular('clone')} fixedWidth />}
            onClick={onDuplicate}
          >
            <Trans>Duplicate</Trans>
          </MenuItem>
          <MenuDivider />
          <MenuItem
            color="red.500"
            icon={<FontAwesomeIcon icon={regular('archive')} fixedWidth />}
            onClick={onArchiveThemeModalOpen}
          >
            <Trans>Archive</Trans>
          </MenuItem>
        </MenuList>
      </Portal>
      {isArchiveThemeModalOpen && (
        <Portal>
          <ArchiveThemeModal
            theme={theme}
            isOpen={true}
            onClose={onArchiveThemeModalClose}
          />
        </Portal>
      )}
    </Menu>
  )
}

export const ThemePreviewMenu = memo(ThemePreviewMenuComponent)
