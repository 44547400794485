import {
  Badge,
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  HStack,
  Image,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'
import React from 'react'

import { useGetSitesQuery } from 'modules/api'
import { useCanUseSites } from 'modules/monetization'
import { CreateSiteModal } from 'modules/sites/components_v2/CreateSiteModal'
import { useUserContext } from 'modules/user'
import { SiteTile } from 'sections/home_v2/components/Sites/SiteTile'

import { SidebarTabs, TabMap } from '../Sidebar'
import { TopbarWrapper } from '../Topbar'

type SitesViewProps = {
  isSidebarVisible: boolean
  setIsSidebarVisible: React.Dispatch<React.SetStateAction<boolean>>
}
export const SitesView = ({
  isSidebarVisible,
  setIsSidebarVisible,
}: SitesViewProps) => {
  // TODO: Gate create site button with permissions
  // https://linear.app/gamma-app/issue/G-5503/figure-out-how-to-handle-permissions
  const sitesEnabled = useCanUseSites()
  const { currentWorkspace } = useUserContext()

  const { isOpen, onOpen, onClose } = useDisclosure({ id: 'create-site-modal' })

  const { data, loading, error } = useGetSitesQuery({
    variables: {
      workspaceId: currentWorkspace?.id as string,
    },
    skip: !currentWorkspace?.id,
  })

  const { displayName, ariaLabel, icon } = TabMap[SidebarTabs.SITES]

  const sites = data?.sites

  if (sites && sites.length === 0 && !loading && !error) {
    return (
      <HStack spacing={4} flex={1} h="100%" maxW="3xl" margin="auto">
        <Stack align="flex-start" justify="center" spacing={6} flex={1}>
          <HStack>
            <Badge colorScheme="green">Beta</Badge>
          </HStack>
          <Heading fontFamily="p22-mackinac-pro" fontWeight="700">
            <Trans>Websites that don't stress you out, in seconds</Trans>
          </Heading>
          <Flex flex={1} display={{ base: 'flex', lg: 'none' }}>
            <Image src="https://cdn.gamma.app/zc87vhr30n8uf3n/9f0b4d71d10f4d388e50febe89bb1853/optimized/Sal-Chill-Cloud.svg" />
          </Flex>
          <Text fontSize="lg">
            <Trans comment="For 'it's a little quiet in here', you can translate it to something that conveys that . If it doesn't make sense for your language, you can omit it all together.">
              It's a little quiet in here. Use Gamma to draft, craft, and
              publish your first website in minutes.
            </Trans>
          </Text>

          <HStack>
            <Button onClick={onOpen}>
              <Trans>Create your first site</Trans>
            </Button>
          </HStack>
        </Stack>
        <Flex flex={1} display={{ base: 'none', lg: 'flex' }}>
          <Image src="https://cdn.gamma.app/zc87vhr30n8uf3n/9f0b4d71d10f4d388e50febe89bb1853/optimized/Sal-Chill-Cloud.svg" />
        </Flex>
        <CreateSiteModal isOpen={isOpen} onClose={onClose} />
      </HStack>
    )
  }
  return (
    <Box w="100%" data-testid="sites-view-container">
      <TopbarWrapper
        isSidebarVisible={isSidebarVisible}
        setIsSidebarVisible={setIsSidebarVisible}
      >
        <Stack spacing={4} w="fit-content">
          <Heading
            fontSize="lg"
            fontWeight="600"
            data-testid="sites-view-header"
          >
            {icon && <FontAwesomeIcon icon={icon} aria-label={ariaLabel} />}{' '}
            <Text as="span" ml={2}>
              {displayName}
            </Text>
          </Heading>

          <Button
            w="auto"
            data-testid="create-site-button"
            isDisabled={!sitesEnabled}
            variant="solid"
            onClick={onOpen}
            leftIcon={<FontAwesomeIcon icon={regular('plus')} />}
          >
            <Trans comment="Site as in website">New site</Trans>
          </Button>
        </Stack>
      </TopbarWrapper>
      <Stack>
        <Grid
          templateColumns={{
            base: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
            md: 'repeat(2, 1fr)',
            lg: 'repeat(3, 1fr)',
            xl: 'repeat(4, 1fr)',
            '2xl': 'repeat(5, 1fr)',
          }}
          gap={6}
        >
          {!loading &&
            !error &&
            sites?.map((site) => <SiteTile key={site.id} site={site} />)}
        </Grid>
      </Stack>

      <CreateSiteModal isOpen={isOpen} onClose={onClose} />
    </Box>
  )
}
