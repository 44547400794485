import { Alert, AlertIcon, Flex, Stack, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'

import { LinkCopier } from 'gamma_components/LinkCopier'
import { ExistingWorkspace } from 'modules/api'
import { GAMMA_ARTIFACT_PROPER_NOUN_PLURAL } from 'modules/i18n/properNouns'
import { useMonetizationContext } from 'modules/monetization/context'
import { WorkspaceAddUserAlert } from 'modules/workspaces/components/WorkspaceSettingsModal/WorkspaceAddUserAlert'
import { generateInviteUrl } from 'utils/url'

export const InviteLink = ({ workspace }: { workspace: ExistingWorkspace }) => {
  const inviteUrl = generateInviteUrl(workspace)
  const { product } = useMonetizationContext()

  return (
    <>
      <Text my={2} fontSize="sm" color="gray.500">
        <Trans>
          Or you can invite them to join as a member with this secret invite
          link.
        </Trans>{' '}
        <Trans>Only admins can see this link.</Trans>
      </Text>
      <Flex mb={4}>
        <LinkCopier url={inviteUrl} />
      </Flex>
      <Alert mt={2} status="warning" fontSize="sm" borderRadius={4}>
        <AlertIcon />
        <Stack>
          <Text>
            <strong>
              <Trans>Heads up! This is a special link.</Trans>
            </strong>{' '}
            <Trans>
              Anyone with this link can join your workspace and will be able to
              view {GAMMA_ARTIFACT_PROPER_NOUN_PLURAL} that are not private.
            </Trans>
          </Text>
          {product && <WorkspaceAddUserAlert />}
        </Stack>
      </Alert>
    </>
  )
}
