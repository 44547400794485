import { useEffect } from 'react'

import { useAppDispatch } from 'modules/redux'

import { SupportedLocaleKey } from '../constants'
import { setPreviewLocale } from '../reducer'

export const useLocalePreview = (previewLocale?: SupportedLocaleKey) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(setPreviewLocale({ locale: previewLocale || null }))
  }, [previewLocale])

  useEffect(() => {
    return () => {
      dispatch(setPreviewLocale({ locale: null }))
    }
  }, [])
}
