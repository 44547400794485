import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { t, Trans } from '@lingui/macro'

import { FilterByOptionsMap, FilterByTypes } from '../../../types'

export const FILTER_BY_OPTIONS: FilterByOptionsMap = {
  all: {
    label: <Trans>All</Trans>,
    ariaLabel: t`All`,
    icon: regular('rectangle-history'),
  },
  recent: {
    label: <Trans>Recently viewed</Trans>,
    ariaLabel: t`Recently viewed`,
    icon: regular('clock'),
  },
  private: {
    label: <Trans>Private</Trans>,
    ariaLabel: t`Private`,
    icon: regular('lock'),
  },
  createdByYou: {
    label: <Trans>Created by you</Trans>,
    ariaLabel: t`Created by you`,
    icon: regular('user-circle'),
  },
  favorites: {
    label: <Trans>Favorites</Trans>,
    ariaLabel: t`Favorites`,
    icon: regular('star'),
  },
}
const FILTER_BY_OPTIONS_ORDERED_KEYS: FilterByTypes[] = [
  'all',
  'recent',
  'createdByYou',
  // This filter actually shows 'anyone with link' and private now
  // Disabling until we can make the backend only show things that are fully private
  // 'private',
  'favorites',
]

export const FILTER_BY_OPTIONS_ORDERED = FILTER_BY_OPTIONS_ORDERED_KEYS.map(
  (key) => {
    return {
      key,
      ...FILTER_BY_OPTIONS[key],
    }
  }
)

export const FILTER_BY_OPTIONS_ORDERED_KEYS__CHANNEL_VIEW: FilterByTypes[] = [
  'all',
  'recent',
  'createdByYou',
  'favorites',
]

export const FILTER_BY_OPTIONS_ORDERED__CHANNEL_VIEW =
  FILTER_BY_OPTIONS_ORDERED_KEYS__CHANNEL_VIEW.map((key) => {
    return {
      key,
      ...FILTER_BY_OPTIONS[key],
    }
  })
