import {
  Avatar,
  Button,
  HStack,
  Link,
  Td,
  Text,
  Tr,
  Wrap,
} from '@chakra-ui/react'
import { LinkTagGroup } from '@gamma-app/ui'
import { t, Trans } from '@lingui/macro'
import { formatDistanceStrict, parseISO } from 'date-fns'
import NextLink from 'next/link'
import { useEffect } from 'react'
import { useDrag } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'

import { FavoriteButton } from 'gamma_components/DocumentGrid'
import { DocSortField } from 'modules/api'
import { isDocPrivate } from 'modules/sharing/utils'
import {
  ChannelDocDragItem,
  ChannelDocDragItemType,
} from 'sections/home_v2/types'
import { generateDocUrl } from 'utils/url'

const TITLE_MIN_WIDTH = '280px'

const getTimestamp = ({ doc, dateDisplayOption }) => {
  let isoTime = doc?.updatedTime
  if (dateDisplayOption === DocSortField.LastViewed) {
    isoTime = doc?.docUser?.lastViewed
  }
  if (dateDisplayOption === DocSortField.Favorited) {
    isoTime = doc?.docUser?.favorited
  }
  if (dateDisplayOption === DocSortField.CreatedTime) {
    isoTime = doc?.createdTime
  }
  try {
    return `${formatDistanceStrict(new Date(), parseISO(isoTime))} ago`
  } catch (e) {
    return '--'
  }
}

export const DocsTableRow = ({
  doc,
  handleShareClick,
  dateDisplayOption,
  tags,
  handleFavoriteDoc,
  user,
  menu,
  canManageDoc,
}) => {
  const docUrl = generateDocUrl({ docId: doc.id })
  const timestamp = getTimestamp({ doc, dateDisplayOption })
  const [_, drag, dragPreview] = useDrag(
    {
      type: ChannelDocDragItemType,
      item: (): ChannelDocDragItem => doc,
      options: {
        dropEffect: 'copy',
        // Super hack! This forces the new doc dependency to recompute
        // the draggable state whenever the doc changes
        // See https://github.com/react-dnd/react-dnd/issues/3345
        // @ts-ignore
        doc,
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    },
    [canManageDoc, doc]
  )

  useEffect(() => {
    dragPreview(getEmptyImage())
  }, [dragPreview])

  return (
    <Tr
      ref={drag}
      key={doc.id}
      _hover={{ bg: 'blackAlpha.50' }}
      role="group"
      data-doc-list-item-id={doc.id}
    >
      <Td>
        {handleFavoriteDoc && (
          <FavoriteButton
            isFavorited={Boolean(doc?.docUser?.favorited)}
            onFavorite={handleFavoriteDoc(new Date(), doc)}
            onUnfavorite={handleFavoriteDoc(null, doc)}
          />
        )}
      </Td>

      <Td minW={TITLE_MIN_WIDTH}>
        <NextLink href={docUrl} passHref legacyBehavior>
          <Link
            _hover={{
              color: 'var(--chakra-colors-trueblue-500) !important',
            }}
          >
            {/* intentionally don't fallback to Untitled so the API sort works as expected */}
            {doc.title || ''}
          </Link>
        </NextLink>
      </Td>
      <Td zIndex={2} _groupHover={{ opacity: 1 }}>
        <Wrap _groupHover={{ opacity: 1 }}>
          <LinkTagGroup
            tags={tags}
            NextLink={NextLink}
            overflowButtonLabel={t`See all folders`}
          />
          {isDocPrivate(doc, user) ? (
            <Button
              size="xs"
              variant="ghost"
              colorScheme="blackAlpha"
              fontWeight="500"
              _hover={{
                color: 'gray.800',
                bg: 'blackAlpha.50',
              }}
              opacity={0}
              _groupHover={{ opacity: 1 }}
              transitionProperty="none"
              onClick={() => handleShareClick(doc.id)}
            >
              <Trans>Share</Trans>&hellip;
            </Button>
          ) : null}
        </Wrap>
      </Td>
      <Td color="gray.600" pr={-2}>
        <Text>{timestamp}</Text>
      </Td>
      <Td alignContent={'center'} pr={-2}>
        <HStack>
          <Avatar
            shadow="md"
            borderWidth="1px"
            size="xs"
            src={doc?.createdBy?.profileImageUrl}
          />
          <Text>{doc?.createdBy?.displayName}</Text>
        </HStack>
      </Td>
      <Td isNumeric>{Boolean(menu) && menu}</Td>
    </Tr>
  )
}
