import { Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'

import { FrequencyUnit } from 'modules/api'
import { useMonetizationContext } from 'modules/monetization/context'
import {
  getPriceDisplayMonthly,
  getProOrPlusProductPrice,
} from 'modules/monetization/utils'

export const WorkspaceAddUserAlert = () => {
  const { subscription } = useMonetizationContext()
  const productPrice = getProOrPlusProductPrice(subscription)
  const frequency = productPrice?.frequencyUnit

  return (
    <Text>
      <Text as="span" fontWeight="600">
        {frequency === FrequencyUnit.Month ? (
          <Trans>
            This will add another user to your subscription at{' '}
            <Text as="span" textDecoration="underline">
              {getPriceDisplayMonthly(productPrice)}
            </Text>
            , billed monthly.
          </Trans>
        ) : frequency === FrequencyUnit.Year ? (
          <Trans>
            This will add another user to your subscription at{' '}
            <Text as="span" textDecoration="underline">
              {getPriceDisplayMonthly(productPrice)} per month
            </Text>
            , billed annually.
          </Trans>
        ) : (
          '---'
        )}
      </Text>
      <Text>
        <Trans>
          Your subscription will be updated to reflect this change when they
          accept the invitation and prorated to the end of the current billing
          cycle.
        </Trans>
      </Text>
    </Text>
  )
}
