import { useRouter } from 'next/dist/client/router'
import { useEffect } from 'react'

import { useUserContext } from 'modules/user'

export const useRedirectLoggedOutUser = () => {
  const { push } = useRouter()
  const { userStatus } = useUserContext()

  useEffect(() => {
    // https://nextjs.org/docs/messages/no-router-instance
    // You can't use push during pre-rendering; wrapping this call inside
    // useEffect ensures that the router method is only executed client side.
    if (userStatus === 'loggedOut') {
      // redirect to signin
      push(`/signin?redirect_to=${encodeURIComponent(window.location.href)}`)
    }
  }, [userStatus, push])
}
