import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react'
import { Plural, Trans } from '@lingui/macro'
import { useCallback, useState } from 'react'

import { FrequencyUnit, ProductPrice } from 'modules/api/generated/graphql'
import { useLocalizedFunction } from 'modules/i18n/hooks/useLocalizedFunction'
import { useMonetizationContext } from 'modules/monetization/context'
import { computeSubscriptionChangeData } from 'modules/monetization/utils'
import { Deferred } from 'utils/deferred'

const getDisplayStrings = ({
  productPrice,
  newMemberCount,
  totalPrice,
  perSeatPrice,
}: {
  productPrice?: ProductPrice
  newMemberCount: number
  totalPrice?: string
  perSeatPrice?: string
}) => {
  const pricePerInterval =
    productPrice?.frequencyUnit === FrequencyUnit.Month ? (
      <Trans>{totalPrice} / month </Trans>
    ) : productPrice?.frequencyUnit === FrequencyUnit.Year ? (
      <Trans>{totalPrice} / year</Trans>
    ) : (
      '---'
    )

  const priceBlurb =
    productPrice?.frequencyUnit === FrequencyUnit.Month ? (
      <Plural
        value={newMemberCount}
        one={`This will add # additional user to your subscription at a cost of ${perSeatPrice} per user per month, billed monthly.`}
        other={`This will add # additional users to your subscription at a cost of ${perSeatPrice} per user per month, billed monthly.`}
      />
    ) : productPrice?.frequencyUnit === FrequencyUnit.Year ? (
      <Plural
        value={newMemberCount}
        one={`This will add # additional user to your subscription at a cost of ${perSeatPrice} per user per year, billed annually.`}
        other={`This will add # additional users to your subscription at a cost of ${perSeatPrice} per user per year, billed annually.`}
      />
    ) : (
      '---'
    )
  const subscriptionCostFrequencyDisplay =
    productPrice?.frequencyUnit === FrequencyUnit.Month ? (
      <Trans>Monthly cost</Trans>
    ) : productPrice?.frequencyUnit === FrequencyUnit.Year ? (
      <Trans>Annual cost</Trans>
    ) : (
      '---'
    )

  return {
    pricePerInterval,
    priceBlurb,
    subscriptionCostFrequencyDisplay,
  }
}

type ConfirmAddPaidMemberModalProps = {
  isOpen: boolean
  newMemberCount: number
  onClose: () => void
  onConfirmClick: () => void
  onCancelClick: () => void
}

export const useConfirmAddPaidMemberModal = () => {
  const { product } = useMonetizationContext()
  const shouldVerify = !!product
  const [confirmDef, setConfirmDef] = useState<Deferred<boolean>>(
    new Deferred()
  )
  const { isOpen, onOpen, onClose } = useDisclosure({
    id: 'confirm-add-paid-user',
  })

  const onCloseClick = useCallback(() => {
    confirmDef.reject()
    onClose()
  }, [onClose, confirmDef])

  const onConfirmClick = useCallback(() => {
    confirmDef.resolve(true)
    onClose()
  }, [onClose, confirmDef])

  const showConfirmPaidUserModal = useCallback(() => {
    if (!shouldVerify) return Promise.resolve()

    const def = new Deferred<boolean>()
    setConfirmDef(def)
    onOpen()

    return def.promise
  }, [shouldVerify, onOpen])

  return {
    isConfirmAddPaidMemberModalOpen: isOpen,
    onConfirmAddPaidMemberClose: onCloseClick,
    onConfirmAddPaidMemberClick: onConfirmClick,
    showConfirmPaidUserModal,
  }
}

export const ConfirmAddPaidMemberModal = ({
  isOpen,
  newMemberCount,
  onClose,
  onConfirmClick,
  onCancelClick,
}: ConfirmAddPaidMemberModalProps) => {
  const { subscription } = useMonetizationContext()
  const computeChangeFn = useCallback(
    () => computeSubscriptionChangeData(subscription, newMemberCount),
    [subscription, newMemberCount]
  )
  const {
    proratedTotalPrice,
    totalPrice,
    productPrice,
    proratedDays,
    perSeatPrice,
  } = useLocalizedFunction(computeChangeFn, [newMemberCount, subscription])

  const { pricePerInterval, priceBlurb, subscriptionCostFrequencyDisplay } =
    getDisplayStrings({
      productPrice,
      newMemberCount,
      totalPrice,
      perSeatPrice,
    })

  if (!isOpen || !subscription) {
    return null
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} trapFocus={false} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Plural
            value={newMemberCount}
            one="Add paid user?"
            other="Add paid users?"
          />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            <Text as="span" fontWeight="600">
              {priceBlurb}
            </Text>
            <Table variant="simple" colorScheme="blackAlpha">
              <Thead>
                <Tr>
                  <Th width="30%">
                    <Trans>Added users</Trans>
                  </Th>
                  <Th isNumeric>{subscriptionCostFrequencyDisplay}</Th>
                  <Th isNumeric width="40%">
                    <Trans>Estimated charge</Trans>
                    <span>&#42;</span>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>{newMemberCount}</Td>
                  <Td isNumeric>
                    <Trans comment="Price per billing interval, e.g. $9/month">
                      {pricePerInterval}
                    </Trans>
                  </Td>
                  <Td isNumeric>
                    <Stack spacing={0}>
                      <Text fontWeight="bold">{proratedTotalPrice}</Text>
                      <Text fontSize="sm" color="gray.500">
                        <Trans>Prorated to {proratedDays} days</Trans>
                        <span>&#42;</span>
                      </Text>
                    </Stack>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
            <Text color="gray.600" fontSize="sm" pt={2}>
              <Trans>
                When they accept the invitation, your subscription will be
                updated to reflect this change and you will be charged. You'll
                receive an email confirming they have been added and another
                email confirming the charge.
              </Trans>
            </Text>
            <Text color="gray.600" fontSize="sm" fontStyle="italic" pt={4}>
              <span>&#42;</span>
              <Trans>
                The exact proration amount will be calculated based on the time
                the invitation is accepted.
              </Trans>
            </Text>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button variant="ghost" colorScheme="gray" onClick={onCancelClick}>
              <Trans>Cancel</Trans>
            </Button>
            <Button variant="solid" onClick={onConfirmClick}>
              <Plural
                value={newMemberCount}
                one="Add # paid user"
                other="Add # paid users"
              />
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
