import { NetworkStatus } from '@apollo/client'
import { useCallback, useEffect } from 'react'

import {
  ExistingWorkspace,
  Channel,
  ChannelActivityDocument,
  DocActivity,
  DocSortField,
  GetDocsQueryVariables,
  SortDirection,
  useGetChannelDocActivityQuery,
  useGetDocsQuery,
  useGetPreviewChannelDocsQuery,
} from 'modules/api'
import { useFeatureFlag } from 'modules/featureFlags'
import { GraphqlUser } from 'modules/user'

import {
  DocsTabs,
  SidebarTabs,
  SidebarTabsType,
} from '../components/Sidebar/Sidebar'
import { DOC_USER_SORTBY_FIELDS } from '../constants'
import { FilterByTypes, FilterInfo } from '../types'
import { getGraphqlFilters } from '../utils'

export const DOCS_PAGE_SIZE = 50

/**
 * Hook to manage fetching doc data from the API based on the
 * activeTab, sortBy, filterBy, and sortDirection, and the current user.
 */
export const useDocsData = ({
  activeTab,
  currentChannelId,
  channelPreview,
  filterBy,
  sortBy,
  sortDirection,
  user,
  currentWorkspace,
}: {
  activeTab: SidebarTabsType | null
  currentChannelId: string | null
  channelPreview: Channel | null
  filterBy: FilterByTypes
  user?: GraphqlUser
  currentWorkspace?: ExistingWorkspace
  sortBy: DocSortField
  sortDirection: SortDirection
}) => {
  const isChannelActivityEnabled = useFeatureFlag('channelActivity')

  let filterInfo: FilterInfo = {
    filter: {},
    ready: true,
  }
  if (currentChannelId) {
    filterInfo = {
      filter: {
        channelId: currentChannelId,
        ...getGraphqlFilters(user)[filterBy].filter,
      },
      ready: true,
    }
  } else if (activeTab === SidebarTabs.ALL) {
    filterInfo = getGraphqlFilters(user)[filterBy]
  } else if (activeTab === SidebarTabs.TRASH) {
    filterInfo = {
      filter: { archived: true, includePages: true },
      ready: true,
    }
  }

  const variables: GetDocsQueryVariables = {
    workspaceId: currentWorkspace?.id,
    first: DOCS_PAGE_SIZE,
    archived: false,
    ...filterInfo.filter,
    ...{
      sortBy: {
        field:
          // TODO: https://linear.app/gamma-app/issue/G-1547/be-make-it-so-that-you-can-filter-by-favorited-items-or-last-viewed
          activeTab === SidebarTabs.TRASH
            ? DOC_USER_SORTBY_FIELDS.includes(sortBy)
              ? DocSortField.EditedTime
              : sortBy
            : filterBy === 'recent'
            ? DocSortField.LastViewed
            : filterBy === 'favorites'
            ? DocSortField.Favorited
            : sortBy,
        direction: sortDirection,
      },
    },
  }

  // If we're previewing a channel (we are not a member), use the
  // previewChannelDocs query instead of the standard docs one.
  const isChannelPreview = Boolean(channelPreview?.id)

  const channelId = currentChannelId || (channelPreview?.id as string)
  const {
    data,
    networkStatus,
    fetchMore: fetchMoreDocs,
  } = useGetDocsQuery({
    variables,
    skip: !user || !filterInfo.ready || isChannelPreview,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
  })
  const { data: channelPreviewData } = useGetPreviewChannelDocsQuery({
    variables: {
      first: DOCS_PAGE_SIZE,
      channelId: channelPreview?.id as string,
    },
    skip: !isChannelPreview,
  })

  const skipChannelDocActivity = !isChannelActivityEnabled || !channelId
  const {
    data: channelDocActivityData,
    subscribeToMore: subscribeToMoreChannelDocActivity,
  } = useGetChannelDocActivityQuery({
    variables: {
      first: DOCS_PAGE_SIZE,
      channelId,
    },
    skip: skipChannelDocActivity,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })

  useEffect(() => {
    if (
      !process.browser ||
      !subscribeToMoreChannelDocActivity ||
      skipChannelDocActivity
    )
      return

    return subscribeToMoreChannelDocActivity({
      document: ChannelActivityDocument,
      variables: { channelId },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data || !subscriptionData.data.channelDocActivity)
          return prev

        // The docs query is a paginated set, but the subscription returns
        // a simple list of docs, so we must coerce them into the paginated
        // shape here so that the apollo cache saves them properly.
        const docs = subscriptionData.data
          .channelDocActivity as unknown as DocActivity[]

        const newEdges = docs.slice(0, DOCS_PAGE_SIZE).map((cda) => {
          return {
            node: cda,
          }
        })
        return {
          channelDocActivity: {
            edges: newEdges,
            pageInfo: prev.channelDocActivity.pageInfo,
            __typename: 'DocActivityConnection',
          },
        }
      },
    })
  }, [channelId, subscribeToMoreChannelDocActivity, skipChannelDocActivity])

  const docsToUse = isChannelPreview
    ? channelPreviewData?.previewChannelDocs
    : data?.docs
  const activeDocs =
    (channelDocActivityData?.channelDocActivity?.edges &&
      channelDocActivityData?.channelDocActivity?.edges
        .map((edge) => edge.node)
        .filter(
          (doc) =>
            (doc.editors && doc.editors.length > 0) ||
            (doc.presenters && doc.presenters.length > 0)
        )) ||
    undefined

  const docs = docsToUse?.edges
    .map((edge) => edge.node)
    .filter((d) => {
      // If there's an active channel, only show the docs that are inside that active channel
      // While the API does this for us on load, live changes to a doc's channels need to be handled
      return currentChannelId
        ? d?.channels?.find((c) => c.id === currentChannelId)
        : true
    })
  const pageInfo = data?.docs.pageInfo
  const endCursor = pageInfo?.endCursor
  const fetchMore = useCallback(() => {
    return fetchMoreDocs({
      variables: {
        after: endCursor,
      },
    })
  }, [fetchMoreDocs, endCursor])

  const loading = [NetworkStatus.loading, NetworkStatus.setVariables].includes(
    networkStatus
  )

  const showPaginationControls =
    !loading && pageInfo?.hasNextPage && pageInfo?.endCursor

  console.debug('%c[useDocsData]', 'background-color:aqua', {
    sortBy,
    sortDirection,
    filterInfo,
    variables,
    networkStatus,
    docs,
  })

  return {
    docs,
    loading,
    pageInfo,
    fetchMore,
    showPaginationControls,
    activeDocs,
  }
}
