import { CheckIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuOptionGroup,
  Portal,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { t, Trans } from '@lingui/macro'

import { DocSortField, SortDirection } from 'modules/api'
import {
  DISPLAY_DATE_OPTIONS,
  DOC_USER_DISPLAY_DATE_OPTIONS,
} from 'sections/home_v2/constants'
import { FilterByTypes } from 'sections/home_v2/types'
import { isDocUserFilter } from 'sections/home_v2/utils'
import { isMobileDevice } from 'utils/deviceDetection'

type SortOptionsProps = {
  filterBy: FilterByTypes
  sortBy: DocSortField
  sortDirection: SortDirection
  setSortBy: (sortBy: DocSortField) => void
  setSortDirection: (sortDirection: SortDirection) => void
  size?: 'sm' | 'xs'
}
export const SortOptions = ({
  filterBy,
  sortBy,
  sortDirection,
  setSortBy,
  setSortDirection,
  size = 'sm',
}: SortOptionsProps) => {
  const _isDocUserFilter = isDocUserFilter(filterBy)

  const options = _isDocUserFilter //
    ? DOC_USER_DISPLAY_DATE_OPTIONS
    : DISPLAY_DATE_OPTIONS.concat({ key: DocSortField.Title, label: 'Title' })
  // We can only change the sort order of the date field in some circumstances.

  return (
    <ButtonGroup
      variant="ghost"
      size={size}
      // Need the z-index so that the "Favorite" buttons don't layer on top
      // of the open menu
      zIndex="2"
    >
      {!isMobileDevice() && !_isDocUserFilter && (
        <Menu closeOnSelect={true}>
          <MenuButton
            as={Button}
            variant="ghost"
            size={size}
            rightIcon={
              <FontAwesomeIcon
                icon={regular('chevron-down')}
                aria-label="Open menu"
              />
            }
            fontWeight="600"
            color="gray.700"
          >
            {/* See getTimestamp */}
            {options?.find((o) => o.key === sortBy)?.label || (
              <Trans>Last edited</Trans>
            )}
          </MenuButton>
          <Portal>
            <MenuList minWidth="240px" zIndex="overlay">
              <MenuOptionGroup
                defaultValue="alpha"
                title={t`Sort by`}
                type="radio"
              >
                {options.map((option) => {
                  const isSelected = sortBy === option.key
                  return (
                    <MenuItem
                      key={option.key}
                      icon={isSelected ? <CheckIcon /> : <Box w={3} />}
                      value={option.key}
                      onClick={() => setSortBy(option.key)}
                    >
                      {option.label}
                    </MenuItem>
                  )
                })}
              </MenuOptionGroup>
            </MenuList>
          </Portal>
        </Menu>
      )}
      {(_isDocUserFilter || options.find((o) => o.key === sortBy)) && (
        <GammaTooltip label={<Trans>Reverse sort order</Trans>}>
          <IconButton
            color="gray.700"
            aria-label="sort"
            onClick={() =>
              setSortDirection(
                sortDirection === SortDirection.Asc
                  ? SortDirection.Desc
                  : SortDirection.Asc
              )
            }
            icon={<FontAwesomeIcon icon={regular('arrow-up-arrow-down')} />}
          />
        </GammaTooltip>
      )}
    </ButtonGroup>
  )
}
