import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Button,
  ButtonGroup,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Text,
} from '@chakra-ui/react'
import { Plural, Trans } from '@lingui/macro'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { useGetThemeQuery } from 'modules/api'
import {
  GAMMA_ARTIFACT_PROPER_NOUN,
  GAMMA_ARTIFACT_PROPER_NOUN_PLURAL,
} from 'modules/i18n/properNouns'
import { useAppSelector } from 'modules/redux'
import { Theme, THEMES_DASHBOARD_LINK } from 'modules/theming'
import { useArchiveTheme } from 'modules/theming/hooks'
import { selectDoc } from 'modules/tiptap_editor/reducer'

export const ArchiveThemeModal = ({
  theme,
  isOpen,
  onClose,
}: {
  theme: Theme
  isOpen: boolean
  onClose: () => void
}) => {
  const { asPath } = useRouter()
  const hideDashboardTip = asPath === '/#themes'
  const [docCount, setDocCount] = useState<number>()
  const [siteCount, setSiteCount] = useState<number>()
  const doc = useAppSelector(selectDoc)
  const showLinkToDashboard = Boolean(doc)

  const archiveTheme = useArchiveTheme({ theme, showLinkToDashboard })

  // Refetch here because docCount is expensive to calculate so we only get it
  // when you archive it
  const { data: fetchedTheme, loading } = useGetThemeQuery({
    variables: {
      id: theme?.id,
    },
    skip: !theme || theme.id === 'new',
  })

  useEffect(() => {
    if (loading || !fetchedTheme) return
    if (fetchedTheme.theme?.docCount) {
      setDocCount(fetchedTheme.theme.docCount)
    }
    if (fetchedTheme.theme?.siteCount) {
      setSiteCount(fetchedTheme.theme.siteCount)
    }
  }, [loading, fetchedTheme, setDocCount, setSiteCount])

  const totalCount = useMemo(() => {
    let total = 0
    if (docCount && docCount > 0) {
      total += docCount
    }
    if (siteCount && siteCount > 0) {
      total += siteCount
    }
    return total
  }, [docCount, siteCount])

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Trans>Archive {theme.name}?</Trans>
        </ModalHeader>
        <ModalBody>
          {totalCount > 0 ? (
            <Text mb={2}>
              <Plural
                value={totalCount}
                one={`It's currently in use by <strong>#</strong> ${GAMMA_ARTIFACT_PROPER_NOUN}.`}
                other={`It's currently in use by <strong>#</strong> ${GAMMA_ARTIFACT_PROPER_NOUN_PLURAL}.`}
              />{' '}
              <Trans>
                Existing {GAMMA_ARTIFACT_PROPER_NOUN_PLURAL} will not be
                changed, but new {GAMMA_ARTIFACT_PROPER_NOUN_PLURAL} will no
                longer be able to select it.
              </Trans>{' '}
              {!hideDashboardTip && (
                <Trans>
                  You can always access your archived themes through{' '}
                  <Link
                    color="trueblue.500"
                    colorScheme="trueblue"
                    href={THEMES_DASHBOARD_LINK}
                    textDecoration="underline"
                    isExternal
                  >
                    your dashboard <ExternalLinkIcon mx="2px" />
                  </Link>
                  .
                </Trans>
              )}
            </Text>
          ) : loading ? (
            <Skeleton h="1em" mb={2} />
          ) : (
            <Text mb={2}>
              <Trans>
                It's not used by any {GAMMA_ARTIFACT_PROPER_NOUN_PLURAL} right
                now.
              </Trans>
            </Text>
          )}
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button variant="ghost" onClick={onClose}>
              <Trans>Cancel</Trans>
            </Button>
            <Button
              variant="ghost"
              colorScheme="red"
              onClick={() => {
                archiveTheme()
                onClose()
              }}
            >
              <Trans>Archive theme</Trans>
            </Button>
          </ButtonGroup>
        </ModalFooter>
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  )
}
