import { Grid, GridProps, Skeleton } from '@chakra-ui/react'

export const ThemeListSkeleton = ({ size }: { size: number }) => {
  return (
    <Grid
      gap={4}
      flex={1}
      my={2}
      templateColumns={
        // https://stackoverflow.com/a/61240964 - this is a hack to make the columns be the exact same width
        { base: 'repeat(2, minmax(0, 1fr))' }
      }
    >
      {new Array(size).fill(true).map((_, i) => {
        return <Skeleton key={i} h="9em" borderRadius="base" />
      })}
    </Grid>
  )
}
