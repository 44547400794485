import { Spacer, HStack, StackProps, Text } from '@chakra-ui/react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface SidebarItemLabelProps {
  icon?: IconProp
  title: React.ReactNode | string
  faClassName?: string
  w?: StackProps['w']
  badge?: JSX.Element
}

export const SidebarItemLabel = ({
  icon,
  title,
  faClassName,
  w,
  badge,
}: SidebarItemLabelProps) => {
  return (
    <HStack justify="flex-start" w="100%" pr={badge ? 3 : undefined}>
      {icon && (
        <FontAwesomeIcon
          icon={icon}
          fixedWidth
          className={faClassName ? faClassName : undefined}
          size="1x"
        />
      )}

      <Text textOverflow="ellipsis" overflowX="hidden" ml={2} w={w}>
        {title}
      </Text>
      {badge && (
        <>
          <Spacer />
          {badge}
        </>
      )}
    </HStack>
  )
}
