import { Alert, AlertIcon, Code, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'

export const EmptyFamilyWarning = () => {
  return (
    <Alert status="warning">
      <AlertIcon />
      <Text>
        <strong>
          <Trans>This font family is empty</Trans>{' '}
          <span role="img" aria-label="Crying emoji">
            😢
          </span>
          .
        </strong>{' '}
        <Trans>
          It will remain hidden from the list of available fonts in the custom
          theme creator until you upload one{' '}
          <Code colorScheme="purple">.ttf</Code> or{' '}
          <Code colorScheme="pink">.otf</Code> file
        </Trans>
      </Text>
    </Alert>
  )
}
