import {
  Box,
  Checkbox,
  HStack,
  IconButton,
  Select,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip, SectionTitle } from '@gamma-app/ui'
import { t, Trans } from '@lingui/macro'
import React, { useCallback, useMemo, useState } from 'react'

import { FontFileType } from 'modules/api'

import { getWeightsMap } from '../constants'
import { FontUploadDispatch, UploadFontFile } from '../fontUploadReducer/types'
import {
  findDuplicateFontFiles,
  getSortedFontWeights,
  groupFontsByWeight,
} from '../utils/utils'
import { EmptyFamilyWarning } from './EmptyFamilyWarning'
import { FontFileExtensionBadge } from './FontFileExtensionBadge'

export const FontWeightTable = ({
  fontFiles,
  fontWeight,
  dispatch,
}: {
  fontFiles: UploadFontFile[]
  fontWeight: string
  dispatch: FontUploadDispatch
}) => {
  const [isHoveringOnADuplicate, setIsHoveringOnADuplicate] = useState(false)
  if (!fontFiles) return null

  const fontWeightHeader = (
    <>
      {fontWeight} - {getWeightsMap()[fontWeight]}
    </>
  )
  const duplicates = findDuplicateFontFiles(fontFiles)
  const hasDuplicates = Boolean(Object.keys(duplicates).length)

  return (
    <TableContainer mt={8}>
      <Table colorScheme="blackAlpha" mb={8} borderRadius="md">
        <Thead>
          <GammaTooltip
            placement="top"
            isDisabled={!hasDuplicates}
            label={
              <Trans>
                Please remove or reassign the duplicate font weights and styles
                in this section
              </Trans>
            }
            shouldWrapChildren={true}
          >
            <SectionTitle
              mb="2"
              cursor={hasDuplicates ? 'help' : undefined}
              color={hasDuplicates ? 'yellow.600' : undefined}
            >
              {hasDuplicates && <FontAwesomeIcon icon={solid('warning')} />}{' '}
              {fontWeightHeader}
            </SectionTitle>
          </GammaTooltip>
        </Thead>

        <Tbody>
          {fontFiles.map((fontFile) => (
            <FontWeightTableRow
              key={fontFile.uploadId}
              uploadFontFile={fontFile}
              dispatch={dispatch}
              duplicates={duplicates}
              isHoveringOnADuplicate={isHoveringOnADuplicate}
              setIsHoveringOnADuplicate={setIsHoveringOnADuplicate}
            />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

type FontWeightTableRow = {
  uploadFontFile: UploadFontFile
  duplicates: { [id: string]: boolean }
  isHoveringOnADuplicate: boolean
  setIsHoveringOnADuplicate: React.Dispatch<React.SetStateAction<boolean>>
  dispatch: FontUploadDispatch
}
export const FontWeightTableRow = ({
  uploadFontFile,
  duplicates,
  isHoveringOnADuplicate,
  setIsHoveringOnADuplicate,
  dispatch,
}: FontWeightTableRow) => {
  const toast = useToast()
  const { fontFile, errorMessage, uploadId } = uploadFontFile
  const isLoading = fontFile.sourceUrl === undefined && !errorMessage
  const isDuplicate = duplicates[uploadId]
  const sortedFontWeights = getSortedFontWeights(Object.keys(getWeightsMap()))
  const rowBackgroundColor =
    isHoveringOnADuplicate && isDuplicate
      ? 'yellow.100'
      : isDuplicate
      ? 'yellow.50'
      : 'blackAlpha.50'

  const handleFileDelete = useCallback(() => {
    dispatch({
      type: 'DELETE_UPLOAD_FONT_FILE',
      data: {
        uploadId,
      },
    })
    toast({
      title: <Trans>Deleted {fontFile.name}</Trans>,
      status: 'info',
      duration: 1000,
      isClosable: true,
      position: 'top',
    })
  }, [dispatch, fontFile.name, toast, uploadId])

  const duplicateLabel = isDuplicate ? (
    fontFile.isItalic ? (
      <Trans>
        These fonts have the same assigned weight ({fontFile.weight}) and style
        (italic). Double check that that's correct and remove or reassign any
        duplicates.
      </Trans>
    ) : (
      <Trans>
        These fonts have the same assigned weight ({fontFile.weight}) and style
        (non-italic). Double check that that's correct and remove or reassign
        any duplicates.
      </Trans>
    )
  ) : (
    ''
  )
  return (
    <Tr minH={12} width="100%" background={rowBackgroundColor}>
      <Td py={1} fontSize="sm" textOverflow="ellipsis" width={80} pl={0}>
        <HStack ml={4} spacing={3}>
          <FontFileExtensionBadge
            fileExtension={fontFile.fileType || FontFileType.Ttf}
          />
          <GammaTooltip placement="right" label={duplicateLabel}>
            <Stack
              width="100%"
              pl={1}
              spacing={0}
              cursor={isDuplicate ? 'help' : 'auto'}
              onMouseOver={() => {
                setIsHoveringOnADuplicate(isDuplicate)
              }}
              onMouseOut={() => {
                setIsHoveringOnADuplicate(false)
              }}
            >
              <GammaTooltip
                label={
                  <Trans>
                    There was an error uploading this file. Please delete the
                    file to continue
                  </Trans>
                }
                placement="top"
                isDisabled={!errorMessage}
              >
                <Text color={errorMessage ? 'red.600' : undefined}>
                  {fontFile.name}{' '}
                  {isLoading ? (
                    <Spinner ml={1} size="xs" color="gray" />
                  ) : (
                    errorMessage && (
                      <Box as="span" color="red.600">
                        <FontAwesomeIcon icon={solid('warning')} />
                      </Box>
                    )
                  )}
                </Text>
              </GammaTooltip>
              {isDuplicate && (
                <Text fontSize="xs" color="yellow.700">
                  <Trans>Duplicate font weight/style</Trans>
                </Text>
              )}
            </Stack>
          </GammaTooltip>
        </HStack>
      </Td>
      <Td py={1} minW={12}>
        <GammaTooltip
          aria-label={t`Is this font italic?`}
          label={<Trans>Is this font italic?</Trans>}
          placement="top"
        >
          <Box>
            <Checkbox
              size="sm"
              isChecked={fontFile.isItalic}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                dispatch({
                  type: 'EDIT_UPLOAD_FONT_FILE_STYLE',
                  data: {
                    uploadId,
                    isItalic: e.target.checked,
                  },
                })
              }}
            >
              <Text fontSize="sm">
                <Trans>Italic</Trans>
              </Text>
            </Checkbox>
          </Box>
        </GammaTooltip>
      </Td>
      <Td minW="16" py="1" pr="2">
        <Select
          bg="revert"
          value={fontFile.weight}
          size="sm"
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            dispatch({
              type: 'EDIT_UPLOAD_FONT_FILE_WEIGHT',
              data: {
                uploadId,
                weight: parseInt(e.target.value, 10),
              },
            })
          }}
        >
          <optgroup label={t`Select font weight`}>
            {sortedFontWeights.map((weight) => (
              <option value={weight} key={weight}>
                {weight} - {getWeightsMap()[weight]}
              </option>
            ))}
          </optgroup>
        </Select>
      </Td>
      <Td py="1" px="0">
        <GammaTooltip placement="top" label={<Trans>Delete font file</Trans>}>
          <IconButton
            aria-label={t`Delete font file`}
            colorScheme="red"
            icon={<FontAwesomeIcon icon={regular('trash')} />}
            variant="ghost"
            onClick={handleFileDelete}
          />
        </GammaTooltip>
      </Td>
    </Tr>
  )
}

export const FontCategorizationByWeights = ({
  fontFiles,
  dispatch,
}: {
  fontFiles: UploadFontFile[]
  dispatch: FontUploadDispatch
}) => {
  const sortedFontFilesGroupedByWeight = useMemo(() => {
    const fontFilesGroupedByWeight = groupFontsByWeight(fontFiles)
    // return an object with the weights as keys, with the values as sorted UploadFontFiles
    return Object.fromEntries(
      Object.entries(fontFilesGroupedByWeight).map(([weight, fontGroup]) => {
        const sortedFontGroup = [...fontGroup]
        sortedFontGroup.sort((a, b) => {
          // sort ttf files before otf
          if (a.fontFile.fileType !== b.fontFile.fileType) {
            return a.fontFile.fileType === FontFileType.Ttf ? -1 : 1
          }
          // then regular before italic
          else if (a.fontFile.isItalic !== b.fontFile.isItalic) {
            return a.fontFile.isItalic === false ? -1 : 1
          } else {
            return 0
          }
        })
        return [weight, sortedFontGroup]
      })
    )
  }, [fontFiles])

  if (!fontFiles) return null

  const sortedFontWeights = getSortedFontWeights(Object.keys(getWeightsMap()))

  return (
    <>
      {fontFiles.length === 0 && <EmptyFamilyWarning />}
      {sortedFontWeights.map((weight) => {
        const currentFontFiles = sortedFontFilesGroupedByWeight[weight]
        if (!currentFontFiles) {
          return null
        }
        return (
          <FontWeightTable
            key={weight}
            fontFiles={currentFontFiles}
            dispatch={dispatch}
            fontWeight={weight}
          />
        )
      })}
    </>
  )
}
