import { Container, Flex } from '@chakra-ui/react'
import capitalize from 'lodash/capitalize'
import Head from 'next/head'
import Router, { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { DefaultHelpWidget } from 'modules/help/components/DefaultHelpWidget'
import { GAMMA_PROPER_NOUN } from 'modules/i18n/properNouns'
import { GlobalSearch } from 'modules/search'
import { SegmentEvents, useAnalyticsEffect } from 'modules/segment'
import { useUserContext } from 'modules/user'
import { SitesView } from 'sections/home_v2/components/Sites/SitesView'
import { isMobileDevice } from 'utils/deviceDetection'
import { getUrlHash } from 'utils/url'

import { DocsView } from './components/DocsView'
import { FontsView } from './components/Fonts'
import { GetStartedModal } from './components/GetStartedModal'
import { InspirationView } from './components/Inspiration'
import {
  DocsTabs,
  Sidebar,
  SidebarTabs,
  SidebarTabsType,
  TabMap,
} from './components/Sidebar/Sidebar'
import { TemplatesView } from './components/Templates'
import { ThemesView } from './components/ThemesAndFonts'
import { UserInfoModalWrapper } from './components/UserInfoModalWrapper'
import { INITIAL_TAB } from './constants'
import { useChannelsData } from './hooks/useChannelsData'
import { useRedirectIfChannelNotInWorkspace } from './hooks/useRedirectIfChannelNotInWorkspace'
import { WaitlistBar } from './Waitlist'

const getTabFromHash = (hashVal?: string): SidebarTabsType | undefined => {
  return Object.values(SidebarTabs).find((val) => val === hashVal)
}

export const Home = () => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(false)
  const { user, currentWorkspace } = useUserContext()
  const { isReady: isRouterReady } = useRouter()

  const userHasNoWorkspaces = Boolean(
    user && user.workspaceMemberships!.length === 0
  )
  const tabHash = getTabFromHash(getUrlHash())

  const {
    channelPreview,
    channels,
    activeChannel,
    currentChannelId,
    isChannelsLoading,
  } = useChannelsData({
    workspaceId: currentWorkspace?.id,
    user,
  })

  useRedirectIfChannelNotInWorkspace({
    currentChannel: channelPreview ? channelPreview : activeChannel,
    currentWorkspaceId: currentWorkspace?.id,
  })

  const activeTab = currentChannelId
    ? SidebarTabs.CHANNEL
    : tabHash
    ? tabHash
    : INITIAL_TAB

  useEffect(() => {
    if (typeof window === 'undefined' || !isRouterReady) return
    // Observe the hash changing (via Router.push or popstate)
    // to correct invalid values and to reset the loaded flag
    //
    // Use the global Router per this NextJS guidance:
    // https://github.com/vercel/next.js/discussions/18522#discussioncomment-836362
    if (userHasNoWorkspaces) {
      Router.replace(`/#${SidebarTabs.SHARED_WITH_ME}`)
    } else {
      // Allow the UI to update before collapsing the sidebar
      setTimeout(() => setIsSidebarVisible(false), 150)
    }
  }, [tabHash, currentChannelId, userHasNoWorkspaces, isRouterReady])

  useAnalyticsEffect(
    (analytics) => {
      analytics.track(SegmentEvents.HOME_TAB_VIEWED, {
        tab: activeTab,
      })
    },
    [activeTab]
  )
  return (
    <DndProvider backend={HTML5Backend}>
      <Flex data-testid="home" width="100%" height="100%" position="relative">
        <Head>
          <title>
            {tabHash
              ? // We can't use the <Trans> here
                `${TabMap[tabHash].ariaLabel} - ${GAMMA_PROPER_NOUN}`
              : `${GAMMA_PROPER_NOUN}`}
          </title>
        </Head>
        <GlobalSearch />
        <GetStartedModal />
        {userHasNoWorkspaces ? null : (
          <Sidebar
            channelPreview={channelPreview}
            isSidebarVisible={isSidebarVisible}
            setIsSidebarVisible={setIsSidebarVisible}
            user={user}
            isReady={Boolean(user)}
            activeTab={activeTab}
            currentChannelId={currentChannelId}
            channels={channels}
            isChannelsLoading={isChannelsLoading}
          />
        )}
        <Flex
          data-testid="doc-root-main"
          flex="1"
          px={userHasNoWorkspaces ? 0 : isMobileDevice() ? 2 : 6}
          py={userHasNoWorkspaces ? 0 : isMobileDevice() ? 2 : 6}
          // Account for the sidebar and padding
          w={`calc(100vw - 300px - var(--chakra-sizes-4))`}
          bg="gray.100"
          borderTopLeftRadius="15px"
          borderBottomLeftRadius="15px"
          direction="column"
          marginLeft={{
            base: '0',
          }}
          minH="var(--100vh)"
          pb="10em" // Buffer area for tooltips inside portals to expand into
          transition="margin .2s ease"
        >
          {userHasNoWorkspaces && <WaitlistBar />}
          <Container maxW="80em" p={isMobileDevice() ? 2 : undefined} h="100%">
            {activeTab === DocsTabs.TEMPLATES ? (
              <TemplatesView
                isSidebarVisible={isSidebarVisible}
                setIsSidebarVisible={setIsSidebarVisible}
              />
            ) : activeTab === DocsTabs.INSPIRATION ? (
              <InspirationView
                isSidebarVisible={isSidebarVisible}
                setIsSidebarVisible={setIsSidebarVisible}
              />
            ) : activeTab === DocsTabs.THEMES ? (
              <ThemesView
                isSidebarVisible={isSidebarVisible}
                setIsSidebarVisible={setIsSidebarVisible}
              />
            ) : activeTab === DocsTabs.FONTS ? (
              <FontsView
                isSidebarVisible={isSidebarVisible}
                setIsSidebarVisible={setIsSidebarVisible}
              />
            ) : activeTab === DocsTabs.SITES ? (
              <SitesView
                isSidebarVisible={isSidebarVisible}
                setIsSidebarVisible={setIsSidebarVisible}
              />
            ) : (
              <DocsView
                activeTab={activeTab}
                userHasNoWorkspaces={userHasNoWorkspaces}
                isSidebarVisible={isSidebarVisible}
                setIsSidebarVisible={setIsSidebarVisible}
                currentChannelId={currentChannelId}
                activeChannel={activeChannel}
                channelPreview={channelPreview}
              />
            )}
          </Container>
        </Flex>
      </Flex>
      <DefaultHelpWidget showShortcuts={false} />
      <UserInfoModalWrapper />
    </DndProvider>
  )
}
