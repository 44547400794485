import { ExternalLinkIcon } from '@chakra-ui/icons'
import { Box, Button, ButtonGroup, Link, Stack } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { Trans } from '@lingui/macro'
import { format, parseISO } from 'date-fns'
import { useCallback, useState } from 'react'

import { useChurnkeyCancelFlow } from 'modules/churnkey'
import { CurrentProductBadge } from 'modules/monetization/components/PaidProductBadge'
import { getCustomerPortalURL } from 'modules/monetization/components/UpsellModal/utils'
import { useMonetizationContext } from 'modules/monetization/context'
import { analytics } from 'modules/segment/AnalyticsWrapper'
import { SegmentEvents } from 'modules/segment/segmentEvents'
import { useUserContext } from 'modules/user'

type WorkspaceSubscriptionSummaryProps = {
  memberCount?: number
}

export const WorkspaceSubscriptionSummary = ({
  memberCount,
}: WorkspaceSubscriptionSummaryProps) => {
  const { user, currentWorkspace } = useUserContext()
  const { subscription, product, refetchSubscription } =
    useMonetizationContext()
  const { launchCancelFlow, cancelFlowEnabled } = useChurnkeyCancelFlow(
    subscription,
    currentWorkspace
  )
  const [isCanceling, setIsCanceling] = useState(false)

  const handleCancel = useCallback(async () => {
    if (!launchCancelFlow) return

    analytics.track(SegmentEvents.CANCEL_SUBSCRIPTION_CLICK, {
      source: 'cancel-flow',
    })

    setIsCanceling(true)
    try {
      const result = await launchCancelFlow()
      if (result?.sessionResults?.canceled === true) {
        await refetchSubscription?.()
      }
    } catch (e) {
      console.warn('Failed to cancel subscription', e)
    }
    setIsCanceling(false)
  }, [launchCancelFlow, refetchSubscription])

  const subscribedProduct = subscription?.products?.[0]
  if (!subscribedProduct || !product) {
    return null
  }

  const cancellationRequested = Boolean(subscription?.cancellationRequestedTime)
  const isExpired =
    Boolean(subscription?.expirationTime) &&
    new Date() > new Date(subscription.expirationTime)

  const canManageSubscription = subscription?.createdByUser?.id === user?.id
  const manageSubscriptionUrl = canManageSubscription
    ? getCustomerPortalURL()
    : null

  const addedSeats =
    subscribedProduct?.units && memberCount
      ? memberCount - subscribedProduct.units
      : undefined
  const creatorNameAndEmail = `${subscription?.createdByUser?.displayName} (${subscription?.createdByUser?.email})`

  return (
    <Stack direction="row" spacing={2} alignItems="baseline">
      <CurrentProductBadge workspace={currentWorkspace} />
      {addedSeats && addedSeats > 0 && canManageSubscription && (
        <GammaTooltip
          label={
            <Trans>
              Your {addedSeats} added seats haven't been charged yet. You can
              still remove users from your workspace to avoid being charged.
            </Trans>
          }
          placement="top"
        >
          <Box color="yellow.500">
            <FontAwesomeIcon icon={regular('circle-dollar')} />
          </Box>
        </GammaTooltip>
      )}
      <ButtonGroup px={2} spacing={4}>
        <GammaTooltip
          placement="top"
          shouldWrapChildren={true}
          label={
            !canManageSubscription ? (
              <Trans>
                Only {creatorNameAndEmail} can manage this subscription. Please
                contact them or reach out to Gamma support if you need help with
                this.
              </Trans>
            ) : isExpired ? (
              <Trans>
                Your subscription expired on{' '}
                {format(parseISO(subscription.expirationTime), 'PPP')}. Click
                here to re-activate it.
              </Trans>
            ) : cancellationRequested ? (
              <Trans>
                Your subscription will expire on{' '}
                {format(parseISO(subscription.expirationTime), 'PPP')}. Click
                here to re-activate it.
              </Trans>
            ) : (
              <Trans>
                Click here to update your billing information, view payment
                history, or make other changes to your subscription.
              </Trans>
            )
          }
        >
          <Button
            target="_blank"
            href={canManageSubscription ? manageSubscriptionUrl : null}
            as={Link}
            colorScheme={canManageSubscription ? 'trueblue' : 'gray'}
            variant="link"
            size="sm"
            rightIcon={<ExternalLinkIcon ml={-1} />}
            isDisabled={!canManageSubscription}
          >
            <Trans>
              {cancellationRequested || isExpired
                ? 'Re-activate subscription'
                : 'Manage subscription'}
            </Trans>
          </Button>
        </GammaTooltip>
        {cancelFlowEnabled && !cancellationRequested && canManageSubscription && (
          <GammaTooltip
            placement="top"
            shouldWrapChildren={true}
            label={<Trans>Click here to cancel your subscription.</Trans>}
          >
            <Button
              colorScheme="gray"
              color="gray.600"
              variant="link"
              size="sm"
              onClick={handleCancel}
              isLoading={isCanceling}
              isDisabled={isCanceling}
            >
              <Trans>Cancel subscription</Trans>
            </Button>
          </GammaTooltip>
        )}
      </ButtonGroup>
      {isExpired ? (
        <Box fontSize="sm" color="gray.400">
          <Trans>
            Expired on {format(parseISO(subscription.expirationTime), 'PPP')}.
          </Trans>
        </Box>
      ) : cancellationRequested ? (
        <Box fontSize="sm" color="gray.400">
          <Trans>
            Expires on {format(parseISO(subscription.expirationTime), 'PPP')}.
          </Trans>
        </Box>
      ) : (
        <Box fontSize="sm" color="gray.400">
          <Trans>
            Renews on {format(parseISO(subscription.nextBillingTime), 'PPP')}.
          </Trans>
        </Box>
      )}
    </Stack>
  )
}
