import { HStack, Spacer, Stack, Text, Link } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'

import { useFeatureFlag } from 'modules/featureFlags'
import {
  GAMMA_PROPER_NOUN,
  GAMMA_PRO_PROPER_NOUN,
} from 'modules/i18n/properNouns'
import { SegmentEvents } from 'modules/segment'

import { useMonetizationContext } from '../context/MonetizationContext'
import { PaidProductBadge } from './PaidProductBadge'

export const SidebarUpsell = ({
  onOpenCallback,
  onCloseCallback,
}: {
  onOpenCallback?: () => void
  onCloseCallback?: () => void
}) => {
  const upsellUXEnabled_Pro = useFeatureFlag('upsellUXEnabled_Pro')
  const { openUpsellModal } = useMonetizationContext()

  if (!upsellUXEnabled_Pro) {
    return null
  }

  return (
    <Stack
      borderTopWidth="1px"
      bottom={0}
      bg="white"
      pt={6}
      pb={2}
      borderTopColor="blackAlpha.200"
      px={2}
    >
      <HStack>
        <PaidProductBadge variant="solid" />
        <Text fontSize="sm">
          <Trans>Upgrade to {GAMMA_PRO_PROPER_NOUN}</Trans>
        </Text>
      </HStack>
      <Text fontSize="xs" color="gray.500">
        <Trans>
          Unlock unlimited AI and remove {GAMMA_PROPER_NOUN} branding
        </Trans>
      </Text>
      <Text fontSize="sm" mt={2} fontWeight="bold">
        <Link
          color="trueblue.600"
          href="#"
          onClick={() => {
            openUpsellModal({
              onOpenCallback,
              onCloseCallback,
              segmentEvent: SegmentEvents.GAMMA_PRO_UPSELL_CALLOUT_SIDEBAR,
            })
          }}
        >
          <HStack flex={1}>
            <Text>
              <Trans>View Plans</Trans>
            </Text>
            <Spacer />
          </HStack>
        </Link>
      </Text>
    </Stack>
  )
}
