import { InternalRefetchQueriesInclude } from '@apollo/client'
import { Link, Text, useToast } from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import { useCallback } from 'react'

import {
  Doc,
  DocUserUpdateFragmentDoc,
  useArchiveDocMutation,
  useDeleteDocMutation,
  useFavoriteDocMutation,
  useUnArchiveDocMutation,
} from 'modules/api'
import { GAMMA_ARTIFACT_PROPER_NOUN } from 'modules/i18n/properNouns'
import { useUserContext } from 'modules/user'

export const useDocMutations = ({
  refetchQueries,
}: {
  refetchQueries?: InternalRefetchQueriesInclude | undefined
}) => {
  const { user } = useUserContext()
  const toast = useToast()

  const [_archiveDoc] = useArchiveDocMutation({
    refetchQueries,
  })
  const [_deleteDoc, { loading: deletingDoc }] = useDeleteDocMutation({
    refetchQueries,
  })
  const [_unarchiveDoc] = useUnArchiveDocMutation({
    refetchQueries,
  })
  const [_favoriteDoc] = useFavoriteDocMutation({
    refetchQueries,
  })

  const favoriteDoc = useCallback(
    (favorited: Date | null, doc: Doc) => {
      return () => {
        if (!user || !doc) return
        const variables = {
          input: {
            docId: doc.id,
            userId: user?.id as string,
            favorited,
          },
        }
        _favoriteDoc({
          variables,
          update: (cache, { data }) => {
            cache.writeFragment({
              id: `Doc:${doc.id}`,
              fragment: DocUserUpdateFragmentDoc,
              data: {
                docUser: data?.updateDocUser,
              },
            })
          },
          optimisticResponse: {
            updateDocUser: {
              ...doc.docUser!,
              favorited: variables.input.favorited,
              __typename: 'DocUser',
            },
          },
        })
      }
    },
    [_favoriteDoc, user]
  )

  const restoreDoc = useCallback(
    (id: string) => {
      const variables = { id }
      _unarchiveDoc({
        variables,
        optimisticResponse: {
          unarchiveDoc: {
            ...variables,
            archived: false,
            __typename: 'Doc',
          },
        },
      }).then(() => {
        toast({
          title: <Trans>Snapshot restored. You can now edit it.</Trans>,
          status: 'success',
          duration: 10000,
          position: 'top',
        })
      })
    },
    [_unarchiveDoc, toast]
  )

  const trashDoc = useCallback(
    (id: string) => {
      const variables = { id }
      _archiveDoc({
        variables,
        optimisticResponse: {
          archiveDoc: {
            ...variables,
            archived: true,
            __typename: 'Doc',
          },
        },
      }).then(() => {
        toast({
          title: (
            <Text>
              <Trans>
                This {GAMMA_ARTIFACT_PROPER_NOUN} has been moved to the trash.
              </Trans>{' '}
              <Link textDecoration="underline" onClick={() => restoreDoc(id)}>
                <Trans>Undo</Trans>
              </Link>
            </Text>
          ),
          status: 'success',
          duration: 3000,
          position: 'top',
          isClosable: true,
        })
      })
    },
    [_archiveDoc, restoreDoc, toast]
  )

  const permanentlyDeleteDoc = useCallback(
    (id: string) => {
      const variables = { id }
      return _deleteDoc({
        variables,
        update: (cache) => {
          cache.evict({ id: cache.identify({ __typename: 'Doc', id: id }) })
          cache.gc()
        },
        optimisticResponse: {
          deleteDoc: id,
        },
      })
        .then(() => {
          toast({
            title: (
              <Trans>
                This {GAMMA_ARTIFACT_PROPER_NOUN} has been permanently deleted.
              </Trans>
            ),
            position: 'top',
            status: 'success',
            duration: 3000,
          })
        })
        .catch(() => {
          toast({
            title: <Trans>Error</Trans>,
            description: <Trans>Something went wrong. Please try again.</Trans>,
            status: 'error',
            duration: 3000,
            position: 'top',
            isClosable: true,
          })
        })
    },
    [_deleteDoc, toast]
  )

  return {
    favoriteDoc,
    restoreDoc,
    trashDoc,
    permanentlyDeleteDoc,
    isPermanentlyDeletingDoc: deletingDoc,
  }
}
