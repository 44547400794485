import { Box, Center, Flex, Heading, Link, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import NextImage from 'next/legacy/image'

export const ChannelsEmptyState = ({
  channelName,
  onCreateClick,
}: {
  channelName: string
  onCreateClick: () => void
}) => {
  return (
    <Center minH="sm">
      <Flex
        direction="column"
        justify={'center'}
        align="center"
        w={['100%', '80%']}
      >
        <Box w="80%" maxW="250px" mb={6} alignContent="center">
          <NextImage
            src="/images/Sal-Fishing-2x.png"
            width="640"
            height="361"
            alt="Sal, the Gamma mascot, fishing extraterrestrially"
          />
        </Box>
        <Heading size="md" mb={2} textAlign="center">
          <Trans>No folders found.</Trans>
        </Heading>
        <Text fontSize="lg" color="gray.400" textAlign="center">
          <Trans>
            Try changing your search query, or{' '}
            <Link onClick={onCreateClick} color="trueblue.500">
              create a new folder called "{channelName}".
            </Link>
          </Trans>
        </Text>
      </Flex>
    </Center>
  )
}
