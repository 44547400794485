import {
  Box,
  Circle,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  useDisclosure,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DarkModeProvider } from '@gamma-app/ui'
import { t, Trans } from '@lingui/macro'
import { memo, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { useHealthCheck } from 'modules/api'
import { useFeatureFlag } from 'modules/featureFlags'
import { useImperativeDocEditorTour } from 'modules/guiders'
import { GAMMA_SUPPORT_EMAIL } from 'modules/help/constants'
import { StandaloneLocaleModal } from 'modules/i18n/components/StandaloneLocaleModal'
import { SUPPORTED_LOCALE_OPTIONS } from 'modules/i18n/constants'
import { useLinguiLocale } from 'modules/i18n/hooks/useLinguiLocale'
import { useLaunchIntercom } from 'modules/intercom/hooks'
import { setIsIntercomOpen } from 'modules/intercom/reducer'
import { SegmentEvents, useAnalytics } from 'modules/segment'
import { useIsThemeDark } from 'modules/theming/hooks'
import { useLocalStorage } from 'utils/hooks/useLocalStorage'

import { HelpWidgetWrapper } from '../HelpWidgetWrapper'
import { KeyboardShortcutsPopover } from '../KeyboardShortcutsPopover'

const BORDERLESS_FRAME_INTERVAL = 1000

const WHATS_NEW_URL =
  'https://gamma.app/public/Change-Log-awopg3inl2eo3b3?mode=doc#card-7l4mxahmsc10wrp'

const FAQ_URL = 'https://help.gamma.app'
export const DefaultHelpWidget = memo(
  ({
    showShortcuts = true,
    showTourReplay = false,
  }: {
    showShortcuts?: boolean
    showTourReplay?: boolean
  }) => {
    const { startTour } = useImperativeDocEditorTour()
    const dispatch = useDispatch()
    const { isConnected } = useHealthCheck()

    const isIntercomEnabled = useFeatureFlag('intercom')
    const launchIntercomUI = useLaunchIntercom()

    const localeKey = useLinguiLocale()
    const {
      isOpen: isKeyboardShortcutsPopoverOpen,
      onOpen: onKeyboardShortcutsPopoverOpen,
      onClose: onKeyboardShortcutsPopoverClose,
    } = useDisclosure()
    const {
      isOpen: isStandaloneLocaleModalOpen,
      onOpen: onStandaloneLocaleModalOpen,
      onClose: onStandaloneLocaleModalClose,
    } = useDisclosure({ id: 'locale-modal' })
    // Store the unread state in localStorage so it can survive a page refresh
    const [hasUnread, setHasUnread] = useLocalStorage(
      'hasUnreadIntercom',
      false
    )
    const analytics = useAnalytics()
    const intercom = window.Intercom

    const handleContactUsClick = useCallback(() => {
      setHasUnread(false)
      launchIntercomUI()
    }, [launchIntercomUI, setHasUnread])

    const isDark = useIsThemeDark()

    useEffect(() => {
      if (!intercom) return

      intercom('onHide', () => {
        dispatch(setIsIntercomOpen({ isOpen: false }))
      })
      const intervalId = setInterval(() => {
        // This is a super-hack to determine if any messages have come in
        // since our last interaction with the help widget.
        if (document.querySelector('iframe.intercom-borderless-frame')) {
          // We'll clear this when opening the widget!
          setHasUnread(true)
        }
      }, BORDERLESS_FRAME_INTERVAL)

      return () => {
        intercom('hide')
        clearInterval(intervalId)
      }
    }, [dispatch, intercom, setHasUnread])

    return (
      <HelpWidgetWrapper>
        <DarkModeProvider isDark={isDark}>
          <Menu>
            <MenuButton
              data-guider-highlight="default-help-widget-activator"
              as={IconButton}
              isRound
              position="relative"
              size="md"
              aria-label={t`Help`}
              icon={
                hasUnread ? (
                  <>
                    <Circle
                      position="absolute"
                      size={2.5}
                      bg="red.400"
                      top={0}
                      right={0}
                    />
                    <FontAwesomeIcon
                      icon={regular('bell')}
                      className="fa-beat-fade"
                    />
                  </>
                ) : (
                  <FontAwesomeIcon icon={regular('question')} />
                )
              }
            />
            <MenuList>
              <MenuGroup>
                {showShortcuts && (
                  <MenuItem
                    onClick={onKeyboardShortcutsPopoverOpen}
                    icon={
                      <FontAwesomeIcon fixedWidth icon={regular('keyboard')} />
                    }
                  >
                    <Trans>Shortcuts</Trans>
                  </MenuItem>
                )}
                <MenuItem
                  as={Link}
                  _hover={{ textDecoration: 'none', shadow: 'none' }}
                  href={WHATS_NEW_URL}
                  target="_blank"
                  icon={
                    <FontAwesomeIcon fixedWidth icon={regular('sparkles')} />
                  }
                >
                  <Trans>What's new</Trans>
                </MenuItem>
                <MenuItem
                  as={Link}
                  _hover={{ textDecoration: 'none', shadow: 'none' }}
                  href={FAQ_URL}
                  target="_blank"
                  icon={
                    <FontAwesomeIcon
                      fixedWidth
                      icon={regular('seal-question')}
                    />
                  }
                >
                  <Trans>FAQ</Trans>
                </MenuItem>
                {showTourReplay && (
                  <MenuItem
                    as={Link}
                    _hover={{ textDecoration: 'none', shadow: 'none' }}
                    onClick={() => {
                      analytics?.track(SegmentEvents.DOC_EDITOR_TOUR_STARTED, {
                        source: 'help-widget',
                      })
                      startTour(0)
                    }}
                    icon={<FontAwesomeIcon fixedWidth icon={regular('map')} />}
                    data-guider-highlight="replay-intro-tour"
                  >
                    <Trans>Take the editor tour</Trans>
                  </MenuItem>
                )}
              </MenuGroup>
              <MenuDivider />
              <MenuGroup>
                {isIntercomEnabled && isConnected ? (
                  <MenuItem
                    icon={
                      hasUnread ? (
                        <Box position="relative">
                          <Circle
                            position="absolute"
                            size={1.5}
                            bg="red.400"
                            top={0}
                            right={-1}
                          />
                          <FontAwesomeIcon
                            fixedWidth
                            icon={regular('bell')}
                            className="fa-beat-fade"
                          />
                        </Box>
                      ) : (
                        <FontAwesomeIcon
                          fixedWidth
                          icon={regular('message-smile')}
                        />
                      )
                    }
                    onClick={handleContactUsClick}
                  >
                    <Trans>Contact us</Trans>
                  </MenuItem>
                ) : (
                  <MenuItem
                    icon={
                      <FontAwesomeIcon fixedWidth icon={regular('envelope')} />
                    }
                    as={Link}
                    _hover={{ textDecoration: 'none', shadow: 'none' }}
                    href={`mailto:${GAMMA_SUPPORT_EMAIL}`}
                  >
                    <Trans>Email us</Trans>
                  </MenuItem>
                )}

                <MenuDivider />
                <MenuItem
                  icon={<FontAwesomeIcon fixedWidth icon={regular('earth')} />}
                  onClick={onStandaloneLocaleModalOpen}
                >
                  {SUPPORTED_LOCALE_OPTIONS[localeKey].displayName}
                </MenuItem>
              </MenuGroup>
            </MenuList>
            <KeyboardShortcutsPopover
              isOpen={isKeyboardShortcutsPopoverOpen}
              onClose={onKeyboardShortcutsPopoverClose}
            />
          </Menu>
          {isStandaloneLocaleModalOpen && (
            <StandaloneLocaleModal onClose={onStandaloneLocaleModalClose} />
          )}
        </DarkModeProvider>
      </HelpWidgetWrapper>
    )
  }
)

DefaultHelpWidget.displayName = 'DefaultHelpWidget'
