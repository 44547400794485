import {
  Box,
  Divider,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  VStack,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'
import Router, { useRouter } from 'next/router'
import { useCallback, useEffect } from 'react'

import { AI_WIZARD_PATH } from 'modules/ai/wizard/constants'
import { DocSortField, SortDirection, useGetDocsQuery } from 'modules/api'
import { useCreateDocAndNavigate } from 'modules/api/hooks'
import { FeatureFlags, useFeatureFlag } from 'modules/featureFlags'
import { replaceState } from 'modules/history'
import { SegmentEvents, useAnalytics } from 'modules/segment'
import { useUserContext } from 'modules/user'
import { useGammaBreakpointValue } from 'utils/breakpoints/useGammaBreakpointValue'
import { isMobileDevice } from 'utils/deviceDetection'
import { useLocalStorage } from 'utils/hooks/useLocalStorage'
import { USER_SETTINGS_CONSTANTS } from 'utils/userSettingsConstants'

import { GetStartedOption } from './GetStartedOption'
import { GETTING_STARTED_OPTIONS } from './optionsData'

const GET_STARTED_SEARCH_PARAM = 'getStarted'

export const GetStartedModal = () => {
  const { push, query, pathname } = useRouter()
  const [createDoc] = useCreateDocAndNavigate()
  const hasGetStartedQueryParam = Boolean(query[GET_STARTED_SEARCH_PARAM])
  const handleOnCloseClick = useCallback(
    (newPath?: string) => {
      const { searchParams } = new URL(window.location.href)
      searchParams.delete(GET_STARTED_SEARCH_PARAM)

      if (!newPath) {
        // User closed or clicked outside - Clear the query param and trigger a next route
        Router.replace(
          { pathname, query: searchParams.toString() },
          undefined,
          {
            shallow: true,
          }
        )
      } else if (newPath.startsWith('/#')) {
        // User chose a sidebar tab - Router.replace with the full new path
        Router.replace(newPath)
      } else {
        // User is navigating outside of the home screen - clear the param then do a push
        replaceState({ query: Object.fromEntries(searchParams.entries()) })
        push(newPath)
      }
    },
    [pathname, push]
  )

  const handleCreateDocClick = useCallback(() => {
    const { searchParams } = new URL(window.location.href)
    searchParams.delete(GET_STARTED_SEARCH_PARAM)
    replaceState({ query: Object.fromEntries(searchParams.entries()) })
    createDoc({ source: 'get_started_modal' })
  }, [createDoc])

  if (!hasGetStartedQueryParam) return null

  return (
    <GetStartedModalContent
      onClose={handleOnCloseClick}
      onCreateDoc={handleCreateDocClick}
    />
  )
}

const getGettingStartedOptions = ({
  isMobile,
  aiWizardEnabled,
  openAiStatus,
}: {
  isMobile: boolean
  aiWizardEnabled: boolean
  openAiStatus: FeatureFlags['openAiStatus']
}) => {
  const isOpenAiStatusDown = openAiStatus == 'DOWN'
  if (isMobile) {
    return [
      ...(aiWizardEnabled && !isOpenAiStatusDown
        ? [GETTING_STARTED_OPTIONS.createFromAI]
        : []),
      GETTING_STARTED_OPTIONS.tipsAndTricks,
      GETTING_STARTED_OPTIONS.inspiration,
    ]
  } else {
    return [
      ...(aiWizardEnabled && !isOpenAiStatusDown
        ? [GETTING_STARTED_OPTIONS.createFromAI]
        : []),
      GETTING_STARTED_OPTIONS.createDeck,
      GETTING_STARTED_OPTIONS.viewTemplates,
      GETTING_STARTED_OPTIONS.tipsAndTricks,
      // Always limit to max 3 options
    ].slice(0, 3)
  }
}

const ACTION_TARGET_MAP = {
  createFromAI: 'create_from_ai',
  welcomeDoc: 'welcome_doc',
  templates: 'templates',
  inspiration: 'inspiration',
  blankDeck: 'create_doc',
}
const GetStartedModalContent = ({
  onClose,
  onCreateDoc,
}: {
  onClose: (pathname?: string) => void
  onCreateDoc: () => void
}) => {
  const createGenerateV2Enabled = useFeatureFlag('createGenerateV2')
  const analytics = useAnalytics()
  const [welcomeDocIdLS] = useLocalStorage<string | null>(
    USER_SETTINGS_CONSTANTS.welcomeDocId,
    null
  )
  const aiWizardEnabled = useFeatureFlag('aiWizard')
  const openAiStatus = useFeatureFlag('openAiStatus')

  const size = useGammaBreakpointValue(['full', 'full', '3xl', '4xl'])
  const { user, currentWorkspace, setSettings } = useUserContext()
  const userId = user?.id

  const options = getGettingStartedOptions({
    isMobile: isMobileDevice(),
    aiWizardEnabled,
    openAiStatus,
  })

  const aiPath = createGenerateV2Enabled
    ? '/create/generate?ref=dashboard'
    : AI_WIZARD_PATH

  useEffect(() => {
    setSettings?.({ remove: ['showGetStarted'] })
  }, [setSettings])

  const {
    data: welcomeDocData,
    loading: welcomeDocLoading,
    called: welcomeDocCalled,
  } = useGetDocsQuery({
    variables: {
      workspaceId: currentWorkspace?.id,
      first: 1,
      createdBy: userId,
      sortBy: {
        field: DocSortField.CreatedTime,
        direction: SortDirection.Asc,
      },
    },
    skip: Boolean(!userId || welcomeDocIdLS),
  })

  const welcomeDocId =
    welcomeDocIdLS ||
    user?.settings?.welcomeDocId ||
    welcomeDocData?.docs.edges[0]?.node.id
  const handleActionClick = useCallback(
    (action?: string) => {
      switch (action) {
        case 'welcomeDoc':
          if (welcomeDocId) {
            onClose(`/docs/${welcomeDocId}`)
          } else {
            console.warn('[StartingModal] No welcomeDoc')
          }
          break

        case 'templates':
          onClose('/#templates')
          break

        case 'inspiration':
          onClose('/#inspiration')
          break

        case 'blankDeck':
          onCreateDoc()
          break

        case 'createFromAI':
          onClose(aiPath)
          break

        default:
          console.warn('[StartingModal] Unknown action', action)
          break
      }
    },
    [aiPath, onClose, onCreateDoc, welcomeDocId]
  )

  return (
    <Modal
      isOpen={true}
      size={size}
      trapFocus={false}
      onClose={() => {
        analytics?.track(SegmentEvents.GET_STARTED_CLICKED, {
          target: 'dismiss',
          variation: 'new',
        })
        onClose()
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Trans>How would you like to get started?</Trans>
        </ModalHeader>
        <ModalCloseButton />
        {isMobileDevice() ? (
          <Box padding="4" background="salmon.100" mb="4">
            <HStack spacing="5" paddingLeft="2">
              <Box color="salmon.600">
                <FontAwesomeIcon icon={regular('desktop')} className="fa-xl" />
              </Box>
              <VStack align="flex-start" spacing="0">
                <Text fontWeight="600" fontSize="lg">
                  <Trans>Gamma works best on desktop</Trans>
                </Text>
                <Text fontSize="sm">
                  <Trans>
                    Just a heads up: you'll need a computer to edit anything you
                    create in Gamma.
                  </Trans>
                </Text>
              </VStack>
            </HStack>
          </Box>
        ) : (
          <Divider mb="4" />
        )}
        <ModalBody>
          <SimpleGrid columns={[1, 1, 3]} spacing={5}>
            {options.map((option, index) => {
              const { icon, colorScheme, title, body, action, isNew } = option

              const isMissingWelcomeDoc =
                !welcomeDocIdLS && // We didnt find an id in local storage from signing up
                welcomeDocCalled && // We've kicked off the fetch for the users first doc
                !welcomeDocLoading && // The fetch isnt loading anymore
                !welcomeDocData // And finally there is no data from the fetch
              if (
                action === 'welcomeDoc' &&
                // Dont show the welcomeDoc option if we cant find a welcome doc. This is unexpected
                isMissingWelcomeDoc
              ) {
                console.error(
                  '[GetStartedModalContent] Unexpectedly cant find a welcomeDocId'
                )
                return null
              }

              const href =
                action === 'blankDeck'
                  ? '/new'
                  : action === 'templates'
                  ? '/#templates'
                  : action === 'inspiration'
                  ? '/#inspiration'
                  : action === 'welcomeDoc'
                  ? `/docs/${welcomeDocId}`
                  : action === 'createFromAI'
                  ? aiPath
                  : '/'

              return (
                <GetStartedOption
                  key={index}
                  accentColor={colorScheme || 'trueblue'}
                  title={title}
                  body={body}
                  href={href}
                  icon={icon}
                  isNew={isNew}
                  onClick={(e) => {
                    analytics?.track(SegmentEvents.GET_STARTED_CLICKED, {
                      target: ACTION_TARGET_MAP[action],
                      variation: 'new',
                    })
                    if (!e.metaKey) {
                      e.preventDefault()
                      handleActionClick(action)
                    }
                  }}
                />
              )
            })}
          </SimpleGrid>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  )
}
