import { useToast } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import Router from 'next/router'
import { useCallback } from 'react'

import {
  Channel,
  CreateChannelInput,
  GetChannelsDocument,
  useCreateChannelMutation,
  useJoinChannelMutation,
  useLeaveChannelMutation,
} from 'modules/api'
import { generateChannelUrl } from 'utils/url'

export const useChannelMutations = () => {
  const toast = useToast()
  const [_leaveChannel] = useLeaveChannelMutation()
  const [_joinChannel] = useJoinChannelMutation()
  const [
    _createChannel,
    { loading: createChannelLoading, error: createChannelError },
  ] = useCreateChannelMutation()

  const leaveChannel = useCallback(
    ({ channel }: { channel: Channel }) => {
      return _leaveChannel({
        variables: {
          channelId: channel.id,
        },
      }).then(() => {
        toast({
          title: (
            <Trans comment="{channel.name} refers to the name of a folder that the user just left ">
              Left "{channel?.name}"
            </Trans>
          ),
          description: (
            <Trans comment="{channel?.name} refers to the name of a folder that the user just left">
              You are no longer a member of "{channel?.name}"
            </Trans>
          ),
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top',
        })
      })
    },
    [_leaveChannel, toast]
  )

  const joinChannel = useCallback(
    ({ channel, workspaceId }: { channel: Channel; workspaceId: string }) => {
      const { name } = channel
      return _joinChannel({
        variables: {
          channelId: channel.id,
        },
        update: (cache, { data }) => {
          if (!data?.joinChannel) return
          // Write the newly joined channel into the cache
          // The channels merge function will handle inserting this
          // channel into the list of existing channels
          cache.writeQuery({
            query: GetChannelsDocument,
            variables: {
              workspaceId,
            },
            data: {
              channels: [data.joinChannel],
            },
          })
        },
        optimisticResponse: {
          joinChannel: {
            ...channel,
          },
        },
      })
        .then(() => {
          toast({
            title: (
              <Trans comment="{name} refers to the name of a folder that the user just joined">
                Joined "{name}"
              </Trans>
            ),
            description: (
              <Trans comment="{name} refers to the name of a folder that the user just joined">
                You are now a member of "{name}"
              </Trans>
            ),
            status: 'success',
            duration: 3000,
            isClosable: true,
            position: 'top',
          })
        })
        .catch((e) => {
          toast({
            title: (
              <Trans comment="{name} refers to the name of a folder that the user just tried to join">
                Error joining "{name}":
              </Trans>
            ),
            description: e.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'top',
          })
        })
    },
    [_joinChannel, toast]
  )

  const createChannel = useCallback(
    ({ input }: { input: CreateChannelInput }) => {
      return _createChannel({
        variables: { input },
        update: (cache, { data }) => {
          if (!data?.createChannel) return

          // Write the newly created channel into the cache
          // The channels merge function will handle inserting this
          // channel into the list of existing channels
          cache.writeQuery({
            query: GetChannelsDocument,
            variables: {
              workspaceId: input.workspaceId,
            },
            data: {
              channels: [data.createChannel],
            },
          })
        },
        optimisticResponse: {
          createChannel: {
            id: 'temp-id',
            slug: 'temp-slug',
            ...input,
            createdTime: new Date().toISOString(),
            updatedTime: new Date().toISOString(),
            __typename: 'Channel',
          },
        },
      })
        .then((res) => {
          const channel = res?.data?.createChannel
          if (channel?.name) {
            toast({
              title: (
                <Trans comment="{channel.name} refers to the name of the folder that was just created">
                  Created folder "{channel.name}"
                </Trans>
              ),
              status: 'success',
              duration: 3000,
              isClosable: true,
              position: 'top',
            })
          }
          if (channel?.id && channel?.slug) {
            Router.push(
              generateChannelUrl({
                id: channel.id,
                slug: channel.slug,
              })
            )
          } else {
            console.error(
              `[CreateChannelModal] Error generating channel URL. ID: "${channel?.id}". Slug: "${channel?.slug}"`
            )
          }
        })
        .catch((e) => {
          toast({
            title: <Trans>Error creating folder:</Trans>,
            description: e.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'top',
          })
        })
    },
    [_createChannel, toast]
  )

  return {
    joinChannel,
    leaveChannel,
    createChannel,
    createChannelLoading,
    createChannelError,
  }
}
