import {
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import { DarkModeProvider } from '@gamma-app/ui'
import { Trans } from '@lingui/macro'
import { useCallback, useMemo, useState } from 'react'

import { LocalePicker } from 'modules/i18n/components/LocalePicker'
import { SUPPORTED_LOCALE_OPTIONS } from 'modules/i18n/constants'
import { useUserContext } from 'modules/user'

import { useLinguiLocale } from '../hooks/useLinguiLocale'
import { useLocalePreview } from '../hooks/useLocalePreview'

export const StandaloneLocaleModal = ({ onClose }) => {
  const toast = useToast()

  const { user } = useUserContext()
  const [isSaving, setIsSaving] = useState(false)
  const { setSettings } = useUserContext()

  const i18nLocaleKey = useLinguiLocale()
  const initialI18nLocaleKey = useMemo(() => i18nLocaleKey, [])

  const [localeKey, setLocaleKey] = useState(initialI18nLocaleKey)
  useLocalePreview(localeKey)

  const onSave = useCallback(async () => {
    setIsSaving(true)
    try {
      await setSettings?.({
        set: {
          locale: localeKey,
        },
      })
      toast({
        title: <Trans>Language updated.</Trans>,
        position: 'top',
        status: 'success',
        isClosable: true,
        duration: 3000,
      })
      onClose()
    } catch (e) {
      console.error(
        `[StandaloneLocaleModal.onSave] Error updating language for user with id "${user?.id}"`,
        e.message
      )
    }
    setIsSaving(false)
  }, [localeKey, onClose, setSettings, toast, user?.id])

  const hasUnsavedChanges = localeKey !== user?.settings?.locale
  return (
    <DarkModeProvider isDark={false}>
      <Modal isOpen={true} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Trans>Change language</Trans>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>
                <Trans>Account language</Trans>
              </FormLabel>
              <LocalePicker
                selectedLocale={SUPPORTED_LOCALE_OPTIONS[localeKey]}
                onLocaleSelect={setLocaleKey}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            {hasUnsavedChanges ? (
              <ButtonGroup>
                <Button variant="ghost" onClick={onClose}>
                  <Trans>Cancel</Trans>
                </Button>
                <Button variant="solid" onClick={onSave} isLoading={isSaving}>
                  <Trans>Save</Trans>
                </Button>
              </ButtonGroup>
            ) : (
              <Button variant="plain" onClick={onClose}>
                <Trans>Close</Trans>
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </DarkModeProvider>
  )
}
