import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Spacer,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AvatarGroupAutoOverflow } from '@gamma-app/ui'
import { Plural, Trans } from '@lingui/macro'
import { useRouter } from 'next/router'
import { useCallback } from 'react'

import { Channel, ExistingWorkspace } from 'modules/api'
import { useCreateDocAndNavigate } from 'modules/api/hooks'
import { AICreditsModal } from 'modules/credits/components/AICreditsModal'
import { useCreditStatus } from 'modules/credits/hooks'
import { TemplatesModal } from 'modules/example_decks'
import { useFeatureFlag } from 'modules/featureFlags'
import { ImportDocPicker } from 'modules/import/ImportDocPicker'
import { ImportDocPickerV2 } from 'modules/import/ImportDocPickerV2'
import { parseImportedDoc } from 'modules/import/parseImportedDoc'
import { ImportedFile, ImportSources } from 'modules/import/types'
import { SegmentEvents, useAnalytics } from 'modules/segment'
import { NewWithAIButtonAndModal } from 'sections/home_v2/components/DocsView/DocsViewTopbar/NewWithAIButtonAndModal'
import { isMobileDevice } from 'utils/deviceDetection'

import { ManageChannelMenu } from '../../channels'
import { TabInfo } from '../../Sidebar/Sidebar'

type HeaderProps = {
  currentWorkspace?: ExistingWorkspace
  description: TabInfo['description']
  ariaLabel: TabInfo['ariaLabel']
  displayName: TabInfo['displayName']
  icon: TabInfo['icon']
  showCreateButton: TabInfo['showCreateButton']
  channel?: Channel
  isChannelPreview: boolean
}

export const Header = ({
  currentWorkspace,
  description,
  ariaLabel,
  displayName,
  icon,
  showCreateButton,
  channel,
  isChannelPreview,
}: HeaderProps) => {
  const {
    isOpen: isTemplateBrowserModalOpen,
    onOpen: onTemplateBrowserModalOpen,
    onClose: onTemplateBrowserModalClose,
  } = useDisclosure({
    id: 'template-browser-modal',
  })
  const {
    isOpen: isAICreditsModalOpen,
    onOpen: onAICreditsModalOpen,
    onClose: onAICreditsModalClose,
  } = useDisclosure({ id: 'ai-credits-modal' })

  const aiWizardEnabled = useFeatureFlag('aiWizard')
  const createV2Enabled = useFeatureFlag('createv2')
  const analytics = useAnalytics()
  const { push } = useRouter()

  const { credits, hasEnoughCreditsToGenerateDeck } = useCreditStatus()

  const [
    createDocAndNavigate,
    { data: createDocData, loading: createDocLoading },
  ] = useCreateDocAndNavigate()

  const isCreateButtonDisabled =
    createDocLoading || Boolean(createDocData?.createDoc || !currentWorkspace)

  const onImportSelectionMade = useCallback(
    ({ html, filename, source }: ImportedFile) => {
      if (!html) return
      const isDocImport = source === 'GoogleDocs' || source === 'DocxUpload'
      const document = parseImportedDoc(html, {
        splitOnHeadings: isDocImport,
        splitOnDividers: true,
        nested: isDocImport,
      })
      analytics?.track(SegmentEvents.DOC_IMPORTED, {
        source,
      })

      createDocAndNavigate({
        document,
        channelId: channel?.id,
        title: filename,
        source: 'import',
      })
    },
    [channel?.id, createDocAndNavigate, analytics]
  )

  const createBlank = useCallback(() => {
    createDocAndNavigate({
      channelId: channel?.id,
      source: 'blank',
    })
  }, [channel?.id, createDocAndNavigate])

  return (
    <Flex flex="1" mb={isMobileDevice() ? 0 : 4} direction={'column'}>
      <Box w={['100%', 'auto']}>
        <HStack h="1.5em">
          <Heading
            fontSize="lg"
            fontWeight="600"
            data-testid="docs-view-header"
          >
            {icon && <FontAwesomeIcon icon={icon} aria-label={ariaLabel} />}{' '}
            <Text as="span" ml={2}>
              {displayName}
            </Text>
          </Heading>
          <Spacer />
          {!isChannelPreview && channel && (
            <ManageChannelMenu
              channel={channel}
              workspaceId={currentWorkspace?.id}
            />
          )}
        </HStack>
        {description && (
          <Text color="gray.600" fontSize="sm">
            {description}
          </Text>
        )}
        {channel && (
          <Stack direction="row" alignItems="center" mt={4}>
            <AvatarGroupAutoOverflow
              overflowTooltipLabel="See everyone"
              size="xs"
              avatars={channel?.members || []}
              max={6}
            />
            <Text fontSize="sm" color="gray.400" ml={2}>
              <Plural
                value={channel.memberCount || 0}
                one="# member"
                other="# members"
              />
            </Text>
          </Stack>
        )}
      </Box>
      {/* Only render the stack if the create button is enabled. An empty stack takes up space! */}
      {showCreateButton && (
        <Stack direction={{ base: 'column', lg: 'row' }} spacing={2} mt={4}>
          {aiWizardEnabled && (
            <NewWithAIButtonAndModal
              isDisabled={isCreateButtonDisabled}
              handleNewWithAIClick={
                !hasEnoughCreditsToGenerateDeck
                  ? onAICreditsModalOpen
                  : undefined
              }
            />
          )}

          {!isMobileDevice() && (
            <Button
              data-testid="create-doc-button"
              isDisabled={isCreateButtonDisabled}
              variant="plain"
              onClick={createBlank}
              leftIcon={<FontAwesomeIcon icon={regular('plus')} />}
            >
              <Trans>New from blank</Trans>
            </Button>
          )}

          {/* {!isMobileDevice() && (
            <Button
              data-testid="create-from-template-button"
              isDisabled={isCreateButtonDisabled}
              variant="plain"
              onClick={onTemplateBrowserModalOpen}
              leftIcon={<FontAwesomeIcon icon={regular('plus')} />}
            >
              New from template
            </Button>
          )} */}
          {showCreateButton && !isMobileDevice() && !createV2Enabled && (
            <ImportDocPicker
              handleImport={onImportSelectionMade}
              isDisabled={isCreateButtonDisabled}
              helpTip={
                <Text fontSize="sm" color="gray.500">
                  <Trans>
                    Tip: You can also paste content from most writing tools
                    directly into a{' '}
                    <Button variant="link" onClick={createBlank} size="sm">
                      blank page
                    </Button>
                    .
                  </Trans>
                </Text>
              }
            />
          )}
          {showCreateButton && !isMobileDevice() && createV2Enabled && (
            <ImportDocPickerV2
              handleImport={onImportSelectionMade}
              isDisabled={isCreateButtonDisabled}
            />
          )}
        </Stack>
      )}

      <AICreditsModal
        credits={credits}
        isOpen={isAICreditsModalOpen}
        onClose={onAICreditsModalClose}
        onOpen={onAICreditsModalOpen}
      />

      <TemplatesModal
        isOpen={isTemplateBrowserModalOpen}
        onClose={onTemplateBrowserModalClose}
        forceChannelId={channel?.id}
      />
    </Flex>
  )
}
