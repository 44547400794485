import {
  Box,
  Heading,
  HStack,
  Kbd,
  Popover,
  PopoverAnchor,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  Tab,
  Table,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Tr,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getShortcutTextForOS, SectionTitle } from '@gamma-app/ui'
import { Trans } from '@lingui/macro'

import { useLocalizedFunction } from 'modules/i18n/hooks/useLocalizedFunction'

import {
  getDocModeShortcuts,
  getPresentModeShortcuts,
  getTextFormattingShortcuts,
  ShortcutConfig,
} from './keyboardShortcutConfig'

const tdStyles = {
  borderColor: 'gray.100',
  py: 2,
  px: 2,
}

export const ShortcutsTable = ({ list }: { list: Array<ShortcutConfig> }) => {
  return (
    <TableContainer w="100%">
      <Table variant="simple">
        <Tbody>
          {list.map(({ shortcut, description }, index) => {
            return (
              <Tr key={index}>
                <Td w="60%" {...tdStyles}>
                  {description}
                </Td>
                <Td w="40%" {...tdStyles}>
                  {Array.isArray(shortcut) ? (
                    shortcut.map((s, index) => (
                      <Kbd
                        display="inline-block"
                        px={2}
                        py={1}
                        mr={1}
                        key={index}
                      >
                        {typeof s === 'string' ? getShortcutTextForOS(s) : s}
                      </Kbd>
                    ))
                  ) : typeof shortcut == 'string' ? (
                    <Kbd display="inline-block" px={2} py={1}>
                      {getShortcutTextForOS(shortcut)}
                    </Kbd>
                  ) : (
                    <Kbd display="inline-block" px={2} py={1}>
                      {shortcut}
                    </Kbd>
                  )}
                </Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </TableContainer>
  )
}
export const KeyboardShortcutsPopover = ({ isOpen, onClose }) => {
  const textFormattingShortcuts = useLocalizedFunction(
    getTextFormattingShortcuts
  )
  const presentModeShortcuts = useLocalizedFunction(getPresentModeShortcuts)
  const docModeShortcuts = useLocalizedFunction(getDocModeShortcuts)
  return (
    <Popover
      returnFocusOnClose={false}
      closeOnBlur={false}
      placement="top"
      isOpen={isOpen}
      onClose={onClose}
      modifiers={[
        {
          name: 'computeStyles',
          options: {
            gpuAcceleration: false,
          },
        },
      ]}
    >
      <PopoverAnchor>
        <Box position="fixed" bottom={0} right={0} />
      </PopoverAnchor>
      <PopoverContent
        zIndex="menu"
        h="80vh"
        w="400px"
        flex={1}
        position="relative"
      >
        <PopoverHeader border="0 none" mb={2} position="sticky" bgColor="white">
          <HStack spacing={2}>
            <FontAwesomeIcon icon={regular('keyboard')} />
            <Heading size="md">
              <Trans comment="Keyboard shortcuts">Shortcuts</Trans>
            </Heading>
          </HStack>
          <PopoverCloseButton />
        </PopoverHeader>
        <PopoverBody overflowY="auto">
          <Tabs variant="soft-rounded" size="sm" isFitted>
            <TabList mb={6}>
              <Tab>
                <Box mr={2}>
                  <FontAwesomeIcon icon={regular('pencil')} />
                </Box>
                <Trans>Doc mode</Trans>
              </Tab>
              <Tab>
                <Box mr={2}>
                  <FontAwesomeIcon icon={regular('presentation-screen')} />
                </Box>
                <Trans>Present mode</Trans>
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel p={0} pb={1} mt={4}>
                <ShortcutsTable list={docModeShortcuts} />
                <SectionTitle pt={4} pb={4}>
                  <Trans>Text & formatting</Trans>
                </SectionTitle>
                <ShortcutsTable list={textFormattingShortcuts} />
              </TabPanel>
              <TabPanel p={0} pb={1} mt={4}>
                <ShortcutsTable list={presentModeShortcuts} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
