import {
  Box,
  Button,
  FormControl,
  FormControlProps,
  FormHelperText,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Select,
  Stack,
  VStack,
} from '@chakra-ui/react'
import { GammaTooltip } from '@gamma-app/ui'
import { t, Trans } from '@lingui/macro'
import { useCallback, useState } from 'react'

import { useUpdateUserMutation } from 'modules/api'
import { GRANT_PROPER_NOUN } from 'modules/i18n/properNouns'
import { useUserContext } from 'modules/user'

type JobFunction = {
  id: string
  label: () => string
}
const ORDERED_JOB_FUNCTIONS: JobFunction[] = [
  // https://docs.google.com/spreadsheets/d/1HUMKWXHhOHNeFV8JP2H1uKifjqtwc7wxoymb91Q7jfQ/edit#gid=0
  { id: 'consulting', label: () => t`Consultant` },
  { id: 'creator', label: () => t`Creator` },
  { id: 'customer_service', label: () => t`Customer service` },
  { id: 'data', label: () => t`Data` },
  { id: 'design', label: () => t`Design` },
  { id: 'education', label: () => t`Education` },
  { id: 'engineering', label: () => t`Engineering` },
  { id: 'finance', label: () => t`Finance` },
  { id: 'human_resources', label: () => t`HR` },
  { id: 'leadership', label: () => t`Leadership` },
  { id: 'legal', label: () => t`Legal` },
  { id: 'marketing', label: () => t`Marketing` },
  { id: 'operations', label: () => t`Operations` },
  { id: 'product', label: () => t`Product` },
  { id: 'recruiting', label: () => t`Recruiting` },
  { id: 'sales', label: () => t`Sales` },
  { id: 'other', label: () => t`Other` },
]

type JobFunctionCategories = 'work' | 'education' | 'personal'
type UserInfoContext = 'get-started' | 'modal' | 'setup'
type UserInfoContextConfig = {
  [key in UserInfoContext]: {
    saveButtonText: () => string
    formControlMaxW: FormControlProps['maxWidth']
    categoryQuestion: () => string
  }
}
const contextConfig: UserInfoContextConfig = {
  'get-started': {
    saveButtonText: () => t`Get started`,
    formControlMaxW: ['100%', '80%', '50%'],
    categoryQuestion: () => t`How do you plan to use Gamma?`,
  },
  modal: {
    saveButtonText: () => t`Submit`,
    formControlMaxW: ['100%', '60%'],
    categoryQuestion: () => t`How are you using Gamma?`,
  },
  setup: {
    saveButtonText: () => t`Continue`,
    formControlMaxW: ['100%'],
    categoryQuestion: () => t`How do you plan to use Gamma?`,
  },
}
const ORDERED_JOB_FUNCTION_CATEGORIES: {
  id: JobFunctionCategories
  label: () => string
}[] = [
  {
    id: 'work',
    label: () => t`For work`,
  },
  {
    id: 'education',
    label: () => t`For school`,
  },
  {
    id: 'personal',
    label: () => t`For personal use`,
  },
]

type SchoolTypes = 'primary' | 'secondary' | 'university' | 'other'
const SCHOOL_TYPES_ORDERED: {
  id: SchoolTypes
  label: () => string
}[] = [
  {
    id: 'primary',
    label: () =>
      t({
        comment:
          "In the US, primary school refers to grades K-6; please translate the school level and grades to a description that's more appropriate for your country",
        message: 'Primary school (grades K-6)',
      }),
  },
  {
    id: 'secondary',
    label: () =>
      t({
        comment:
          "In the US, secondary school refers to grades 7-12; please translate the school level and grades to a description that's more appropriate for your country",
        message: 'Secondary school (grades 7-12)',
      }),
  },
  {
    id: 'university',
    label: () => t`University and higher education`,
  },
  {
    id: 'other',
    label: () => t`Other`,
  },
]

type SchoolRoles = 'student' | 'teacher' | 'administrator' | 'other'
const SCHOOL_ROLES_ORDERED: {
  id: SchoolRoles
  label: () => string
}[] = [
  {
    id: 'student',
    label: () => t`Student`,
  },
  {
    id: 'teacher',
    label: () => t`Teacher`,
  },
  {
    id: 'administrator',
    label: () => t`Administrator`,
  },
  {
    id: 'other',
    label: () => t`Other`,
  },
]

export const UserInfo = ({
  onSuccess,
  onError,
  context = 'get-started',
}: {
  onSuccess?: () => void
  onError?: () => void
  context?: UserInfoContext
}) => {
  const { user, refetch: refetchUser } = useUserContext()
  const [isSaving, setIsSaving] = useState(false)
  const [category, setCategory] = useState<JobFunctionCategories | undefined>(
    undefined
  )
  const [referrer, setReferrer] = useState<string | undefined>(undefined)
  const [jobFunction, setJobFunction] = useState<string | undefined>(undefined)
  const [updateUser, { loading, error }] = useUpdateUserMutation()
  const [schoolType, setSchoolType] = useState<SchoolTypes | undefined>(
    undefined
  )
  const [schoolRole, setSchoolRole] = useState<SchoolRoles | undefined>(
    'student'
  )
  const hasChosenJobFunction =
    jobFunction === 'education' || category === 'education'
      ? schoolType && schoolRole
      : jobFunction

  const isSaveDisabled = isSaving || Boolean(error) || !hasChosenJobFunction

  const isSaveLoading = loading || isSaving

  const showDisabledButtonTooltip = category && !jobFunction

  const onSaveClick = useCallback(() => {
    const detailedJobFunction =
      jobFunction === 'education' || category === 'education'
        ? `${jobFunction}_${schoolRole}_${schoolType}`
        : jobFunction

    updateUser({
      variables: {
        input: {
          id: user?.id as string,
          referrer,
          jobFunction: detailedJobFunction,
        },
      },
    })
      .then(() => {
        // We need to granularly control the state of the saving button such that someone can't click it while the onSuccess call is in flight
        setIsSaving(true)
        return refetchUser?.()
      })
      .then(() => {
        if (onSuccess) {
          onSuccess()
        }
      })
      .catch((e) => {
        console.error(
          `[GetStarted.UserInfo] Error updating user info "${user?.id}"`,
          e.message
        )
        setIsSaving(false)
        if (onError) {
          onError()
        }
      })
  }, [
    jobFunction,
    schoolType,
    schoolRole,
    onError,
    onSuccess,
    referrer,
    refetchUser,
    updateUser,
    user?.id,
    category,
  ])

  const { formControlMaxW, saveButtonText, categoryQuestion } =
    contextConfig[context]
  return (
    <Box w="100%">
      <Stack pt={6} alignItems="center" spacing={6} alignSelf="center" mb={10}>
        <FormControl maxW={formControlMaxW} as="fieldset" isRequired>
          <FormLabel>{categoryQuestion()}</FormLabel>
          <RadioGroup
            onChange={(val: JobFunctionCategories) => {
              setCategory(val)
              if (val === 'education') {
                setJobFunction('education')
              } else {
                // Reset if you chose education then changed your mind
                setJobFunction(undefined)
              }
            }}
            value={category}
          >
            <Stack direction="column" spacing={2}>
              {ORDERED_JOB_FUNCTION_CATEGORIES.map(({ id, label }) => {
                return (
                  <Radio key={id} value={id}>
                    {label()}
                  </Radio>
                )
              })}
            </Stack>
          </RadioGroup>
        </FormControl>

        {category && (
          <>
            {category !== 'education' && (
              <FormControl maxW={formControlMaxW} isRequired>
                <FormLabel>
                  <Trans>What kind of work do you do?</Trans>
                </FormLabel>
                <Select
                  placeholder={t`Select option`}
                  isRequired
                  onChange={(e) => {
                    const val = e.target.value
                    if (category === 'work' && val === 'education') {
                      setSchoolRole('teacher')
                    }
                    setJobFunction(val)
                  }}
                >
                  {ORDERED_JOB_FUNCTIONS.map(({ label, id }, index) => (
                    <option key={`${id}-${index}`} value={id}>
                      {label()}
                    </option>
                  ))}
                </Select>
              </FormControl>
            )}

            {(category === 'education' || jobFunction === 'education') && (
              <FormControl maxW={formControlMaxW} isRequired>
                <FormLabel>
                  <Trans>
                    Which of the following best describes your role?
                  </Trans>
                </FormLabel>
                <VStack spacing={2}>
                  <Select
                    placeholder={t`Select option`}
                    isRequired
                    value={schoolRole}
                    onChange={(e) => {
                      setSchoolRole(() => e.target.value as SchoolRoles)
                    }}
                  >
                    {SCHOOL_ROLES_ORDERED.map(({ label, id }, index) => (
                      <option key={`${id}-${index}`} value={id}>
                        {label()}
                      </option>
                    ))}
                  </Select>
                  <Select
                    placeholder={t`Select school type`}
                    isRequired
                    onChange={(e) => {
                      setSchoolType(() => e.target.value as SchoolTypes)
                    }}
                  >
                    {SCHOOL_TYPES_ORDERED.map(({ label, id }, index) => (
                      <option key={`${id}-${index}`} value={id}>
                        {label()}
                      </option>
                    ))}
                  </Select>
                </VStack>
                <FormHelperText fontSize="xs">
                  <Trans>
                    We use this information to create more relevant features and
                    templates
                  </Trans>
                </FormHelperText>
              </FormControl>
            )}

            <FormControl maxW={formControlMaxW}>
              <FormLabel>
                <Trans>How did you hear about us?</Trans>
              </FormLabel>
              <Input
                size="lg"
                type="text"
                value={referrer}
                onChange={(e) => {
                  setReferrer(e.target.value)
                }}
              />
              <FormHelperText fontSize="xs">
                {referrer && referrer?.length > 4 ? (
                  <>
                    <Trans>{GRANT_PROPER_NOUN} says thank you</Trans>{' '}
                    <span role="img" aria-label={t`thanks`}>
                      🙏
                    </span>
                  </>
                ) : (
                  <>
                    <Trans>
                      (Optional) You'll make our CEO {GRANT_PROPER_NOUN} happy
                    </Trans>{' '}
                    <span role="img" aria-label={t`smile`}>
                      😊
                    </span>
                  </>
                )}
              </FormHelperText>
            </FormControl>
          </>
        )}

        <Stack alignItems="center" spacing={4} pt={4}>
          <GammaTooltip
            label={<Trans>Please select a role from the dropdown</Trans>}
            isDisabled={!showDisabledButtonTooltip}
            placement="top"
          >
            <Box>
              <Button
                variant="solid"
                size="lg"
                isDisabled={isSaveDisabled}
                isLoading={isSaveLoading}
                onClick={onSaveClick}
              >
                {saveButtonText()}
              </Button>
            </Box>
          </GammaTooltip>
        </Stack>
      </Stack>
    </Box>
  )
}
