import { CloseIcon } from '@chakra-ui/icons'
import {
  Button,
  ButtonGroup,
  Flex,
  IconButton,
  Text,
  ToastId,
  useToast,
} from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import Router from 'next/router'

import { Channel } from 'modules/api'

import { INITIAL_TAB } from '../../constants'

type ChannelPreviewToastProps = {
  workspaceId: string
  currentChannelId: string
  channelPreview: Channel
  joinChannel: ({
    channel,
    workspaceId,
  }: {
    channel: Channel
    workspaceId: string
  }) => Promise<void>
  toastId?: ToastId
}
export const ChannelPreviewToast = ({
  currentChannelId,
  channelPreview,
  workspaceId,
  joinChannel,
  toastId,
}: ChannelPreviewToastProps) => {
  const toast = useToast()
  if (!toastId) {
    return null
  }

  return (
    <Flex
      bg="white"
      p={3}
      px={6}
      m={4}
      justify="center"
      align="center"
      borderRadius="md"
      shadow="md"
    >
      <Text fontSize="sm" mr={4}>
        <Trans>
          You're not a member of <strong>{channelPreview.name}</strong>.
        </Trans>
      </Text>
      <ButtonGroup size="sm" alignItems="center">
        <Button
          maxW={'xs'}
          display="block"
          noOfLines={1}
          onClick={() => {
            if (!currentChannelId || !channelPreview) {
              console.error('No active channel id')
              return
            }
            joinChannel({
              channel: { ...channelPreview },
              workspaceId,
            }).then(() => {
              toast.close(toastId)
            })
          }}
          variant="solid"
        >
          <Trans>Join</Trans>
        </Button>

        <IconButton
          isRound
          variant="ghost"
          size="xs"
          aria-label={t`Dismiss`}
          icon={<CloseIcon />}
          onClick={() => {
            toast.close(toastId)
            Router.replace(`/#${INITIAL_TAB}`)
          }}
        />
      </ButtonGroup>
    </Flex>
  )
}
