import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'
import { useCallback, useState } from 'react'

import { useGetWorkspaceQuery } from 'modules/api'
import { UpsellCallout } from 'modules/monetization/components/UpsellCallout'
import { SegmentEvents } from 'modules/segment'
import { useCanManageWorkspace, useUserContext } from 'modules/user'
import { WorkspaceSubscriptionSummary } from 'modules/workspaces/components/WorkspaceSettingsModal/WorkspaceSubscriptionSummary'
import { normalizeDate } from 'utils/date'

import { GeneralSettings } from './GeneralSettings'
import { InviteByEmail } from './InviteByEmail'
import { InviteLink } from './InviteLink'
import { MembersList } from './MembersList'
import { PendingInvitationsList } from './PendingInvitationsList'

export const WorkspaceSettingsModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}) => {
  const { currentWorkspace } = useUserContext()
  const canManageWorkspace = useCanManageWorkspace()
  const { data: workspaceData } = useGetWorkspaceQuery({
    variables: {
      id: currentWorkspace?.id as string,
    },
    skip: !currentWorkspace?.id,
  })

  const [verifyInviteDone, setVerifyInviteDone] = useState<() => Promise<void>>(
    // Allows the InviteByEmail to register a done fn that will get called before
    // closing the modal
    () => () => Promise.resolve()
  )

  const invitations = workspaceData?.workspace?.invitations || []

  const closeIfReady = useCallback(() => {
    verifyInviteDone()
      .then(onClose)
      .catch(() => {
        console.warn(
          '[workspaceSettingsModal] InviteByEmail rejected to close modal'
        )
      })
  }, [verifyInviteDone, onClose])

  if (!currentWorkspace) return null

  const memberCount = workspaceData?.workspace?.memberCount

  return (
    <Modal isOpen={isOpen} onClose={closeIfReady} size="3xl" trapFocus={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Trans>Workspace settings</Trans>
        </ModalHeader>
        <ModalCloseButton />
        <Divider mb={4} />
        <ModalBody>
          <UpsellCallout
            segmentEvent={
              SegmentEvents.GAMMA_PRO_UPSELL_CALLOUT_WORKSPACE_SETTINGS
            }
            onOpenCallback={closeIfReady}
          />
          <GeneralSettings
            workspace={currentWorkspace}
            canManageWorkspace={canManageWorkspace}
          />

          <Box mt={12}>
            <Stack spacing={8}>
              <Box>
                <Heading size="md" fontWeight="600" mb={4}>
                  <Trans>Invite others to this workspace</Trans>
                </Heading>
                <InviteByEmail
                  workspace={currentWorkspace}
                  canManageWorkspace={canManageWorkspace}
                  setDoneFn={setVerifyInviteDone}
                />
                {canManageWorkspace && (
                  <InviteLink workspace={currentWorkspace} />
                )}
              </Box>
              <Box>
                <Tabs variant="soft-rounded" size="sm">
                  <TabList mb={4}>
                    <Tab>
                      <Box mr={2}>
                        <FontAwesomeIcon icon={solid('people-group')} />
                      </Box>
                      <Trans>Active members ({memberCount})</Trans>
                    </Tab>
                    <Tab>
                      <Box mr={2}>
                        <FontAwesomeIcon icon={regular('envelope')} />
                      </Box>
                      <Trans>Invitations ({invitations.length})</Trans>
                    </Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel p={0} mt={0}>
                      <MembersList
                        workspace={currentWorkspace}
                        dateFormatterFn={normalizeDate}
                        canManageWorkspace={canManageWorkspace}
                        workspaceAdminCount={
                          workspaceData?.workspace?.adminCount
                        }
                      />
                    </TabPanel>
                    <TabPanel p={0} mt={0}>
                      <PendingInvitationsList
                        invitations={invitations}
                        dateFormatterFn={normalizeDate}
                        canManageWorkspace={canManageWorkspace}
                      />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Box>
            </Stack>
          </Box>
        </ModalBody>
        <Divider mt={6} />

        <ModalFooter>
          <Stack
            w="100%"
            direction="row"
            align="center"
            justifyContent="space-between"
          >
            <WorkspaceSubscriptionSummary memberCount={memberCount} />
            <Spacer />

            <ButtonGroup>
              <Button variant="solid" onClick={closeIfReady}>
                <Trans>Done</Trans>
              </Button>
            </ButtonGroup>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
