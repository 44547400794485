import { Heading } from '@chakra-ui/react'

export const FontPreviewHeading = ({
  fontFamily,
  fontWeight,
  isItalic,
  headingText,
}: {
  fontFamily: string
  fontWeight: string
  isItalic: boolean
  headingText?: React.ReactNode
}) => {
  return (
    <Heading
      fontFamily={`"${fontFamily}"`}
      noOfLines={1}
      // overriding the Heading line-height prevents the descenders (e.g. for `g` letters) from being cut off
      lineHeight="base"
      fontWeight={fontWeight}
      fontStyle={isItalic ? 'italic' : 'normal'}
    >
      {headingText ? headingText : fontFamily}
    </Heading>
  )
}
