import { useToast } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { useEffect } from 'react'

import { replaceState } from 'modules/history/history'
import { useUserContext, WORKSPACE_SETTINGS_QUERY_PARAM } from 'modules/user'
import { getExistingQueryParams } from 'utils/url'

export const useOpenWorkspaceSettingsOnLoad = ({
  openModal,
}: {
  openModal: () => void
}) => {
  const { currentWorkspace } = useUserContext()
  const toast = useToast()

  useEffect(() => {
    // Open the workspaceSettings modal on load if the query param is present
    const queryWorkspaceId =
      getExistingQueryParams()[WORKSPACE_SETTINGS_QUERY_PARAM]
    const currentWorkspaceId = currentWorkspace?.id
    if (!queryWorkspaceId || !currentWorkspaceId) return

    if (queryWorkspaceId === currentWorkspaceId) {
      openModal()
    } else {
      toast({
        title: <Trans>Workspace not found.</Trans>,
        position: 'top',
        id: 'workspace-not-found',
        status: 'error',
        isClosable: true,
        duration: 3000,
      })
    }
    replaceState({
      query: {
        ...getExistingQueryParams(),
        [WORKSPACE_SETTINGS_QUERY_PARAM]: undefined,
      },
      emitChange: false,
    })
  }, [toast, openModal, currentWorkspace])
}
