import {
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  Text,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'
import { memo } from 'react'

const CustomizeThemeMenuComponent = ({
  onCustomizeThemeClick,
}: {
  onCustomizeThemeClick: () => void
}) => {
  return (
    <Menu isLazy>
      <MenuButton
        as={IconButton}
        isRound
        size="xs"
        variant="ghost"
        colorScheme="blackAlpha"
        icon={<FontAwesomeIcon icon={regular('ellipsis')} />}
        onClick={(ev) => ev.stopPropagation()} // Prevents clicking the ... from changing the theme
      >
        <Trans>Actions</Trans>
      </MenuButton>

      <Portal>
        <MenuList
          zIndex="modal" // Necessary when this component is rendered inside a drawer
          onClick={(ev) => ev.stopPropagation()} // Prevents clicking a menu item from changing the theme
        >
          <MenuItem
            icon={<FontAwesomeIcon icon={regular('split')} fixedWidth />}
            onClick={onCustomizeThemeClick}
          >
            <Trans>Customize this theme</Trans>
          </MenuItem>
          <MenuDivider />
          <Box px={4} py={0} maxW={56}>
            <Text fontSize="sm" color="gray.600">
              <Trans>
                Duplicate this theme and edit a custom version for your
                workspace
              </Trans>
            </Text>
          </Box>
        </MenuList>
      </Portal>
    </Menu>
  )
}

export const CustomizeThemeMenu = memo(CustomizeThemeMenuComponent)
