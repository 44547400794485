import {
  Box,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Plural, t, Trans } from '@lingui/macro'

import { Font } from 'modules/api'
import {
  findClosestFontWeight,
  getSortedFontFileWeights,
  getStyleCount,
  shouldBeItalicStyle,
} from 'modules/fonts/utils/utils'

import { FontGridItemWrapper } from './FontGridItemWrapper'
import { FontPreviewHeading } from './FontPreviewHeader'

type FontTileProps = {
  font: Font
  onClick: () => void
  onEditClick: () => void
  onDeleteClick: () => void
}
export const FontTile = ({
  font,
  onClick,
  onEditClick,
  onDeleteClick,
}: FontTileProps) => {
  const styleCount = getStyleCount(font?.fontFiles || [])
  const sortedFontWeights = getSortedFontFileWeights(font.fontFiles || [])
  const headingFontWeight = findClosestFontWeight(sortedFontWeights, 700) || 700
  const isItalicHeading = shouldBeItalicStyle(font?.fontFiles)

  return (
    <Stack>
      <FontGridItemWrapper
        bg="white"
        justifyContent="flex-start"
        p={6}
        onClick={onClick}
      >
        <Stack maxW="100%">
          <FontPreviewHeading
            fontWeight={headingFontWeight.toString()}
            isItalic={isItalicHeading}
            fontFamily={font.name}
            headingText={font.name}
          />
          <Plural
            value={styleCount}
            one="# style"
            other="# styles"
            _0={
              <Text color="orange.600">
                <Box as="span" mr={1}>
                  <FontAwesomeIcon icon={solid('warning')} />
                </Box>
                <Trans># styles</Trans>
              </Text>
            }
          />
        </Stack>
      </FontGridItemWrapper>
      <Flex>
        <Text textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
          {font.name}
        </Text>
        <Spacer />
        <Menu>
          <MenuButton
            as={IconButton}
            isRound
            size="sm"
            variant="ghost"
            icon={<FontAwesomeIcon icon={regular('ellipsis-h')} />}
            aria-label={t`Font family actions`}
          />
          <MenuList>
            <MenuItem
              icon={<FontAwesomeIcon icon={regular('edit')} fixedWidth />}
              onClick={onEditClick}
            >
              <Trans>Edit</Trans>
            </MenuItem>
            <MenuDivider />
            <MenuItem
              color="red.500"
              icon={<FontAwesomeIcon icon={regular('trash')} fixedWidth />}
              onClick={onDeleteClick}
            >
              <Trans>Delete</Trans>
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Stack>
  )
}
