import { CheckIcon } from '@chakra-ui/icons'
import {
  Box,
  Flex,
  HStack,
  Spacer,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import { memo, useCallback, useRef } from 'react'

import { CreatedByPanel } from 'gamma_components/CreatedByPanel'
import { GetThemesDocument, useDuplicateThemeMutation } from 'modules/api'
import { formatDistanceToNowStrict } from 'modules/i18n/utils/date'
import { SegmentEvents, useAnalytics } from 'modules/segment'
import {
  ARCHIVED_THEME_NAME_REPLACE_REGEX,
  makeCleanCustomTheme,
  Theme,
  ThemeType,
} from 'modules/theming'
import { ArchivedThemePreviewMenu } from 'modules/theming/components/ArchivedThemePreviewMenu'
import { ThemePreviewThumbnail } from 'modules/theming/components/ThemePreviewThumbnail'
import { useUserContext } from 'modules/user'

import { CustomizeThemeMenu } from './CustomizeThemeMenu'
import { ThemePreviewMenu } from './ThemePreviewMenu'

type ThemePreviewProps = {
  theme: Theme
  isChecked?: boolean
  onThemeClicked?: (newTheme: Theme) => void
  onEditClick?: (themeToEdit?: Theme) => void
  variant?: 'outline' | 'ghost'
  type?: ThemeType
}

const ThemeName = ({ isChecked, name }) => {
  return (
    <HStack spacing={1} alignItems="flex-start">
      {isChecked && (
        <CheckIcon w={3} h="auto" mr={1} mt={1} color="trueblue.700" />
      )}
      <Text
        fontSize="sm"
        noOfLines={1}
        data-testid="theme-name"
        color={isChecked ? 'trueblue.700' : undefined}
      >
        {name}
      </Text>
    </HStack>
  )
}

export const ThemePreview = memo(
  ({
    theme,
    onThemeClicked,
    isChecked = false,
    onEditClick,
    variant = 'outline',
  }: ThemePreviewProps) => {
    const analytics = useAnalytics()
    const toast = useToast()
    const isGhost = variant === 'ghost'
    const ref = useRef<HTMLDivElement>(null)

    const openThemeEditorWithThisTheme = useCallback(
      (isCustomizing: boolean) => () => {
        if (!onEditClick) return
        if (isCustomizing) {
          onEditClick(makeCleanCustomTheme(theme, true))
        } else {
          onEditClick(theme)
        }
      },
      [onEditClick, theme]
    )

    const {
      isOpen: isArchiveThemeModalOpen,
      onOpen: onArchiveThemeModalOpen,
      onClose: onArchiveThemeModalClose,
    } = useDisclosure({ id: 'ArchiveThemeModalDisclosure' })

    const { user, currentWorkspace } = useUserContext()
    const [duplicateTheme] = useDuplicateThemeMutation()

    const displayName = theme.name.replace(
      ARCHIVED_THEME_NAME_REPLACE_REGEX,
      ''
    )
    const onDuplicate = useCallback(() => {
      if (!user || !currentWorkspace) return

      const { id, ...rest } = theme

      duplicateTheme({
        variables: { id },
        update: (cache, { data }) => {
          if (!data?.duplicateTheme) return

          // Write the newly created theme into the cache so it shows up in the
          // list without refreshing
          cache.writeQuery({
            query: GetThemesDocument,
            variables: {
              workspaceId: theme.workspaceId,
              archived: false,
            },
            data: {
              themes: [data.duplicateTheme],
            },
          })
        },
        optimisticResponse: {
          duplicateTheme: {
            id: 'temp',
            __typename: 'Theme',
            ...rest,
            name: `${displayName} (copy)`,
            archived: false,
            createdTime: new Date().toISOString(),
            updatedTime: new Date().toISOString(),
          },
        },
      })
        .then(({ data }) => {
          if (!data) return
          analytics?.track(SegmentEvents.THEME_DUPLICATED, {
            theme_id: data.duplicateTheme.id,
            source_theme_id: id,
            name: displayName,
          })
          analytics?.track(SegmentEvents.THEME_CREATED, {
            theme_id: data.duplicateTheme.id,
            name: data.duplicateTheme.name,
            type: 'duplicate',
          })
          toast({
            title: t`Theme ${data.duplicateTheme.name} has been saved`,
            status: 'success',
            duration: 3000,
            position: 'top',
            isClosable: true,
          })
        })
        .catch((err) => {
          console.error(`Couldn't duplicate theme ${theme.name} error: ${err}`)
          toast({
            title: t`Couldn't duplicate theme. ${err}`,
            status: 'error',
            duration: 3000,
            position: 'top',
            isClosable: false,
          })
        })
    }, [
      user,
      currentWorkspace,
      theme,
      duplicateTheme,
      displayName,
      analytics,
      toast,
    ])

    const renderCreatedBy = theme.workspaceId && !!theme.createdBy?.displayName

    const isClickable = Boolean(onThemeClicked)
    const hideEditMenu = !onEditClick

    return (
      <Box
        ref={ref}
        bgColor={isChecked ? 'trueblue.100' : isGhost ? undefined : 'linen.50'}
        textAlign="left"
        tabIndex={isClickable ? 0 : undefined}
        borderRadius="md"
        transitionProperty={isGhost ? 'common' : 'none'}
        transitionDuration="normal"
        outline="none"
        onClick={() => onThemeClicked?.(theme)}
        onKeyPress={(event) => {
          if (isClickable && event.key === 'Enter') {
            onThemeClicked?.(theme)
          }
        }}
        _hover={
          isClickable
            ? {
                shadow: isGhost || isChecked ? undefined : 'lg',
                bgColor: isChecked
                  ? 'trueblue.100'
                  : isGhost
                  ? 'trueblue.50'
                  : 'gray.100',
              }
            : undefined
        }
        _focus={{
          boxShadow: isChecked || !isClickable ? undefined : 'outline',
        }}
        shadow={isGhost ? undefined : 'md'}
        p={isGhost ? 2 : 0}
        data-theme-id={theme.id}
        data-testid={isChecked ? 'current-theme-preview' : undefined}
        cursor={isClickable ? 'pointer' : undefined}
      >
        <ThemePreviewThumbnail theme={theme} minH="9em" maxH="9em" />
        <Flex align="baseline" mt={1} direction="column" p={isGhost ? 0 : 2}>
          {renderCreatedBy && (
            <Flex my={2}>
              <ThemeName name={displayName} isChecked={isChecked} />
            </Flex>
          )}
          <HStack alignItems="flex-start" w="100%">
            {renderCreatedBy ? (
              <CreatedByPanel
                createdByYou={theme.createdBy?.id === user?.id}
                createdByName={theme.createdBy?.displayName!}
                createdByProfileImageUrl={theme.createdBy?.profileImageUrl}
                timestampDescription={
                  theme.updatedTime && (
                    <Trans>
                      Updated {formatDistanceToNowStrict(theme.updatedTime)} ago
                    </Trans>
                  )
                }
              />
            ) : (
              <ThemeName name={displayName} isChecked={isChecked} />
            )}
            <Spacer />
            {hideEditMenu ? null : theme.workspaceId && !theme.archived ? (
              <ThemePreviewMenu
                theme={theme}
                openThemeEditorWithThisTheme={openThemeEditorWithThisTheme(
                  false
                )}
                onDuplicate={onDuplicate}
                onArchiveThemeModalOpen={onArchiveThemeModalOpen}
                isArchiveThemeModalOpen={isArchiveThemeModalOpen}
                onArchiveThemeModalClose={onArchiveThemeModalClose}
              />
            ) : theme.workspaceId && theme.archived === true ? (
              <ArchivedThemePreviewMenu theme={theme} />
            ) : (
              <CustomizeThemeMenu
                onCustomizeThemeClick={openThemeEditorWithThisTheme(true)}
              />
            )}
          </HStack>
        </Flex>
      </Box>
    )
  }
)

ThemePreview.displayName = 'ThemePreview'
