import {
  Avatar,
  Box,
  Button,
  Flex,
  HStack,
  Skeleton,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'
import { useCallback, useMemo, useState } from 'react'

import {
  ExistingWorkspace,
  SortDirection,
  useGetWorkspaceMembersQuery,
  User,
  useRemoveMemberMutation,
  useUpdateMemberRoleMutation,
  WorkspaceMembership,
  WorkspaceMembersSortField,
  WorkspaceRole,
} from 'modules/api'
import { useUserContext } from 'modules/user'
import { workspaceRoleMap } from 'modules/workspaces/constants'
import { EditMemberAction } from 'modules/workspaces/types'
import { formatNameWithEmail } from 'modules/workspaces/utils'
import { Deferred } from 'utils/deferred'

import { ConfirmMemberEditModal } from './ConfirmMemberEditModal'
import { EditMemberMenu } from './EditMemberMenu'

const MEMBERS_PAGE_SIZE = 50

const findCurrentWorkspaceMembership = (
  id: ExistingWorkspace['id'],
  workspaceMemberships?: WorkspaceMembership[]
): WorkspaceMembership | undefined => {
  return (workspaceMemberships || []).find((w) => w.workspace?.id === id)
}

type MembersListProps = {
  workspace: ExistingWorkspace
  dateFormatterFn: (date: string) => string
  canManageWorkspace: boolean
  workspaceAdminCount?: number
}

export const MembersList = ({
  workspace,
  dateFormatterFn,
  canManageWorkspace,
  workspaceAdminCount,
}: MembersListProps) => {
  const [sortField, setSortField] = useState<WorkspaceMembersSortField>(
    WorkspaceMembersSortField.DisplayName
  )
  const [sortDirection, setSortDirection] = useState<SortDirection>(
    SortDirection.Asc
  )
  const [isFetchingMore, setIsFetchingMore] = useState<boolean>(false)
  const variables = {
    id: workspace?.id,
    first: MEMBERS_PAGE_SIZE,
    sortBy: {
      field: sortField,
      direction: sortDirection,
    },
  }
  const { data, loading, fetchMore } = useGetWorkspaceMembersQuery({
    variables,
    skip: !workspace?.id,
  })
  const [updateMemberRole, { loading: isUpdatingMemberRole }] =
    useUpdateMemberRoleMutation()
  const [removeMember, { loading: isRemovingMember }] =
    useRemoveMemberMutation()
  const [memberIdLoading, setMemberIdLoading] = useState<string | null>(null)

  const pageInfo = data?.workspaceMembers?.pageInfo
  const showPaginationControls =
    !loading && pageInfo?.hasNextPage && pageInfo?.endCursor
  const members = data?.workspaceMembers.edges.map((edge) => edge.node)
  const { user, refetch } = useUserContext()
  const workspaceId = workspace.id
  const toast = useToast()
  const [userToConfirm, setUserToConfirm] = useState<User | null>(null)
  const [confirmationAction, setConfirmationAction] =
    useState<EditMemberAction | null>(null)
  const [confirmedDef, setConfirmedDef] = useState<Deferred<void>>(
    new Deferred()
  )

  const { isOpen, onOpen, onClose } = useDisclosure()

  const adminCount = useMemo(
    () =>
      workspaceAdminCount ??
      (members?.reduce((count, member) => {
        const currentWorkspaceMembership = findCurrentWorkspaceMembership(
          workspaceId,
          member.workspaceMemberships
        )
        if (
          currentWorkspaceMembership &&
          currentWorkspaceMembership.role === WorkspaceRole.Admin
        ) {
          count++
        }
        return count
      }, 0) ||
        0),
    [members, workspaceId, workspaceAdminCount]
  )

  const confirmAction = useCallback(async () => {
    onOpen()
    return confirmedDef.promise
  }, [confirmedDef, onOpen])

  const resetConfirmation = useCallback(() => {
    setUserToConfirm(null)
    setConfirmationAction(null)
  }, [])

  const doMemberRoleUpdate = useCallback(
    (member: User, option: WorkspaceRole) => {
      setMemberIdLoading(member.id)
      updateMemberRole({
        variables: {
          workspaceId: workspace.id,
          userId: member.id,
          role: option,
        },
        refetchQueries: ['GetWorkspace'],
      })
        .then(() => {
          refetch?.()
          const description =
            option === WorkspaceRole.Admin ? (
              <Trans>
                Workspace role for {member.displayName} ({member.email}) updated
                to Admin
              </Trans>
            ) : (
              <Trans>
                Workspace role for {member.displayName} ({member.email}) updated
                to Member
              </Trans>
            )
          toast({
            description,
            status: 'success',
            position: 'top',
            duration: 5000,
            isClosable: true,
          })
        })
        .catch(() => {
          toast({
            description: (
              <Trans>
                Unable to update workspace role for {member.displayName} (
                {member.email})
              </Trans>
            ),
            status: 'error',
            position: 'top',
            duration: 5000,
            isClosable: true,
          })
        })
        .finally(() => {
          setMemberIdLoading(null)
        })
    },
    [refetch, toast, updateMemberRole, workspace.id]
  )

  const handleMemberRoleChange = useCallback(
    (member?: User) => async (option: WorkspaceRole) => {
      if (!member || !canManageWorkspace) {
        return
      }
      if (user?.id === member.id && option === WorkspaceRole.Member) {
        if (adminCount <= 1) {
          return
        }
        setUserToConfirm(member)
        setConfirmationAction('giveUpAdminAccess')
        confirmAction()
          .then(() => {
            doMemberRoleUpdate(member, option)
          })
          .catch(() => {
            console.debug('[handleMemberRoleChange] User cancelled action')
          })
          .finally(() => resetConfirmation())
      } else {
        doMemberRoleUpdate(member, option)
      }
    },
    [
      adminCount,
      canManageWorkspace,
      doMemberRoleUpdate,
      confirmAction,
      resetConfirmation,
      user?.id,
    ]
  )

  const handleRemoveMember = useCallback(
    (member: User) => () => {
      if (member.id === user?.id) {
        return
      }
      const emailWithName = formatNameWithEmail(
        member.displayName,
        member.email
      )
      setUserToConfirm(member)
      setConfirmationAction('remove')
      confirmAction()
        .then(() => {
          setMemberIdLoading(member.id)
          removeMember({
            variables: { workspaceId, userId: member.id },
            refetchQueries: ['GetWorkspace', 'GetWorkspaceMembers'],
          })
            .then(() => {
              refetch?.()
              toast({
                description: (
                  <Trans>
                    {emailWithName} was successfully removed from your
                    workspace.
                  </Trans>
                ),
                status: 'success',
                position: 'top',
                duration: 5000,
                isClosable: true,
              })
            })
            .catch(() => {
              toast({
                description: (
                  <Trans>
                    Unable to update workspace role for {emailWithName}
                  </Trans>
                ),
                status: 'error',
                position: 'top',
                duration: 5000,
                isClosable: true,
              })
            })
        })
        .finally(() => {
          setMemberIdLoading(null)
          resetConfirmation()
        })
    },
    [
      removeMember,
      confirmAction,
      workspaceId,
      user?.id,
      refetch,
      resetConfirmation,
      toast,
    ]
  )

  return (
    <>
      <TableContainer>
        <Table
          variant="simple"
          colorScheme="blackAlpha"
          size="sm"
          whiteSpace="normal"
        >
          <Thead>
            <Tr>
              <Th>
                <Button
                  ml={-3}
                  size="sm"
                  color="gray.500"
                  variant="ghost"
                  colorScheme="blackAlpha"
                  aria-label="sort"
                  onClick={() => {
                    setSortField(WorkspaceMembersSortField.DisplayName)
                    setSortDirection((prev) =>
                      prev === SortDirection.Asc
                        ? SortDirection.Desc
                        : SortDirection.Asc
                    )
                  }}
                  rightIcon={
                    <Box
                      color={
                        sortField === WorkspaceMembersSortField.DisplayName
                          ? 'gray.700'
                          : 'gray.300'
                      }
                    >
                      <FontAwesomeIcon
                        icon={
                          sortField === WorkspaceMembersSortField.DisplayName
                            ? sortDirection === 'asc'
                              ? solid('caret-down')
                              : solid('caret-up')
                            : solid('sort')
                        }
                      />
                    </Box>
                  }
                >
                  <Trans>Name</Trans>
                </Button>
              </Th>
              <Th>
                <Button
                  ml={-3}
                  size="sm"
                  color="gray.500"
                  variant="ghost"
                  colorScheme="blackAlpha"
                  aria-label="sort"
                  onClick={() => {
                    setSortField(WorkspaceMembersSortField.CreatedTime)
                    setSortDirection((prev) =>
                      prev === SortDirection.Asc
                        ? SortDirection.Desc
                        : SortDirection.Asc
                    )
                  }}
                  rightIcon={
                    <Box
                      color={
                        sortField === WorkspaceMembersSortField.CreatedTime
                          ? 'gray.700'
                          : 'gray.300'
                      }
                    >
                      <FontAwesomeIcon
                        icon={
                          sortField === WorkspaceMembersSortField.CreatedTime
                            ? sortDirection === 'asc'
                              ? solid('caret-down')
                              : solid('caret-up')
                            : solid('sort')
                        }
                      />
                    </Box>
                  }
                >
                  <Trans>Join date</Trans>
                </Button>
              </Th>
              <Th>
                <Trans>Role</Trans>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {loading && (
              <Tr p={0}>
                <Td colSpan={2} p={0}>
                  <Stack spacing={2}>
                    <Skeleton h={8} />
                    <Skeleton h={8} />
                    <Skeleton h={8} />
                  </Stack>
                </Td>
              </Tr>
            )}

            {members &&
              members.map((member, i) => {
                const isLastRow = i === members.length - 1
                const currentWorkspaceMembership =
                  findCurrentWorkspaceMembership(
                    workspace.id,
                    member.workspaceMemberships
                  )
                const createdTime = currentWorkspaceMembership?.createdTime
                const formattedCreatedTime = dateFormatterFn(createdTime)
                const memberRole = currentWorkspaceMembership?.role
                const isAdmin = memberRole === WorkspaceRole.Admin
                const memberRoleTitle = memberRole
                  ? workspaceRoleMap[memberRole].title
                  : ''

                const isCurrentMemberUpdating = memberIdLoading === member.id
                const isSelf = member.id === user?.id

                return (
                  <Tr key={member.id}>
                    <Td borderBottom={isLastRow ? '0 none' : undefined}>
                      <UserAvatarBlock user={member} isSelf={isSelf} />
                    </Td>
                    <Td
                      borderBottom={isLastRow ? '0 none' : undefined}
                      whiteSpace="nowrap"
                    >
                      {formattedCreatedTime}
                    </Td>
                    <Td borderBottom={isLastRow ? '0 none' : undefined} w={32}>
                      {isCurrentMemberUpdating ? (
                        <Box textAlign="center">
                          <Spinner size="xs" />
                        </Box>
                      ) : canManageWorkspace && memberRole ? (
                        <EditMemberMenu
                          selected={memberRole}
                          isMenuDisabled={
                            isUpdatingMemberRole || isRemovingMember
                          }
                          options={[WorkspaceRole.Admin, WorkspaceRole.Member]}
                          onSelect={handleMemberRoleChange(member)}
                          disabledOptions={
                            isAdmin && adminCount <= 1
                              ? [
                                  {
                                    disabledOption: WorkspaceRole.Member,
                                    reason: `A workspace must have at least one admin`,
                                  },
                                ]
                              : []
                          }
                          disabledDeleteReason={
                            // TODO: when we add support for leaving, this should be disabled only if there is one admin with the reason:
                            // 'A workspace must have at least one admin'
                            isSelf ? `You cannot remove yourself` : undefined
                          }
                          onDeleteClick={handleRemoveMember(member)}
                        />
                      ) : (
                        memberRoleTitle
                      )}
                    </Td>
                  </Tr>
                )
              })}
          </Tbody>
        </Table>
      </TableContainer>
      {showPaginationControls && (
        <Flex w="100%" justify="center" align="center" mt={3}>
          <Button
            isLoading={isFetchingMore}
            size="sm"
            onClick={() => {
              setIsFetchingMore(true)
              fetchMore({
                variables: {
                  after: pageInfo?.endCursor,
                },
              }).finally(() => {
                setIsFetchingMore(false)
              })
            }}
          >
            <Trans>Load more</Trans>
          </Button>
        </Flex>
      )}
      {userToConfirm && confirmationAction && (
        <ConfirmMemberEditModal
          isOpen={isOpen}
          onClose={() => {
            onClose()
            confirmedDef.reject()
            setConfirmedDef(new Deferred())
          }}
          onCancelClick={() => {
            onClose()
            confirmedDef.reject()
            setConfirmedDef(new Deferred())
          }}
          onConfirmClick={async () => {
            onClose()
            confirmedDef.resolve()
            setConfirmedDef(new Deferred())
          }}
          userToConfirm={userToConfirm}
          action={confirmationAction}
          workspace={workspace}
        />
      )}
    </>
  )
}

type UserAvatarBlock = {
  user: User
  isSelf: boolean
}
const UserAvatarBlock = ({ user, isSelf }: UserAvatarBlock) => {
  const { displayName, profileImageUrl, email } = user
  return (
    <HStack>
      <Avatar size="sm" name={displayName} src={profileImageUrl} />
      <Stack spacing={0}>
        <Text>
          {displayName}
          {isSelf ? (
            <>
              {' '}
              <Trans>(you)</Trans>
            </>
          ) : (
            ''
          )}
        </Text>
        <HStack mt={1}>
          <Text fontSize="xs" color="gray.500">
            {email}
          </Text>
        </HStack>
      </Stack>
    </HStack>
  )
}
