import { useRouter } from 'next/dist/client/router'

import { withHomeLayout } from 'layout'
import { useLastHomeUrlListener } from 'modules/routing/hooks'
import { useUserContext } from 'modules/user'
import { useRedirectLoggedOutUser } from 'modules/user/hooks/useRedirectLoggedOutUser'
import { Home, HomeSkeleton } from 'sections/home_v2'
import { useSSRMounted } from 'utils/hooks'

export const HomePage = () => {
  const { push } = useRouter()
  const { userStatus } = useUserContext()
  const isMounted = useSSRMounted()

  useLastHomeUrlListener(true)

  useRedirectLoggedOutUser()

  if (!isMounted) {
    // SSR Hydration
    return <HomeSkeleton key="home-skeleton" />
  }

  return <Home key={'home-v2'} />
}

export default withHomeLayout(HomePage)
