import { SearchIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Flex,
  InputGroup,
  InputLeftElement,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteRefMethods,
} from '@choc-ui/chakra-autocomplete'
import { GammaTooltip } from '@gamma-app/ui'
import { Plural, t, Trans } from '@lingui/macro'
import { validate as validateEmail } from 'email-validator'
import { motion } from 'framer-motion'
import { uniqBy } from 'lodash'
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { ExistingWorkspace, useInviteMembersMutation } from 'modules/api'
import { UnsentInvitationsModal } from 'modules/invitations/UnsentInvitationsModal'
import { SearchBarCollaboratorTag } from 'modules/sharing/components/SharePanel/SearchBarTag'
import {
  getWorkspaceAdminDisplayName,
  getWorkspaceAdminDisplayNamePlural,
} from 'modules/workspaces/constants'
import { Deferred } from 'utils/deferred'

import {
  ConfirmAddPaidMemberModal,
  useConfirmAddPaidMemberModal,
} from './ConfirmAddPaidMemberModal'

const MotionBox = motion(Box)
interface UserToInvite {
  email: string
}

type InviteByEmailProps = {
  workspace: ExistingWorkspace
  canManageWorkspace: boolean
  setDoneFn: Dispatch<SetStateAction<() => Promise<void>>>
}
export const InviteByEmail = ({
  workspace,
  canManageWorkspace,
  setDoneFn,
}: InviteByEmailProps) => {
  const toast = useToast()
  const autocompleteRef = useRef<AutoCompleteRefMethods>()
  const inputRef = useRef<HTMLInputElement>(null)
  const [selectedItems, setSelectedItems] = useState<UserToInvite[]>([])
  const [doneDeferred, setDoneDeferred] = useState<Deferred<void>>(
    new Deferred()
  )
  const [inputValue, setInputValue] = useState('')
  const [inviteMembers, { loading }] = useInviteMembersMutation()

  const {
    isOpen: isUnsentInvitationsModalOpen,
    onOpen: onUnsentInvitationsModalOpen,
    onClose: onUnsentInvitationsModalClose,
  } = useDisclosure({ id: 'confirm-unsent-invitations' })

  const {
    isConfirmAddPaidMemberModalOpen,
    onConfirmAddPaidMemberClick,
    onConfirmAddPaidMemberClose,
    showConfirmPaidUserModal,
  } = useConfirmAddPaidMemberModal()

  // Include current input value in case the user forgot to submit the text input
  const currentValidItems = useMemo(
    () =>
      selectedItems
        .concat({ email: inputValue.trim() })
        .filter((u) => ('email' in u ? validateEmail(u.email || '') : true)),
    [inputValue, selectedItems]
  )

  const showControls = Boolean(selectedItems.length)
  const hasSomeValidItems = Boolean(currentValidItems.length)

  useEffect(() => {
    // So that the parent WorkspaceSettingsModal knows what function to call before closing
    setDoneFn(() => () => {
      if (hasSomeValidItems) {
        onUnsentInvitationsModalOpen()
      } else {
        doneDeferred.resolve()
      }
      return doneDeferred.promise
    })
  }, [hasSomeValidItems, doneDeferred, onUnsentInvitationsModalOpen, setDoneFn])

  const shouldDisableInput = loading || !canManageWorkspace

  const inviteSelectedUsers = useCallback(async () => {
    if (currentValidItems.length === 0) return

    return inviteMembers({
      variables: {
        workspaceId: workspace.id,
        invitees: currentValidItems,
      },
      refetchQueries: ['GetWorkspace'],
    }).then(() => {
      const title = (
        <Plural
          value={currentValidItems.length}
          one="Invitation sent"
          other="Invitations sent"
        />
      )
      toast({
        title,
        description: (
          <Text>
            {currentValidItems.map((u) => (
              <Text key={u.email}>{u.email}</Text>
            ))}
          </Text>
        ),
        status: 'success',
        position: 'top',
        isClosable: true,
        duration: 9000,
      })
    })
  }, [currentValidItems, inviteMembers, workspace.id, toast])

  const onInviteClick = useCallback(async () => {
    return showConfirmPaidUserModal()
      .then(inviteSelectedUsers)
      .then(() => {
        setSelectedItems([])
        setInputValue('')
        autocompleteRef.current?.resetItems(true)
        doneDeferred.resolve()
        setDoneDeferred(new Deferred())
      })
      .catch((e) => {
        console.warn(e)
      })
  }, [showConfirmPaidUserModal, inviteSelectedUsers, doneDeferred])

  const onRemoveClick = useCallback((email: string) => {
    setSelectedItems((prev) => {
      return prev.filter((u) => u.email !== email)
    })
  }, [])

  const addToSelectedItems = useCallback((itemOrItems: UserToInvite[]) => {
    setSelectedItems((prev) => {
      return uniqBy([...prev, ...itemOrItems], 'email')
    })
  }, [])

  const WORKSPACE_ADMIN_DISPLAY_NAME_PLURAL =
    getWorkspaceAdminDisplayNamePlural()
  const WORKSPACE_ADMIN_DISPLAY_NAME = getWorkspaceAdminDisplayName()
  return (
    <Box my={4} mb={6}>
      <Text mb={2} fontSize="sm" color="gray.500">
        <Trans>Invite by email address</Trans>
      </Text>
      <Flex direction="row" w="100%" alignItems="flex-start">
        <InputGroup w="100%" justifyContent="center" flex={1}>
          <AutoComplete
            ref={autocompleteRef}
            values={selectedItems}
            maxSelections={100}
            maxSuggestions={20}
            creatable={true}
            multiple={true}
            freeSolo={true}
            suggestWhenEmpty={false}
            openOnFocus={true}
            filter={(_query: string, itemValue: string) => {
              return !selectedItems.find((s) => s.email === itemValue)
            }}
            // @ts-ignore
            onSelectOption={({ item }: OnSelectOptionArgs) => {
              addToSelectedItems([{ email: item.value }])
              setInputValue('')
            }}
            onTagRemoved={(value) => {
              setSelectedItems((prev) =>
                [...prev].filter((c) => {
                  return c.email !== value
                })
              )
            }}
          >
            <InputLeftElement
              pointerEvents="none"
              color="gray.300"
              h="100%"
              flexDirection="column"
            >
              {/**
               * Use a flex 1 div to slam the Search Icon to the bottom.
               * Necessary until this library supports InputGroup inside AutoCompleteInput
               * See https://github.com/anubra266/choc-autocomplete/issues/63
               */}
              <Flex flex={1} />
              <Flex h={10} py={4} alignItems="center">
                <SearchIcon w={10} />
              </Flex>
            </InputLeftElement>
            <GammaTooltip
              placement="top-start"
              label={
                <Trans>
                  Only {WORKSPACE_ADMIN_DISPLAY_NAME_PLURAL} can invite others.
                  Ask your {WORKSPACE_ADMIN_DISPLAY_NAME} for help inviting
                  others, or have them make you an{' '}
                  {WORKSPACE_ADMIN_DISPLAY_NAME} too.
                </Trans>
              }
              isDisabled={canManageWorkspace}
            >
              <Box>
                <AutoCompleteInput
                  ref={inputRef}
                  data-testid="autocomplete-collaborators-input"
                  cursor={shouldDisableInput ? 'not-allowed' : undefined}
                  disabled={shouldDisableInput}
                  w={'100%'}
                  pl={5}
                  wrapStyles={{
                    px: 2,
                  }}
                  fontSize="md"
                  placeholder={t`Add people`}
                  transition="width 1s ease-in-out"
                  value={inputValue}
                  onKeyDown={(e) => {
                    const { key } = e
                    if (key === 'Enter' && inputValue.length === 0) {
                      // No op if empty string
                      e.preventDefault()
                      return
                    } else if (
                      key === 'Backspace' &&
                      inputValue.length === 0 &&
                      selectedItems.length > 0
                    ) {
                      // Delete the last item
                      const lastItem = selectedItems.slice(-1).pop()
                      if (lastItem) {
                        autocompleteRef.current?.removeItem(lastItem.email)
                      }
                    }
                  }}
                  onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => {
                    e.preventDefault()
                    e.stopPropagation()
                    const value = e.clipboardData.getData('Text')
                    const parts = value.trim().split(/[,|;\s]+/)
                    addToSelectedItems([...parts.map((email) => ({ email }))])
                    setInputValue('')
                  }}
                  onChange={({
                    target,
                  }: React.ChangeEvent<HTMLInputElement>) => {
                    const { value } = target
                    setInputValue(value)
                  }}
                >
                  {selectedItems.map((selectedItem, idx) => {
                    return (
                      <SearchBarCollaboratorTag
                        idx={idx}
                        selectedCollaborator={selectedItem}
                        handleRemoveClick={() => {
                          onRemoveClick(selectedItem.email)
                        }}
                        key={selectedItem.email}
                      />
                    )
                  })}
                </AutoCompleteInput>
              </Box>
            </GammaTooltip>
          </AutoComplete>
        </InputGroup>
        <MotionBox
          overflowX="hidden"
          transition="slow"
          animate={{
            maxWidth: showControls ? '240px' : '0px',
          }}
        >
          <Box ml={2}>
            <Button
              variant="solid"
              onClick={onInviteClick}
              isDisabled={!hasSomeValidItems}
              isLoading={false}
              data-testid="add-selected-items"
            >
              <Trans>Invite</Trans>
            </Button>
          </Box>
        </MotionBox>
      </Flex>
      <UnsentInvitationsModal
        invitationCount={currentValidItems.length}
        isOpen={isUnsentInvitationsModalOpen}
        onClose={() => {
          onUnsentInvitationsModalClose()
          doneDeferred.reject()
          setDoneDeferred(new Deferred())
        }}
        onDiscardClick={() => {
          onUnsentInvitationsModalClose()
          doneDeferred.resolve()
          setDoneDeferred(new Deferred())
        }}
        onSendInvitationsClick={() => {
          onUnsentInvitationsModalClose()
          onInviteClick()
        }}
      />
      <ConfirmAddPaidMemberModal
        isOpen={isConfirmAddPaidMemberModalOpen}
        newMemberCount={currentValidItems.length}
        onConfirmClick={onConfirmAddPaidMemberClick}
        onClose={onConfirmAddPaidMemberClose}
        onCancelClick={onConfirmAddPaidMemberClose}
      />
    </Box>
  )
}
