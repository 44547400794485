export const AI_WIZARD_PATH = '/generate'
export const AI_WIZARD_SOURCE_QUERY_VALUE = 'ai-generate' // used in query param to indicate that the doc was created from the AI wizard
export const AI_ADVANCED_MODE_PATH = '/generate-advanced'
export const TOP_BAR_SPACING = 2
export const TOP_BAR_Z_INDEX = 2

export const MAX_INPUT_TOKENS = 400

export const stepToNumberMap = {
  DESIGNER__HELLO: 0,
  USER__OUTPUT_FORMAT_INPUT: 1,
  DESIGNER__TOPIC: 2,
  USER__TOPIC_INPUT: 3,
  DESIGNER__OUTLINE: 4,
  USER__OUTLINE_INPUT: 5,
}
