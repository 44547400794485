import {
  Box,
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { t, Trans } from '@lingui/macro'
import { sortBy } from 'lodash'
import { useCallback, useState } from 'react'

import {
  useRevokeWorkspaceInvitationMutation,
  WorkspaceInvitation,
} from 'modules/api'
import { getWorkspaceAdminDisplayNamePlural } from 'modules/workspaces/constants'

export const PendingInvitationsList = ({
  invitations,
  dateFormatterFn,
  canManageWorkspace,
}: {
  invitations: WorkspaceInvitation[]
  dateFormatterFn: (date: string) => string
  canManageWorkspace: boolean
}) => {
  const [sortField, setSortField] = useState<'createdTime' | 'email'>('email')
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc')
  const [revokeInvitation] = useRevokeWorkspaceInvitationMutation()
  const toast = useToast()
  const WORKSPACE_ADMIN_DISPLAY_NAME_PLURAL =
    getWorkspaceAdminDisplayNamePlural()

  const handleRevokeInvitation = useCallback(
    (invitationId: string) => {
      revokeInvitation({
        variables: {
          id: invitationId,
        },
      })
        .then(() => {
          toast({
            title: t`Invitation revoked`,
            status: 'success',
            duration: 3000,
            isClosable: true,
            position: 'bottom',
          })
        })
        .catch((e) => {
          console.error(e)
          toast({
            title: t`Error revoking invitation: ${e.message}`,
            status: 'error',
            isClosable: true,
            position: 'bottom',
          })
        })
    },
    [revokeInvitation, toast]
  )

  const sortedInvitations =
    sortDirection === 'asc'
      ? sortBy(invitations, sortField)
      : sortBy(invitations, sortField).reverse()

  if (sortedInvitations.length === 0) {
    return (
      <Box p={4} background="gray.50">
        <Text my={8} textAlign="center">
          <Trans>No pending invitations.</Trans>
        </Text>
      </Box>
    )
  }
  return (
    <TableContainer>
      <Table
        variant="simple"
        colorScheme="blackAlpha"
        size="sm"
        whiteSpace="normal"
      >
        <Thead>
          <Tr>
            <Th>
              <Button
                ml={-3}
                size="sm"
                color="gray.500"
                variant="ghost"
                colorScheme="blackAlpha"
                aria-label="sort"
                onClick={() => {
                  setSortField('email')
                  setSortDirection((prev) => (prev === 'asc' ? 'desc' : 'asc'))
                }}
                rightIcon={
                  <Box color={sortField === 'email' ? 'gray.700' : 'gray.300'}>
                    <FontAwesomeIcon
                      icon={
                        sortField === 'email'
                          ? sortDirection === 'asc'
                            ? solid('caret-down')
                            : solid('caret-up')
                          : solid('sort')
                      }
                    />
                  </Box>
                }
              >
                <Trans>Email</Trans>
              </Button>
            </Th>
            <Th>
              {' '}
              <Button
                ml={-3}
                size="sm"
                color="gray.500"
                variant="ghost"
                colorScheme="blackAlpha"
                aria-label="sort"
                onClick={() => {
                  setSortField('createdTime')
                  setSortDirection((prev) => (prev === 'asc' ? 'desc' : 'asc'))
                }}
                rightIcon={
                  <Box color={sortField === 'email' ? 'gray.700' : 'gray.300'}>
                    <FontAwesomeIcon
                      icon={
                        sortField === 'createdTime'
                          ? sortDirection === 'asc'
                            ? solid('caret-down')
                            : solid('caret-up')
                          : solid('sort')
                      }
                    />
                  </Box>
                }
              >
                <Trans>Last sent</Trans>
              </Button>
            </Th>
            <Th>
              <Trans>Revoke</Trans>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {sortedInvitations &&
            sortedInvitations?.length > 0 &&
            sortedInvitations.map((invitation, i) => {
              return (
                <Tr key={invitation.id}>
                  <Td>{invitation.email}</Td>
                  <Td>{dateFormatterFn(invitation.createdTime)}</Td>
                  <Td>
                    <GammaTooltip
                      placement="top"
                      label={
                        <Trans>
                          Only {WORKSPACE_ADMIN_DISPLAY_NAME_PLURAL} can revoke
                          invitations.
                        </Trans>
                      }
                      shouldWrapChildren={true}
                      isDisabled={canManageWorkspace}
                    >
                      <Button
                        w={20}
                        variant="plain"
                        color="plain"
                        size="sm"
                        isDisabled={!canManageWorkspace}
                        onClick={() => {
                          handleRevokeInvitation(invitation.id)
                        }}
                      >
                        <Trans>Revoke</Trans>
                      </Button>
                    </GammaTooltip>
                  </Td>
                </Tr>
              )
            })}
        </Tbody>
      </Table>
    </TableContainer>
  )
}
