import {
  Button,
  ButtonGroup,
  Divider,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { useMemo } from 'react'

import { Font, FontFile, FontFileType } from 'modules/api'

import { getWeightsMap } from '../constants'
import { EmptyFamilyWarning } from './EmptyFamilyWarning'
import { FontFileExtensionBadge } from './FontFileExtensionBadge'
import { FontPreviewHeading } from './FontPreviewHeader'

type FontPreviewModalProps = {
  font: Font
  onClose: () => void
  onEditClick: () => void
  onDeleteClick: () => void
}

const removeDuplicatesAndSort = (fontFiles: FontFile[]) => {
  const newFiles = fontFiles.reduce<
    (FontFile & { fileTypes?: FontFileType[] })[]
  >((result, currFile) => {
    const sameStyleFontIndex = result.findIndex(
      (f) => f.isItalic === currFile.isItalic && f.weight === currFile.weight
    )
    // If there are no fonts with same style, add currFile to result
    if (sameStyleFontIndex === -1) {
      return [...result, currFile]
    }
    // If same style font also has the same file type, this is a duplicate font file, so don't add
    if (result[sameStyleFontIndex].fileType === currFile.fileType) {
      return result
    }
    // Otherwise, combine this same style font with the current file so we only display one row in the preview
    const newFontFile = {
      ...currFile,
      fileTypes: [currFile.fileType, result[sameStyleFontIndex].fileType],
    }
    return [
      ...result.slice(0, sameStyleFontIndex),
      newFontFile,
      ...result.slice(sameStyleFontIndex + 1),
    ]
  }, [])

  // sort files by weight, then by style
  const sortedFiles = [...newFiles]
  sortedFiles.sort((a, b) => {
    if (a.weight !== b.weight) {
      return a.weight - b.weight
    } else if (a.isItalic !== b.isItalic) {
      return a.isItalic === false ? -1 : 1
    } else {
      return 0
    }
  })
  return sortedFiles
}

export const FontPreviewModal = ({
  font,
  onClose,
  onEditClick,
  onDeleteClick,
}: FontPreviewModalProps) => {
  const sortedFontFiles = useMemo(
    () => removeDuplicatesAndSort(font.fontFiles || []),
    [font.fontFiles]
  )

  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      size="3xl"
      // This is needed to allow the UpsellModalPro to be scrollable
      blockScrollOnMount={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{font.name}</ModalHeader>
        <ModalCloseButton />
        <Divider mb="4" />
        <ModalBody>
          <VStack mb={4} spacing={6} align="stretch">
            {font.fontFiles?.length === 0 && <EmptyFamilyWarning />}
            {sortedFontFiles.map((file) => {
              const hasOTF = Boolean(
                file.fileType === FontFileType.Otf ||
                  file.fileTypes?.includes(FontFileType.Otf)
              )
              const hasTTF = Boolean(
                file.fileType === FontFileType.Ttf ||
                  file.fileTypes?.includes(FontFileType.Ttf)
              )
              return (
                <FontFileRow
                  key={file.id}
                  fontName={font.name}
                  weight={file.weight}
                  isItalic={file.isItalic}
                  hasOTF={hasOTF}
                  hasTTF={hasTTF}
                />
              )
            })}
          </VStack>
        </ModalBody>
        <ModalFooter justifyContent="space-between">
          <Button variant="ghost" colorScheme="red" onClick={onDeleteClick}>
            <Trans>Delete</Trans>
          </Button>

          <ButtonGroup>
            <Button variant="plain" onClick={onEditClick}>
              <Trans>Edit</Trans>
            </Button>
            <Button variant="solid" onClick={onClose}>
              <Trans>Done</Trans>
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

type FontFileRowProps = {
  fontName: string
  weight: number
  isItalic: boolean
  hasOTF: boolean
  hasTTF: boolean
}

const FontFileRow = ({
  fontName,
  weight,
  isItalic,
  hasOTF,
  hasTTF,
}: FontFileRowProps) => {
  const style = (
    <>
      {getWeightsMap()[weight]} {isItalic ? <Trans>Italic</Trans> : ''}
      {/* E.g. Black Italic */}
    </>
  )
  const fontNameWithStyle = (
    <>
      {fontName} {style}
    </>
  )
  const weightAndStyleName = (
    <>
      {weight} - {style}
    </>
  )

  return (
    <Flex justify="space-between" gap="10px">
      <Stack spacing={2}>
        <Text color="gray.600">{weightAndStyleName}</Text>
        <FontPreviewHeading
          fontFamily={fontName}
          fontWeight={weight.toString()}
          isItalic={isItalic}
          headingText={fontNameWithStyle}
        />
      </Stack>
      <HStack align="center">
        {hasTTF && <FontFileExtensionBadge fileExtension={FontFileType.Ttf} />}
        {hasOTF && <FontFileExtensionBadge fileExtension={FontFileType.Otf} />}
      </HStack>
    </Flex>
  )
}
