import { Badge, Box, Flex, GridItem, Link, Stack, Text } from '@chakra-ui/react'
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'
import NextLink from 'next/link'

type GetStartedOptionProps = {
  title: JSX.Element
  body: JSX.Element
  accentColor: string
  href: string
  icon: FontAwesomeIconProps['icon']
  isNew: boolean
  onClick: (e: React.MouseEvent) => void
}

export const GetStartedOption = ({
  title,
  body,
  accentColor,
  href,
  icon,
  isNew,
  onClick,
}: GetStartedOptionProps) => {
  return (
    <NextLink href={href} passHref legacyBehavior>
      <GridItem
        as={Link}
        position="relative"
        padding="6"
        border="2px solid"
        borderColor="gray.100"
        borderRadius="8"
        cursor="pointer"
        boxSizing="border-box"
        background="white"
        shadow="sm"
        transitionProperty="common"
        transitionDuration="normal"
        _hover={{
          borderColor: `${accentColor}.400`,
          boxShadow: 'xl',
          textDecoration: 'none',
        }}
        role="group"
        onClick={onClick}
      >
        <Stack>
          <Flex
            height="40"
            mb="2"
            justify="center"
            alignItems="center"
            borderRadius="md"
            top={0}
            left={0}
            bottom={0}
            right={0}
            background={`${accentColor}.100`}
            fontSize="3rem"
            color={`${accentColor}.300`}
            transitionProperty="all"
            transitionDuration="normal"
            _groupHover={{
              color: `${accentColor}.400`,
              fontSize: '3.125rem',
            }}
          >
            <Box>
              <FontAwesomeIcon icon={icon} className="fa-xl" />
            </Box>
          </Flex>
          {/* </Flex> */}
          <Text mt={2} fontSize="xl" fontWeight="bold" lineHeight="short">
            {title}
            {isNew && (
              <>
                {' '}
                <Badge fontSize="0.75em" colorScheme="green">
                  <Trans>Beta</Trans>
                </Badge>
              </>
            )}
          </Text>
          <Text fontSize="sm">{body}</Text>
        </Stack>
      </GridItem>
    </NextLink>
  )
}
