import { Button, HStack, Text } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'

type ViewToggleProps = {
  viewPreference: 'grid' | 'list'
  setViewPreference: (viewPreference: 'grid' | 'list') => void
}
export const ViewToggle = ({
  viewPreference,
  setViewPreference,
}: ViewToggleProps) => {
  const isListView = viewPreference === 'list'
  return (
    <HStack spacing={0} bg="gray.200" p={0.5} borderRadius="md">
      <Button
        size="sm"
        data-testid="view-toggle-grid"
        variant={isListView ? 'ghost' : 'plain'}
        color={isListView ? 'gray.600' : 'trueblue.500'}
        onClick={() => setViewPreference('grid')}
      >
        <HStack>
          <FontAwesomeIcon icon={regular('grid-2')} />
          <Text display={['none', 'none', 'none', 'none', 'block']}>
            <Trans>Grid</Trans>
          </Text>
        </HStack>
      </Button>
      <Button
        size="sm"
        data-testid="view-toggle-list"
        variant={isListView ? 'plain' : 'ghost'}
        color={isListView ? 'trueblue.500' : 'gray.600'}
        onClick={() => setViewPreference('list')}
      >
        <HStack>
          <FontAwesomeIcon icon={regular('bars')} />
          <Text display={['none', 'none', 'none', 'none', 'block']}>
            <Trans>List</Trans>
          </Text>
        </HStack>
      </Button>
    </HStack>
  )
}
