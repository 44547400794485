import {
  Avatar,
  Flex,
  HStack,
  Portal,
  Table,
  Td,
  Text,
  Tr,
} from '@chakra-ui/react'
import { LinkTagGroup } from '@gamma-app/ui'
import NextLink from 'next/link'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDragDropManager } from 'react-dnd'

import { DocumentGridItem } from 'gamma_components/DocumentGrid'
import placeholderBackground from 'gamma_components/placeholderBackground.svg'
import { Doc, ExistingWorkspace } from 'modules/api'
import { useFeatureFlag } from 'modules/featureFlags'
import { getTagsForDocExcludingChannel } from 'modules/sharing/utils'
import { GraphqlUser } from 'modules/user'
import {
  ChannelDocDragItemType,
  ViewPreferenceTypes,
} from 'sections/home_v2/types'

const getChannelDocDragItemSelector = (id: string) =>
  `[data-doc-grid-item-id="${id}"]`

/**
 * A custom drag layer to show a preview of the DocGridItem
 * being dragged. This is so the preview can be animated and
 * scaled down, which isnt possible with the native ghost image.
 * See https://react-dnd.github.io/react-dnd/examples/drag-around/custom-drag-layer
 */
export const DocGridItemCustomDragLayer = React.memo(
  ({
    user,
    currentWorkspace,
    currentChannelId,
    viewPreference,
  }: {
    user?: GraphqlUser
    currentWorkspace?: ExistingWorkspace
    currentChannelId: string | null
    viewPreference: ViewPreferenceTypes
  }) => {
    const screenshotsEnabled = useFeatureFlag('screenshotsEnabled')
    const wrapperRef = useRef<HTMLDivElement>(null)
    const innerRef = useRef<HTMLDivElement>(null)
    const [item, setItem] = useState<Doc | null>(null)
    const dragDropManager = useDragDropManager()
    const monitor = dragDropManager.getMonitor()

    useEffect(
      () =>
        monitor.subscribeToOffsetChange(() => {
          const i = monitor.getItem()
          if (!i || !wrapperRef.current || !innerRef.current) return

          const currentOffset = monitor.getClientOffset()
          if (!currentOffset) return

          const { x, y } = currentOffset
          wrapperRef.current!.style['transform'] = `translate(${x}px, ${y}px)`
        }),
      [monitor]
    )

    useEffect(
      () =>
        monitor.subscribeToStateChange(() => {
          const nextItemType = monitor.getItemType()
          const nextItem = monitor.getItem() as Doc | null
          if (nextItemType !== ChannelDocDragItemType || nextItem === null) {
            setItem(null)
            wrapperRef.current!.style['transform'] = `translate(0px, 0px)`
            return
          }
          const el = document.querySelector<HTMLDivElement>(
            getChannelDocDragItemSelector(nextItem.id)
          )
          if (el) {
            const elRect = el.getBoundingClientRect()
            innerRef.current!.style.width = `${elRect.width}px`
            innerRef.current!.style.height = `${elRect.height}px`
          }

          setItem(nextItem)
        }),
      [monitor]
    )

    const previewComp = useMemo(() => {
      if (!item) return null

      const tags = getTagsForDocExcludingChannel({
        doc: item,
        user,
        currentWorkspace,
        channelToExclude: currentChannelId,
      })

      const thumbnailImageUrl = {
        src: screenshotsEnabled
          ? item.titleCard?.previewUrl
          : placeholderBackground.src,
        fallbackSrc: placeholderBackground.src,
      }

      if (viewPreference === 'list') {
        return (
          <Table width="100%">
            <Tr>
              <Td></Td>
              <Td w="50%">{item.title || ''}</Td>
              <Td>
                <LinkTagGroup tags={tags} NextLink={NextLink} />
              </Td>
              <Td color="gray.600"></Td>
              <Td>
                <HStack>
                  <Avatar
                    shadow="md"
                    borderWidth="1px"
                    size="xs"
                    src={item?.createdBy?.profileImageUrl}
                  />
                  <Text>{item?.createdBy?.displayName}</Text>
                </HStack>
              </Td>
            </Tr>
          </Table>
        )
      }

      return (
        <DocumentGridItem
          id="temp"
          title={item.title || ''}
          thumbnailImageUrl={thumbnailImageUrl}
          NextLink={Flex}
          createdTime={item.createdTime}
          href=""
          editors={item.editors}
          tags={tags}
          createdByName={item.createdBy?.displayName || ''}
          createdByProfileImageUrl={item.createdBy?.profileImageUrl}
          createdByEmail={''}
          menuEnabled={false}
        />
      )
    }, [
      item,
      user,
      currentWorkspace,
      screenshotsEnabled,
      currentChannelId,
      viewPreference,
    ])

    return (
      <Portal>
        <Flex
          ref={wrapperRef}
          display={item ? 'block' : 'none'}
          position="fixed"
          pointerEvents="none"
          zIndex="overlay"
          left={0}
          top={0}
        >
          <Flex
            ref={innerRef}
            transform={`scale(${item ? 0.5 : 1})`}
            transition="transform .2s ease"
            transformOrigin="0 0"
          >
            {previewComp}
          </Flex>
        </Flex>
      </Portal>
    )
  }
)

DocGridItemCustomDragLayer.displayName = 'DocGridItemCustomDragLayer'
