import {
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import { getCardTitle } from '@gammatech/lib/dist/prosemirror-helpers'
import { t, Trans } from '@lingui/macro'
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'

import {
  UpdateDocTitleMutationVariables,
  useGetSnapshotQuery,
  useUpdateDocTitleMutation,
} from 'modules/api'
import { GAMMA_ARTIFACT_PROPER_NOUN } from 'modules/i18n/properNouns'

export const RenameDocModal = ({ onClose, doc }) => {
  const [updateDocTitle, { loading }] = useUpdateDocTitleMutation()
  const toast = useToast()
  const [titleField, setTitleField] = useState<string>(() => doc.title)
  const [computedTitle, setComputedTitle] = useState<string>(`Untitled`)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setTitleField(e.target.value)
  }, [])
  const initialRef = useRef<HTMLInputElement>(null)

  const { data: snapshotData } = useGetSnapshotQuery({
    variables: { docId: doc.id, snapshotId: doc.currentSnapshotId },
    skip: !doc?.currentSnapshotId || !doc?.id,
  })

  const isError = Boolean(errorMessage)

  useEffect(() => {
    if (!snapshotData?.snapshot) return
    const titleFromCard =
      (snapshotData?.snapshot &&
        getCardTitle(
          snapshotData.snapshot.content.default.content[0].content[0]
        )) ||
      t`Untitled`
    setComputedTitle(titleFromCard)
  }, [snapshotData?.snapshot])

  const onRenameSave = useCallback(() => {
    const titleEdited = Boolean(titleField && titleField.length)
    const variables: UpdateDocTitleMutationVariables = {
      id: doc.id,
      titleEdited,
      title: titleEdited ? titleField : computedTitle,
    }
    updateDocTitle({
      variables,
    })
      .then(() => {
        toast({
          title: (
            <Trans>
              Success! Your {GAMMA_ARTIFACT_PROPER_NOUN} was renamed to{' '}
              {titleField.length > 0 ? titleField : computedTitle}
            </Trans>
          ),
          position: 'top',
          status: 'success',
          duration: 5000,
        })
        setErrorMessage(null)
        onClose()
      })
      .catch((e) => {
        console.error(e)
        setErrorMessage(e.message)
      })
  }, [doc.id, onClose, titleField, toast, updateDocTitle, computedTitle])

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={true}
      initialFocusRef={initialRef}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Trans>Rename</Trans>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex mt={4} w="100%" direction="column">
            <FormControl isInvalid={isError}>
              <Input
                value={titleField}
                placeholder={computedTitle}
                ref={initialRef}
                _placeholder={{
                  opacity: 1,
                  color: 'gray.300',
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') onRenameSave()
                }}
                onChange={onChange}
              />
              {isError && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
            </FormControl>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button variant="ghost" onClick={onClose}>
              <Trans>Cancel</Trans>
            </Button>
            <Button isLoading={loading} variant="solid" onClick={onRenameSave}>
              <Trans>Rename</Trans>
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
