import { SearchIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Spacer,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AvatarGroupAutoOverflow, SectionTitle } from '@gamma-app/ui'
import { Plural, t, Trans } from '@lingui/macro'
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'

import {
  Channel,
  ChannelSortField,
  SortDirection,
  useGetWorkspaceChannelsQuery,
} from 'modules/api'
import { GAMMA_ARTIFACT_PROPER_NOUN_PLURAL } from 'modules/i18n/properNouns'
import { CHANNELS_ICON } from 'modules/sharing/constants'
import SalPainting from 'publicImages/Sal-Painting-2x.png'
import { useChannelMutations } from 'sections/home_v2/hooks/useChannelMutations'

import { ChannelsEmptyState } from './ChannelsEmptyState'

const MAX_CHANNEL_AVATARS = 3

const ChannelsList = ({
  channels,
  workspaceId,
}: {
  channels: Channel[]
  workspaceId: string
}) => {
  const { leaveChannel, joinChannel } = useChannelMutations()

  const handleJoinLeaveClick = useCallback(
    (shouldJoin: boolean, channel: Channel) => {
      if (shouldJoin) {
        joinChannel({ channel, workspaceId })
      } else {
        leaveChannel({ channel })
      }
    },
    [joinChannel, leaveChannel, workspaceId]
  )

  return (
    <Stack spacing={0}>
      {channels.length === 0 && (
        <Flex
          direction="column"
          justify="center"
          align="center"
          w="100%"
          my={6}
        >
          <Box w="80%" maxW="200px" mb={6} alignContent="center">
            <Image
              src={SalPainting.src}
              width="725px"
              alt="Sal, the Gamma mascot, painting in space"
            />
          </Box>
          <Heading size="md" mb={2} textAlign="center">
            <Trans>Your workspace doesn't have any folders yet.</Trans>
          </Heading>
          <Text fontSize="lg" color="gray.400" textAlign="center">
            <Trans>
              Start using folders to organize your{' '}
              {GAMMA_ARTIFACT_PROPER_NOUN_PLURAL}. <br />
              Your workspace's folders will appear here.
            </Trans>
          </Text>
        </Flex>
      )}
      {channels.length > 0 &&
        channels.map((channel) => {
          const { members, isMember, memberCount, name } = channel
          const memberList = members || []
          return (
            <HStack
              key={channel.id}
              spacing={4}
              px={4}
              py={2}
              _hover={{ bg: 'gray.100' }}
              borderRadius="md"
              transitionProperty="common"
              transitionDuration="normal"
            >
              <Box p={2} color={!isMember ? 'gray.400' : ''}>
                <FontAwesomeIcon
                  icon={isMember ? regular('circle-check') : CHANNELS_ICON}
                />
              </Box>

              <HStack flex={1}>
                <Stack spacing={0}>
                  <Text>{name}</Text>
                  <Text fontSize="sm" color="gray.400">
                    {channel.isMember ? (
                      <Plural
                        value={memberCount || 0}
                        one="# member, including you"
                        other="# members, including you"
                      />
                    ) : (
                      <Plural
                        value={memberCount || 0}
                        one="# member"
                        other="# members"
                      />
                    )}
                  </Text>
                </Stack>
                <Spacer />
                <AvatarGroupAutoOverflow
                  size="xs"
                  overflowTooltipLabel={t`See everyone`}
                  avatars={memberList}
                  max={MAX_CHANNEL_AVATARS}
                />
              </HStack>
              <Button
                w={20}
                variant={isMember ? 'ghost' : 'plain'}
                color={isMember ? 'gray.600' : 'plain'}
                size="sm"
                onClick={() => {
                  handleJoinLeaveClick(!isMember, channel)
                }}
              >
                {isMember ? <Trans>Leave</Trans> : <Trans>Join</Trans>}
              </Button>
            </HStack>
          )
        })}
    </Stack>
  )
}

export const ManageChannelsModal = ({ isOpen, onClose, workspaceId }) => {
  const [query, setQuery] = useState('')
  const [showSearchEmptyState, setShowSearchEmptyState] = useState(false)
  const [hasInitialized, setHasInitialized] = useState(false)
  const initialRef = useRef<HTMLInputElement>(null)
  const { createChannel, createChannelLoading, createChannelError } =
    useChannelMutations()
  const [createChannelValidationError, setCreateChannelValidationError] =
    useState<string | null>(null)
  const [isFetchingMore, setIsFetchingMore] = useState<boolean>(false)

  const {
    data: apiData,
    loading: apiLoading,
    called: apiCalled,
    fetchMore: apiFetchMore,
  } = useGetWorkspaceChannelsQuery({
    variables: {
      workspaceId,
      first: 20,
      sortBy: {
        field: ChannelSortField.MemberCount,
        direction: SortDirection.Desc,
      },
      query,
    },
    skip: !workspaceId,
    nextFetchPolicy: 'cache-only',
  })

  const onQueryChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setCreateChannelValidationError(null)
    if (value) {
      setQuery(value)
    } else {
      setQuery('')
    }
  }, [])

  const showInitialSpinner = !apiCalled && apiLoading
  const hasQueryWithNoResults = Boolean(
    query && apiData?.workspaceChannels.edges.length === 0
  )

  const onCreateChannelClick = () => {
    if (!workspaceId) return

    const input = {
      name: query,
      workspaceId,
    }

    createChannel({
      input,
    })
      .then(() => {
        onClose()
      })
      .catch((e) => {
        setCreateChannelValidationError(e.message)
        // swallow
      })
  }

  useEffect(() => {
    if (apiLoading) return
    // Only update this value when search is done loading
    setShowSearchEmptyState(hasQueryWithNoResults)
  }, [apiLoading, hasQueryWithNoResults])

  useEffect(() => {
    if (!apiCalled || apiLoading || hasInitialized) return
    setHasInitialized(true)
  }, [apiCalled, apiLoading, hasInitialized])

  // Err on the side of hiding the empty state to prevent flicker
  const shouldShowSearchEmptyState =
    showSearchEmptyState && hasQueryWithNoResults

  const channels = apiData?.workspaceChannels.edges.map((e) => e.node) || []

  // If user has not entered a query, or query is empty, then show the first 10 of all channels by default
  const showAllChannels = !query
  const pageInfo = apiData?.workspaceChannels?.pageInfo
  const showPaginationControls =
    hasInitialized && pageInfo?.hasNextPage && pageInfo?.endCursor

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      lockFocusAcrossFrames={false}
      // This is needed to prevent the pesky tooltip from appearing if you click the button to create a new channel
      returnFocusOnClose={false}
      size="2xl"
      initialFocusRef={initialRef}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader pb={0}>
          <Trans>Create or join a folder</Trans>
        </ModalHeader>
        <Text px={6} color="gray.500" mb={2}>
          <Trans>
            You can join a folder to keep track of what folks are working on
          </Trans>
          .
        </Text>

        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={5}>
            <HStack align="flex-start">
              <FormControl
                isInvalid={Boolean(createChannelError) && query.length > 0}
              >
                <InputGroup size="md">
                  <InputLeftElement pointerEvents="none" color="gray.300">
                    <SearchIcon />
                  </InputLeftElement>
                  <Input
                    value={query}
                    onChange={onQueryChange}
                    placeholder={t`Find or create a new folder`}
                    _placeholder={{ opacity: 1 }}
                    ref={initialRef}
                  />
                  {apiLoading && (
                    <InputRightElement
                      pointerEvents="none"
                      color="gray.500"
                      transitionProperty="common"
                      transitionDuration="normal"
                    >
                      <Spinner size="sm" />
                    </InputRightElement>
                  )}
                </InputGroup>
                {createChannelValidationError && (
                  <FormErrorMessage>
                    {createChannelValidationError}
                  </FormErrorMessage>
                )}
              </FormControl>
              <Button
                w="300px"
                variant="plain"
                onClick={onCreateChannelClick}
                isDisabled={query.length === 0}
                isLoading={createChannelLoading}
              >
                <Trans>Create folder</Trans>
              </Button>
            </HStack>
            <SectionTitle>
              {showAllChannels && <Trans>All folders</Trans>}
              {query && (
                <Plural
                  value={channels.length}
                  one="Displaying # folder"
                  other="Displaying # folders"
                />
              )}
            </SectionTitle>
            {shouldShowSearchEmptyState && (
              <ChannelsEmptyState
                channelName={query}
                onCreateClick={onCreateChannelClick}
              />
            )}
            {!hasInitialized && (
              <Stack>
                <Skeleton height="55px" />
                <Skeleton height="55px" />
                <Skeleton height="55px" />
                <Skeleton height="55px" />
                <Skeleton height="55px" />
              </Stack>
            )}
            {hasInitialized && workspaceId && !shouldShowSearchEmptyState && (
              <ChannelsList channels={channels} workspaceId={workspaceId} />
            )}
            {showPaginationControls && (
              <Center p={6}>
                <Button
                  isLoading={isFetchingMore}
                  onClick={() => {
                    setIsFetchingMore(true)
                    apiFetchMore({
                      variables: {
                        ...(query.length === 0 ? { first: 50 } : {}),
                        after: pageInfo?.endCursor,
                      },
                    }).finally(() => {
                      setIsFetchingMore(false)
                    })
                  }}
                  size="sm"
                >
                  <Trans>Load more</Trans>
                </Button>
              </Center>
            )}
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button variant="solid" onClick={onClose}>
            <Trans>Done</Trans>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
