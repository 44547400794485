import { t, Trans } from '@lingui/macro'

import { WorkspaceRole } from 'modules/api'

export const WORKSPACE_NAME_MIN_LENGTH = 1

export const getWorkspaceAdminDisplayName = () => t`admin`
export const getWorkspaceAdminDisplayNamePlural = () => t`admins`
export const getWorkspaceMemberDisplayName = () => t`member`

export const workspaceRoleMap = {
  [WorkspaceRole.Admin]: {
    title: <Trans>Admin</Trans>,
    description: (
      <Trans>
        Can change workspace settings, assign roles, and invite or remove
        workspace members
      </Trans>
    ),
  },
  [WorkspaceRole.Member]: {
    title: <Trans>Member</Trans>,
    description: (
      <Trans>
        Cannot change workspace settings or manage workspace members
      </Trans>
    ),
  },
}
