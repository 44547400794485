import {
  Badge,
  Button,
  ButtonProps,
  Flex,
  GridItem,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useCallback } from 'react'

import { AI_WIZARD_PATH } from 'modules/ai/wizard/constants'
import { useFeatureFlag } from 'modules/featureFlags'
import { useModalDisclosure } from 'modules/modal_state/hooks/useModalDisclosure'
import GuidedThumbnail from 'publicImages//ai/guided-thumb.png'
import TextToDeckThumbnail from 'publicImages//ai/text-to-deck-thumb.png'
import { isMobileDevice } from 'utils/deviceDetection'

const AdvancedModeDrawer = dynamic(
  () =>
    import('modules/ai/wizard/components/AdvancedMode/AdvancedModeDrawer').then(
      (mod) => mod.AdvancedModeDrawer
    ),
  {
    ssr: false,
  }
)

type AiModeOptionKey = 'advanced' | 'guided'
type AiModeOption = {
  title: React.ReactNode
  description: React.ReactNode
  beta?: boolean
  icon: string
}

const AI_MODE_OPTIONS: {
  [key in AiModeOptionKey]: AiModeOption
} = {
  guided: {
    title: <Trans>Generate</Trans>,
    description: (
      <Trans>
        Provide a topic, and let AI generate you a first draft of a{' '}
        <strong>doc</strong>, <strong>deck</strong> or <strong>webpage</strong>.
      </Trans>
    ),
    icon: GuidedThumbnail.src,
  },
  advanced: {
    title: <Trans>Text transform</Trans>,
    description: (
      <Trans>
        <strong>Paste in</strong> notes, or <strong>import</strong> docs and
        slides. Edit with more advanced controls.
      </Trans>
    ),
    icon: TextToDeckThumbnail.src,
  },
}

export const AIOptionButton = ({ title, description, beta, icon, onClick }) => {
  return (
    <GridItem
      position="relative"
      padding="6"
      border="2px solid"
      borderColor="gray.100"
      cursor="pointer"
      boxSizing="border-box"
      background="white"
      shadow="sm"
      transitionProperty="common"
      transitionDuration="normal"
      _hover={{
        bg: `trueblue.50`,
        borderColor: `trueblue.400`,
        boxShadow: 'xl',
        textDecoration: 'none',
      }}
      role="group"
      onClick={onClick}
      as={Button}
      variant="unstyled"
      borderRadius={8}
      height="revert"
      fontWeight="unset"
      whiteSpace="normal"
      textAlign="left"
      alignItems="start"
      display="flex"
    >
      <Stack>
        <Flex
          height="40"
          mb="2"
          justify="top"
          alignItems="top"
          borderRadius="md"
          top={0}
          left={0}
          bottom={0}
          right={0}
          fontSize="3rem"
          transitionProperty="all"
          transitionDuration="normal"
          bgImage={icon}
          bgSize="cover"
        />
        <HStack align="center" pt={2}>
          <Text mt={0} fontSize="xl" fontWeight="bold" lineHeight="short">
            {title}
          </Text>
          <Spacer />
          {beta && (
            <Badge fontSize="0.75em" colorScheme="green" mt="-6px !important">
              <Trans>Beta</Trans>
            </Badge>
          )}
        </HStack>
        <Text fontSize="sm"> {description}</Text>
      </Stack>
    </GridItem>
  )
}
export const NewWithAIButtonAndModal = ({
  isDisabled,
  handleNewWithAIClick,
  ...buttonProps
}: {
  isDisabled: boolean
  handleNewWithAIClick?: () => void
} & ButtonProps) => {
  const aiWizardAdvancedEnabled = useFeatureFlag('aiWizardAdvanced')
  const createV2Enabled = useFeatureFlag('createv2')

  const { push } = useRouter()
  const {
    isOpen: isAiWizardAdvancedDrawerOpen,
    onClose: onAiWizardAdvancedDrawerClose,
    onOpen: onAiWizardAdvancedDrawerOpen,
  } = useModalDisclosure({ id: 'aiWizardAdvancedDrawer' })

  // New with AI modal
  const {
    isOpen: newWithAiModalIsOpen,
    onClose: newWithAiModalOnClose,
    onOpen: newWithAiModalOnOpen,
  } = useModalDisclosure({ id: 'newWithAiModal' })

  const onClickGuided = useCallback(() => {
    if (handleNewWithAIClick) {
      handleNewWithAIClick()
    } else {
      newWithAiModalOnClose()
      push(AI_WIZARD_PATH)
    }
  }, [handleNewWithAIClick, newWithAiModalOnClose, push])

  const onClickAdvanced = useCallback(() => {
    if (handleNewWithAIClick) {
      handleNewWithAIClick()
    } else {
      newWithAiModalOnClose()
      onAiWizardAdvancedDrawerOpen()
    }
  }, [
    handleNewWithAIClick,
    newWithAiModalOnClose,
    onAiWizardAdvancedDrawerOpen,
  ])

  const onClickNew = createV2Enabled ? () => push('/create') : onClickGuided

  if (!isMobileDevice() && aiWizardAdvancedEnabled && !createV2Enabled) {
    return (
      <>
        <Modal
          isOpen={newWithAiModalIsOpen}
          onClose={newWithAiModalOnClose}
          size="xl"
          // This is needed to allow the UpsellModalPro (accessible via credits modal) to be scrollable
          blockScrollOnMount={false}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <FontAwesomeIcon icon={solid('sparkle')} />{' '}
              <Trans>New with AI</Trans>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <SimpleGrid columns={[1, 2]} spacing={5} mb={6}>
                {Object.entries(AI_MODE_OPTIONS).map(([mode, modeConfig]) => {
                  const { description, title, beta, icon } =
                    modeConfig as AiModeOption
                  const onClick =
                    mode === 'guided' ? onClickGuided : onClickAdvanced
                  return (
                    <AIOptionButton
                      key={mode}
                      description={description}
                      title={title}
                      beta={beta}
                      icon={icon}
                      onClick={onClick}
                    />
                  )
                })}
              </SimpleGrid>
            </ModalBody>
          </ModalContent>
        </Modal>

        <Button
          leftIcon={<FontAwesomeIcon icon={regular('plus')} />}
          rightIcon={
            <Badge colorScheme="trueblue" textShadow="none">
              <Trans>AI</Trans>
            </Badge>
          }
          variant={'solid'}
          onClick={newWithAiModalOnOpen}
        >
          <Trans>
            <Trans>Create new</Trans>
          </Trans>
        </Button>
        <AdvancedModeDrawer
          isOpen={isAiWizardAdvancedDrawerOpen}
          onClose={onAiWizardAdvancedDrawerClose}
        />
      </>
    )
  }
  // Mobile devices and anyone for whom advanced ai isn't enabled yet
  return (
    <Button
      data-testid="create-from-aipartner-button"
      isDisabled={isDisabled}
      variant={'solid'}
      onClick={onClickNew}
      leftIcon={<FontAwesomeIcon icon={regular('plus')} />}
      rightIcon={
        <Badge colorScheme="trueblue" textShadow="none">
          AI
        </Badge>
      }
    >
      <Trans>Create new</Trans>
    </Button>
  )
}
