import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'
export type GettingStartedOption = {
  title: JSX.Element
  body: JSX.Element
  colorScheme: string
  icon: FontAwesomeIconProps['icon']
  action:
    | 'createFromAI'
    | 'blankDeck'
    | 'templates'
    | 'welcomeDoc'
    | 'inspiration'
  isNew: boolean
}

export const GETTING_STARTED_OPTIONS: Record<string, GettingStartedOption> = {
  createFromAI: {
    title: <Trans>Create using AI</Trans>,
    body: (
      <Trans>
        Use our AI generator to quickly create a first draft of your
        presentation, document, or webpage.
      </Trans>
    ),
    colorScheme: 'pink',
    icon: solid('sparkle'),
    action: 'createFromAI',
    isNew: true,
  },
  createDeck: {
    title: <Trans>Start from blank</Trans>,
    body: (
      <Trans>
        Jump right in and start writing. Add or paste in content, style it with
        themes, and test out present mode.
      </Trans>
    ),
    colorScheme: 'purple',
    icon: solid('plus-large'),
    action: 'blankDeck',
    isNew: false,
  },
  viewTemplates: {
    title: <Trans>Browse templates</Trans>,
    body: (
      <Trans>
        Create your next presentation faster with our collection of fully
        customizable templates.
      </Trans>
    ),
    colorScheme: 'salmon',
    icon: solid('bolt'),
    action: 'templates',
    isNew: false,
  },
  tipsAndTricks: {
    title: <Trans>Gamma tips & tricks</Trans>,
    body: (
      <Trans>
        See how Gamma differs from traditional slide decks, and check out
        everything it can do.
      </Trans>
    ),
    colorScheme: 'teal',
    icon: solid('rectangle-history'),
    action: 'welcomeDoc',
    isNew: false,
  },
  inspiration: {
    title: <Trans>Get inspired</Trans>,
    body: (
      <Trans>
        Browse decks created by the Gamma team and other members of the Gamma
        community.
      </Trans>
    ),
    colorScheme: 'orchid',
    icon: solid('book-open'),
    action: 'inspiration',
    isNew: false,
  },
}
