import { useToast } from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import { useCallback } from 'react'

import {
  ExistingWorkspace,
  FontFile,
  JsonPatch,
  JsonPatchOperation,
  useArchiveFontMutation,
  useCreateFontMutation,
  useUpdateFontMutation,
} from 'modules/api'

import { FontUploadReducerState } from './fontUploadReducer/types'

type FontFileMaybeId = Omit<FontFile, 'id'> & {
  id?: string
}
export const useSaveFont = (orgId: string) => {
  const [createFont] = useCreateFontMutation()
  const [updateFont] = useUpdateFontMutation()

  const saveFont = useCallback(
    (state: FontUploadReducerState) => {
      const filesToSave: FontFileMaybeId[] = state.uploadFontFiles
        .filter(
          (file) =>
            (file.status === 'existing' || file.status === 'created') &&
            file.fontFile.sourceUrl
        )
        .map((uploadFontFile) => {
          const { name, weight, isItalic, fileType, sourceUrl } =
            uploadFontFile.fontFile
          const fileToSave: FontFileMaybeId = {
            name,
            weight,
            isItalic,
            fileType,
            sourceUrl,
          }
          if (
            uploadFontFile.status === 'existing' &&
            uploadFontFile.fontFile.id
          ) {
            fileToSave['id'] = uploadFontFile.fontFile.id
          }
          return fileToSave
        })

      if (state.mode === 'edit') {
        const newData: JsonPatch[] = [
          {
            op: JsonPatchOperation.Add,
            path: '/name',
            value: state.fontName,
          },
          // Replace all font files with the font files in the UI
          {
            op: JsonPatchOperation.Replace,
            path: '/fontFiles',
            value: filesToSave,
          },
        ]
        return updateFont({
          variables: {
            input: {
              id: state.fontId!,
              workspaceId: orgId,
              data: newData,
            },
          },
          refetchQueries: ['GetWorkspaceFonts'],
        })
      } else {
        return createFont({
          variables: {
            input: {
              name: state.fontName,
              fontFiles: filesToSave,
              workspaceId: orgId,
            },
          },
          refetchQueries: ['GetWorkspaceFonts'],
        })
      }
    },
    [createFont, orgId, updateFont]
  )

  return saveFont
}

export const useDeleteFont = (orgId: ExistingWorkspace['id']) => {
  const toast = useToast()

  const [archiveFont, { loading }] = useArchiveFontMutation()
  const archiveFontFn = useCallback(
    async (id: string, name: string) => {
      return archiveFont({
        variables: { id, workspaceId: orgId },
        refetchQueries: ['GetWorkspaceFonts'],
      }).then((results) => {
        toast({
          title: <Trans>Successfully deleted {name} from workspace</Trans>,
          status: 'success',
          duration: 2000,
          isClosable: true,
          position: 'top',
        })
        return results
      })
    },
    [archiveFont, orgId, toast]
  )

  return { archiveFontFn, isArchiving: loading }
}
