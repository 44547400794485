import {
  Box,
  Collapse,
  HStack,
  Link,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'
import NextLink from 'next/link'

import { BigButton } from 'gamma_components/BigButton'
import { ImportedFile } from 'modules/import/types'
import { useImportGoogleDoc } from 'modules/import/useImportGoogleDoc'
import { useUploadDoc } from 'modules/import/useUploadDoc'
import ThumbnailDrive from 'publicImages/Thumbnail-drive.svg'
import ThumbnailUpload from 'publicImages/Thumbnail-upload.svg'

const CheckboxRow = ({ text }: { text: JSX.Element }) => {
  return (
    <Stack direction="row" spacing={1.5} w="100%">
      <Box color="green.400">
        <FontAwesomeIcon icon={solid('check')} />
      </Box>
      <Text>{text}</Text>
    </Stack>
  )
}

export const ImportButtons = ({
  onImportComplete,
  hidePasteLink,
}: {
  onImportComplete: (file: ImportedFile) => void
  hidePasteLink?: boolean
}) => {
  const {
    initiateDocUpload,
    hiddenInputElement,
    isUploading,
    isSuccess: isUploadSuccess,
  } = useUploadDoc({
    onUploadComplete: onImportComplete,
  })
  const {
    initiateGoogleImport,
    isImporting,
    isSuccess: isImportSuccess,
  } = useImportGoogleDoc({
    onImportComplete,
  })
  const isUploadingOrImporting = isUploading || isImporting
  const isSuccess = isImportSuccess || isUploadSuccess
  return (
    <Box w="100%">
      <Collapse in={isSuccess}>
        <HStack
          w="100%"
          p={12}
          justifyContent="center"
          spacing={4}
          border="none"
          boxShadow="none"
          bg="whiteAlpha.700"
          backdropFilter="blur(10px)"
          borderRadius="md"
        >
          <Text fontSize="xl">
            <Trans>Success!</Trans>
          </Text>
        </HStack>
      </Collapse>
      <Collapse in={isUploadingOrImporting}>
        <HStack
          w="100%"
          p={12}
          justifyContent="center"
          spacing={4}
          border="none"
          boxShadow="none"
          bg="whiteAlpha.700"
          backdropFilter="blur(10px)"
          borderRadius="md"
        >
          <Spinner />
          <Text fontSize="xl">
            <Trans>Importing your content...</Trans>
          </Text>
        </HStack>
      </Collapse>
      <Collapse in={!isUploadingOrImporting && !isSuccess}>
        <Stack
          direction="column"
          spacing={{ base: 6, md: 8 }}
          align="center"
          w="100%"
        >
          <Stack
            w="100%"
            direction={{ base: 'column', md: 'row' }}
            justifyContent="center"
            spacing={{ base: 3, md: 4 }}
          >
            <BigButton
              label={<Trans>Import from Drive</Trans>}
              description={
                <Stack spacing={1}>
                  <CheckboxRow text={<Trans>Google Slides</Trans>} />
                  <CheckboxRow text={<Trans>Google Docs</Trans>} />
                </Stack>
              }
              isPopular={false}
              colorScheme="blue"
              imageUrl={ThumbnailDrive.src}
              onClick={initiateGoogleImport}
              ctaIcon={
                <FontAwesomeIcon icon={regular('arrow-up-from-bracket')} />
              }
              ctaLabel={<Trans>Connect</Trans>}
            />
            <BigButton
              label={<Trans>Upload a file</Trans>}
              description={
                <Stack spacing={1}>
                  <CheckboxRow text={<Trans>Powerpoint PPT/X</Trans>} />
                  <CheckboxRow text={<Trans>Word docs</Trans>} />
                </Stack>
              }
              isPopular={false}
              colorScheme="purple"
              imageUrl={ThumbnailUpload.src}
              onClick={initiateDocUpload}
              ctaIcon={
                <FontAwesomeIcon icon={regular('arrow-up-from-bracket')} />
              }
              ctaLabel={<Trans>Browse files</Trans>}
            />
            {hiddenInputElement}
          </Stack>
          {!hidePasteLink && (
            <Box w="100%">
              <Text textAlign="center" color="blackAlpha.800">
                <Trans>
                  If your file isn't supported, you can also{' '}
                  <Link
                    as={NextLink}
                    textDecoration="underline"
                    fontWeight="600"
                    href="/create/paste?ref=import"
                  >
                    paste in text
                  </Link>
                </Trans>
              </Text>
            </Box>
          )}
        </Stack>
      </Collapse>
    </Box>
  )
}
