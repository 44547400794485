import { t, Trans } from '@lingui/macro'

import { DocSortField } from 'modules/api'

import { SidebarTabs } from './components/Sidebar'
import { FilterByTypes, SortByOptions } from './types'

export const DEFAULT_FILTERBY_TYPE: FilterByTypes = 'recent'
export const DISPLAY_DATE_OPTIONS: SortByOptions = [
  {
    key: DocSortField.EditedTime,
    label: <Trans>Last edited</Trans>,
  },
  {
    key: DocSortField.CreatedTime,
    label: <Trans>Date created</Trans>,
  },
]

export const DOC_USER_DISPLAY_DATE_OPTIONS: SortByOptions = [
  {
    key: DocSortField.LastViewed,
    label: <Trans>Last viewed</Trans>,
  },
  {
    key: DocSortField.LastEdited,
    label: <Trans>Last edited</Trans>,
  },
  {
    key: DocSortField.Favorited,
    label: <Trans>Date favorited</Trans>,
  },
]

// In the absence of a tab hash, we'll default to this tab.
export const INITIAL_TAB = SidebarTabs.ALL

export const DEFAULT_DATE_DISPLAY_OPTION = DocSortField.EditedTime

export const DOC_USER_SORTBY_FIELDS = [
  DocSortField.LastViewed,
  DocSortField.LastEdited,
  DocSortField.Favorited,
]
