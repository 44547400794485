import {
  Alert,
  AlertIcon,
  Avatar,
  Button,
  ButtonGroup,
  HStack,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  OrderedList,
  Stack,
  StackProps,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'

import { ExistingWorkspace, User } from 'modules/api'
import { GAMMA_ARTIFACT_PROPER_NOUN_PLURAL } from 'modules/i18n/properNouns'
import { getWorkspaceAdminDisplayName } from 'modules/workspaces/constants'
import { EditMemberAction } from 'modules/workspaces/types'
import { formatNameWithEmail } from 'modules/workspaces/utils'

const getModalLabels = (
  action: EditMemberAction,
  user: User,
  workspace: ExistingWorkspace
) => {
  const nameWithEmail = formatNameWithEmail(user.displayName, user.email)
  const WORKSPACE_ADMIN_DISPLAY_NAME = getWorkspaceAdminDisplayName()
  if (action === 'remove') {
    return {
      title: <Trans>Remove {user.displayName || ''} from workspace?</Trans>,
      description: (
        <Trans>
          Are you sure you want to remove {nameWithEmail} from your workspace?
        </Trans>
      ),
      confirmButtonLabel: <Trans>Yes, remove from workspace</Trans>,
    }
  } else if (action === 'leave') {
    return {
      title: <Trans>Leave workspace?</Trans>,
      description: (
        <Trans>
          Are you sure you want to leave the {workspace.name} workspace? You
          will not be able to rejoin unless someone else invites you.
        </Trans>
      ),
      confirmButtonLabel: <Trans>Yes, leave workspace</Trans>,
    }
  } else {
    return {
      title: <Trans>Give up {WORKSPACE_ADMIN_DISPLAY_NAME} access?</Trans>,
      description: (
        <Trans>
          Are you sure you want to give up {WORKSPACE_ADMIN_DISPLAY_NAME}{' '}
          access? Another {WORKSPACE_ADMIN_DISPLAY_NAME} will have to make you
          an {WORKSPACE_ADMIN_DISPLAY_NAME} again if you want.
        </Trans>
      ),
      confirmButtonLabel: <Trans>Yes, change my role</Trans>,
    }
  }
}

const MODAL_SPACING = 3

type ConfirmMemberEditModalProps = {
  isOpen: boolean
  onClose: () => void
  onConfirmClick: () => void
  onCancelClick: () => void
  userToConfirm: User
  workspace: ExistingWorkspace
  action: EditMemberAction
}
export const ConfirmMemberEditModal = ({
  isOpen,
  onClose,
  onConfirmClick,
  onCancelClick,
  userToConfirm,
  workspace,
  action,
}: ConfirmMemberEditModalProps) => {
  if (!isOpen) {
    return null
  }

  const { title, description, confirmButtonLabel } = getModalLabels(
    action,
    userToConfirm,
    workspace
  )

  return (
    <Modal
      returnFocusOnClose={false}
      onClose={onClose}
      isOpen={true}
      isCentered
      size="lg"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>{description}</Text>
          {action === 'remove' && (
            <RemoveMemberDetails user={userToConfirm} mt={MODAL_SPACING} />
          )}
        </ModalBody>
        <ModalFooter>
          <ButtonGroup variant="ghost">
            <Button onClick={onCancelClick} colorScheme="gray">
              <Trans>Cancel</Trans>
            </Button>
            <Button colorScheme="red" onClick={onConfirmClick}>
              {confirmButtonLabel}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

interface RemoveMemberDetailsProps extends StackProps {
  user: User
}
const RemoveMemberDetails = ({ user, ...rest }: RemoveMemberDetailsProps) => {
  return (
    <VStack spacing={3} {...rest}>
      <Alert status="warning">
        <HStack>
          <Avatar
            src={user?.profileImageUrl}
            size="sm"
            name={user?.displayName}
          />
          <Stack spacing={0} ml={2} flex="1">
            <Text fontSize="sm" flex="1" wordBreak="break-word">
              {user?.displayName}
            </Text>
            {user.email && (
              <Text
                fontSize="xs"
                color="gray.600"
                flex="1"
                wordBreak="break-word"
              >
                {user.email}
              </Text>
            )}
          </Stack>
        </HStack>
      </Alert>
      <Alert>
        <AlertIcon />
        <OrderedList spacing={MODAL_SPACING}>
          <ListItem>
            <Trans>
              Any {GAMMA_ARTIFACT_PROPER_NOUN_PLURAL} {user.displayName || ''}{' '}
              created and shared with the workspace will remain available in the
              workspace.
            </Trans>
          </ListItem>
          <ListItem>
            <Trans>
              Any private {GAMMA_ARTIFACT_PROPER_NOUN_PLURAL}{' '}
              {user.displayName || ''} created will be archived.
            </Trans>
          </ListItem>
        </OrderedList>
      </Alert>
    </VStack>
  )
}
