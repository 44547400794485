import { useToast } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { hasGrantedAllScopesGoogle, useGoogleLogin } from '@react-oauth/google'
import { useCallback, useState } from 'react'

import { config } from 'config'
import { featureFlags } from 'modules/featureFlags'
import { useUserContext } from 'modules/user'
import { useScript } from 'utils/hooks'

import { ImportedFile } from './types'
import { useUploadDoc } from './useUploadDoc'
const GOOGLE_SDK_URL = 'https://apis.google.com/js/api.js'

// Space delimited set of scopes
// This gets us access to the files in the drive that they select through the picker
// This is basically the only non-sensitive drive scope, so change this at your peril
const scope = 'https://www.googleapis.com/auth/drive.file'

type UseImportGoogleDocHandlers = {
  onImportComplete: (file: ImportedFile) => void
}

export const useImportGoogleDoc = (handlers: UseImportGoogleDocHandlers) => {
  const [pickerReady, setPickerReady] = useState(false)
  const [accessToken, setAccessToken] = useState<string>()
  const toast = useToast()
  const { user } = useUserContext()
  const [isImporting, setImporting] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const { handleDocUpload } = useUploadDoc({
    onUploadComplete: handlers.onImportComplete,
  })

  const loadGoogleSlide = useCallback(
    (token: string, fileId: string, mimeType: string, title: string) => {
      // https://stackoverflow.com/a/59168288
      // exportLinks doesn't have file size limitations like /export does
      const url = `https://www.googleapis.com/drive/v3/files/${fileId}?fields=exportLinks`
      setImporting(true)
      const loadDocToast = toast({
        id: `load-doc-toast-${fileId}`,
        position: 'top',
        duration: null,
        status: 'loading',
        variant: 'subtle',
        title: (
          <Trans comment="This is shown in a toast notification while we are importing a Google doc">
            Importing...
          </Trans>
        ),
        description: <Trans>This could take up to a minute</Trans>,
        isClosable: false,
      }) as string
      fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then(async (response) => {
          if (response.status !== 200) {
            const body = await response.json()
            const errorMsg = body?.error?.message
            return Promise.reject(
              errorMsg
                ? `Google Drive error ${response.status}: ${errorMsg}`
                : `Google Drive error ${response.status}`
            )
          }
          return response.json()
        })
        .then(async (body) => {
          const downloadUrl = body.exportLinks[mimeType]
          const response = await fetch(downloadUrl, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          return response.blob()
        })
        .then((blob) => {
          const file = new File([blob], `${title}.pptx`, { type: mimeType })
          handleDocUpload([file])
          setImporting(false)
          toast.close(loadDocToast)
        })
        .catch((error) => {
          console.error(`Error importing doc ${fileId} from google`, error)
          setImporting(false)
          toast.update(loadDocToast, {
            status: 'error',
            title: <Trans>Error</Trans>,
            description: error.message || error || (
              <Trans>
                Sorry, we couldn't import this file. Please try again.
              </Trans>
            ),
            duration: 5000,
            isClosable: true,
          })
          return
        })
    },
    [toast, handleDocUpload]
  )

  const loadGoogleDoc = useCallback(
    (token: string, fileId: string, mimeType: string, title: string) => {
      // https://developers.google.com/drive/api/v3/reference/files/export
      const url = `https://www.googleapis.com/drive/v3/files/${fileId}/export?mimeType=${mimeType}`
      setImporting(true)
      const loadDocToast = toast({
        id: `load-doc-toast-${fileId}`,
        position: 'top',
        duration: null,
        status: 'loading',
        variant: 'subtle',
        title: <Trans>Importing...</Trans>,
        description: <Trans>This should only take a moment</Trans>,
        isClosable: false,
      }) as string
      fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.text())
        .then((html) => {
          handlers.onImportComplete({
            html,
            filename: title,
            source: 'GoogleDocs',
          })
          toast.update(loadDocToast, {
            status: 'success',
            title: <Trans>Success!</Trans>,
            duration: 5000,
            isClosable: true,
          })
          setIsSuccess(true)
          setImporting(false)
        })
        .catch((error) => {
          console.error(`Error importing doc ${fileId} from google`, error)
          setIsSuccess(false)
          setImporting(false)
          return
        })
    },
    [handlers, toast]
  )

  const onSelect = useCallback(
    (token: string, data) => {
      if (data.action !== 'picked') return

      const [doc] = data.docs
      if (doc.mimeType === 'application/vnd.google-apps.document') {
        loadGoogleDoc(token, doc.id, 'text/html', doc.name)
      } else if (doc.mimeType === 'application/vnd.google-apps.presentation') {
        loadGoogleSlide(
          token,
          doc.id,
          'application/vnd.openxmlformats-officedocument.presentationml.presentation',
          doc.name
        )
      } else {
        // toast maybe?
      }
    },
    [loadGoogleDoc, loadGoogleSlide]
  )

  const showPicker = useCallback(
    (token: string) => {
      setAccessToken(token)

      // @ts-ignore
      const google = window.google

      const slidesEnabled = featureFlags.get('pptImport')
      const mimeTypes: string[] = [
        // Google MIME Types: https://developers.google.com/drive/api/guides/mime-types
        'application/vnd.google-apps.document',
        slidesEnabled && 'application/vnd.google-apps.presentation',
      ].filter((m): m is string => Boolean(m))

      const docsView = new google.picker.DocsView(
        google.picker.ViewId.DOCS
      ).setMimeTypes(mimeTypes.join(','))

      const picker = new google.picker.PickerBuilder()
        .addView(docsView)
        .addView(google.picker.ViewId.FOLDERS)
        .setOAuthToken(token)
        .setAppId(config.GOOGLE_PROJECT_ID)
        .setDeveloperKey(config.GOOGLE_API_KEY)
        .setCallback((data) => {
          onSelect(token, data)
        })

      if (slidesEnabled) {
        picker
          .addView(google.picker.ViewId.DOCUMENTS)
          .addView(google.picker.ViewId.PRESENTATIONS)
      }

      picker.build().setVisible(true)

      // the picker modal has a weird z-index, so bring it to the top
      Array.from(document.getElementsByClassName('picker-dialog')).forEach(
        (element) => {
          // @ts-ignore
          element.style.zIndex = '2000'
        }
      )
    },
    [onSelect]
  )

  const onAuthFailed = useCallback(
    (error) => {
      console.error('Google auth failed:', error)
      toast({
        title: <Trans>Couldn't authenticate with Google Error: {error}</Trans>,
        status: 'error',
        duration: 3000,
        position: 'top',
        isClosable: false,
      })
    },
    [toast]
  )

  const googleLogin = useGoogleLogin({
    scope,
    hint: user?.email,
    prompt: '',
    onSuccess: (response) => {
      if (hasGrantedAllScopesGoogle(response, scope)) {
        showPicker(response.access_token)
      } else {
        onAuthFailed('Could not get requested scopes')
      }
    },
    onError: onAuthFailed,
  })

  const postPickerLoad = useCallback(() => {
    // @ts-ignore
    window.gapi.load('picker', () => {
      setPickerReady(true)
    })
  }, [])

  useScript(GOOGLE_SDK_URL, { onload: postPickerLoad })

  const initiateGoogleImport = useCallback(() => {
    if (!pickerReady) return

    if (!accessToken) {
      googleLogin()
    } else {
      showPicker(accessToken)
    }
  }, [accessToken, googleLogin, pickerReady, showPicker])

  return {
    initiateGoogleImport,
    isImporting,
    isSuccess,
  }
}
